import { ExecuteReportAction, ExecuteReportActionType } from './Actions/ExecuteReportAction';
import { ExportReportAction, ExportReportActionType } from './Actions/ExportReportAction';
import { FetchReportsAction, FetchReportsActionType } from './Actions/FetchReportsAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { AdminHub } from 'models';
import { ApplicationState } from '../ApplicationState';
import { ReportContent } from 'models/ReportContent';
import { ReportExportContent } from 'models/ReportExportContent';
import { ReportInfo } from 'models/ReportInfo';
import { ReportInputControlModel } from 'models/ReportInputControlModel';
import { ReportInputControlValue } from 'models/ReportInputControlValue';
import { State } from './State';
import b64toBlob from 'b64-to-blob';
import { log } from 'utils/log';
import { selectAdminHub } from 'store/selectAdminHub';

function* onFetchReports(action: FetchReportsAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        log('[PREF]', 'getReports');
        const reports: ReportInfo[] = yield call(() => adminHub.getReports());
        log('[PREF]', 'getReports end', reports);

        const act: UpdateStateAction = {
            type: UpdateStateActionType,
            payload: {
                reports,
            },
        };

        yield put(act);
    }
}

function* execReportWithValues(pageNo: number, values: ReportInputControlValue[]) {
    try {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                currentReport: null,
                runningReport: true,
            },
        });

        const uri: string = yield select((s: ApplicationState) => s.reporting.currentReportUri);
        const adminHub: AdminHub = yield selectAdminHub();
        const parameters: { [id: string]: string } = {};
        for (const v of values) {
            parameters[v.id] = v.value;
        }

        log('[PREF]', 'executeReport', parameters);
        const report: ReportContent = yield call(() => adminHub.executeReport(uri, pageNo, parameters));
        log('[PREF]', 'executeReport end');

        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                currentInputControls: values,
                currentPage: pageNo,
                currentReport: report,
                runningReport: false,
            },
        });
    } catch (err) {
        log('fail to excute report', err);
    }
}

function* onExecuteReport(action: ExecuteReportAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    const user = state.server.user;
    if (adminHub && user) {
        if (action.payload.values) {
            yield execReportWithValues(action.payload.page, action.payload.values);
        } else {
            yield put<UpdateStateAction>({
                type: UpdateStateActionType,
                payload: {
                    currentInputControls: null,
                    currentPage: 0,
                    currentReport: null,
                    currentReportUri: action.payload.uri,
                    showInputControls: false,
                },
            });

            try {
                log('[PREF]', 'getInputControls');
                const inputs: ReportInputControlModel[] = yield call(() =>
                    adminHub.getInputControls(action.payload.uri),
                );
                log('[PREF]', 'getInputControls end', inputs);

                const values = inputs.map((m) => {
                    let val = '';
                    if (m.type === 'singleSelect') {
                        const selected = m.state.options.find((opt) => opt.selected) || m.state.options[0];
                        if (selected) {
                            val = selected.value;
                        }

                        if (user.merchantId && m.id === 'MerchantId') {
                            const merchantOpt = m.state.options.find((opt) => opt.value === user.merchantId);
                            if (merchantOpt) {
                                val = merchantOpt.value;
                            }
                        }
                    }

                    return {
                        ...m,
                        value: val,
                    };
                });

                if (values.filter((v) => !v.value).length > 0) {
                    yield put<UpdateStateAction>({
                        type: UpdateStateActionType,
                        payload: {
                            currentInputControls: values,
                            showInputControls: true,
                        },
                    });
                } else {
                    yield execReportWithValues(1, values);
                }
            } catch (err) {
                log('fail to load report', err);
            }
        }
    }
}

function* onExportReport(action: ExportReportAction) {
    try {
        const { currentReportUri, currentInputControls, reports }: State = yield select(
            (s: ApplicationState) => s.reporting,
        );

        const exportType = action.payload.type;
        const reportInfo = reports && reports.find((r) => r.uri === currentReportUri);
        if (currentReportUri && currentInputControls && reportInfo) {
            const adminHub: AdminHub = yield selectAdminHub();
            const parameters: { [id: string]: string } = {};
            for (const v of currentInputControls) {
                parameters[v.id] = v.value;
            }

            log('[PREF]', 'exportReport', parameters);
            const report: ReportExportContent = yield call(() =>
                adminHub.exportReport(currentReportUri, parameters, exportType),
            );
            log('[PREF]', 'exportReport end');

            if (report && report.content) {
                const blob = b64toBlob(
                    report.content,
                    exportType == 'Pdf' ? 'application/pdf' : exportType === 'Csv' ? 'text/csv' : undefined,
                );

                const fileName = reportInfo.label + (exportType == 'Pdf' ? '.pdf' : exportType === 'Csv' ? '.csv' : '');
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            }
        }
    } catch (err) {
        log('fail to export report', err);
    }
}

export const sagas = [
    takeEvery(FetchReportsActionType, onFetchReports),
    takeEvery(ExecuteReportActionType, onExecuteReport),
    takeEvery(ExportReportActionType, onExportReport),
];
