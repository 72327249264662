import { Navbar, NavbarBrand } from 'reactstrap';
import { appConfig } from 'appConfig';
import { Route, Switch } from 'react-router-dom';
import ResetPassword from './ResetPassword';

const OnboardingApp = () => {
    return (
        <div className="h-100 d-flex flex-column">
            <div>
                <Navbar color="light" light={true} expand="md">
                    <NavbarBrand href="/">{appConfig.companyName}</NavbarBrand>
                </Navbar>
            </div>
            <main className="flex-grow-1">
                <Switch>
                    <Route path="/onboarding/account/:token">
                        <ResetPassword />
                    </Route>
                    <Route path="/onboarding/driver/:token">
                        <ResetPassword isDriver={true} />
                    </Route>
                </Switch>
            </main>
        </div>
    );
};

export default OnboardingApp;
