import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { FetchMoreHistoryAction, FetchMoreHistoryActionType } from './Actions/FetchMoreHistoryAction';

export const actionCreators = {
    fetchHistory: (driverId: string) => {
        const act: FetchHistoryAction = { type: FetchHistoryActionType, payload: { driverId } };
        return act;
    },
    fetchMoreOrders: () => {
        const act: FetchMoreHistoryAction = { type: FetchMoreHistoryActionType };
        return act;
    },
};
