/* eslint-disable @typescript-eslint/no-unused-vars */
import { Without } from './helpers';

import {
    DriverLocation,
    DriverLocationValues,
    getDefault as getDriverLocationDefault,
    toModel as toDriverLocationModel,
    toValues as toDriverLocationValues,
} from './DriverLocation';
import {
    GeoAddress,
    GeoAddressValues,
    getDefault as getGeoAddressDefault,
    toModel as toGeoAddressModel,
    toValues as toGeoAddressValues,
} from './GeoAddress';
import {
    getDefault as getMerchantOrganizationDefault,
    MerchantOrganization,
    MerchantOrganizationValues,
    toModel as toMerchantOrganizationModel,
    toValues as toMerchantOrganizationValues,
} from './MerchantOrganization';

import { DriverPhoneStatus } from './DriverPhoneStatus';
import { DriverPunchInMode } from './DriverPunchInMode';
import { DriverVehicleType } from './DriverVehicleType';

export interface Driver {
    address: GeoAddress;
    addressHint: string;
    allowJailbrokenPhone: boolean;
    allowRemotePunchIn: boolean;
    allowRemotePunchOut: boolean;
    allowViewAllTasks: boolean;
    appUpdateUrl: string;
    barcodeValidationEnabled: boolean;
    binAuditerName: string;
    binNumber: number;
    binOutForDelivery: boolean;
    binReadyForVerification: boolean;
    binVerified: boolean;
    buildNumber: string;
    buildNumberTargetOptional: number;
    buildNumberTargetRequired: number;
    currentTrackerId: string;
    disableConfirmation: boolean;
    employeeNumber: string;
    extras: {};
    friendlyName: string;
    hideLastEta: boolean;
    hideTasksBeforePickup: boolean;
    highMileageAlertKMOverwrite: number;
    id: string;
    invitationToken: string | null;
    isDisabled: boolean;
    isOnline: boolean;
    isPlaceholder: boolean;
    lastEta: string | null;
    location: DriverLocation;
    locationTracker: DriverLocation;
    merchantOrgs: MerchantOrganization[];
    name: string;
    nextShiftStartTime: string | null;
    note: string;
    password: string;
    payGroup: string;
    payPerPackage: boolean;
    phone: string;
    phoneId: string;
    phoneOs: string;
    phoneStatus: DriverPhoneStatus;
    plateform: string;
    preferredTrackingMethod: string;
    punchInMode: DriverPunchInMode;
    sendInvite: boolean;
    seqId: number;
    serviceCity: string;
    smsIfPhoneOffline: boolean;
    smsIfTrackerUnplugged: boolean;
    tempTag: string;
    ttsNoRepeat: boolean;
    ttsPositionHubSort: boolean;
    userId: string;
    username: string;
    vehicleType: DriverVehicleType;
}

export type DriverValues = Without<
    Driver,
    keyof {
        address: {};
        binNumber: {};
        buildNumberTargetOptional: {};
        buildNumberTargetRequired: {};
        highMileageAlertKMOverwrite: {};
        invitationToken: {};
        lastEta: {};
        location: {};
        locationTracker: {};
        merchantOrgs: {};
        nextShiftStartTime: {};
        seqId: {};
    }
> & {
    address: GeoAddressValues;
    binNumber: string;
    buildNumberTargetOptional: string;
    buildNumberTargetRequired: string;
    highMileageAlertKMOverwrite: string;
    invitationToken: string;
    lastEta: string;
    location: DriverLocationValues;
    locationTracker: DriverLocationValues;
    merchantOrgs: MerchantOrganizationValues[];
    nextShiftStartTime: string;
    seqId: string;
};

export function getDefault(): Driver {
    return {
        address: getGeoAddressDefault(),
        addressHint: '',
        allowJailbrokenPhone: false,
        allowRemotePunchIn: false,
        allowRemotePunchOut: false,
        allowViewAllTasks: false,
        appUpdateUrl: '',
        barcodeValidationEnabled: false,
        binAuditerName: '',
        binNumber: 0,
        binOutForDelivery: false,
        binReadyForVerification: false,
        binVerified: false,
        buildNumber: '',
        buildNumberTargetOptional: 0,
        buildNumberTargetRequired: 0,
        currentTrackerId: '',
        disableConfirmation: false,
        employeeNumber: '',
        extras: {},
        friendlyName: '',
        hideLastEta: false,
        hideTasksBeforePickup: false,
        highMileageAlertKMOverwrite: 0,
        id: '',
        invitationToken: '',
        isDisabled: false,
        isOnline: false,
        isPlaceholder: false,
        lastEta: '',
        location: getDriverLocationDefault(),
        locationTracker: getDriverLocationDefault(),
        merchantOrgs: [],
        name: '',
        nextShiftStartTime: '',
        note: '',
        password: '',
        payGroup: '',
        payPerPackage: false,
        phone: '',
        phoneId: '',
        phoneOs: '',
        phoneStatus: 'Offline',
        plateform: '',
        preferredTrackingMethod: '',
        punchInMode: 'hubscan',
        sendInvite: false,
        seqId: 0,
        serviceCity: '',
        smsIfPhoneOffline: false,
        smsIfTrackerUnplugged: false,
        tempTag: '',
        ttsNoRepeat: false,
        ttsPositionHubSort: false,
        userId: '',
        username: '',
        vehicleType: 'car',
    };
}

export function toValues(model: Partial<Driver>): DriverValues {
    model = model || {};
    return {
        address: toGeoAddressValues(model.address || {}),
        addressHint: model.addressHint || '',
        allowJailbrokenPhone: model.allowJailbrokenPhone || false,
        allowRemotePunchIn: model.allowRemotePunchIn || false,
        allowRemotePunchOut: model.allowRemotePunchOut || false,
        allowViewAllTasks: model.allowViewAllTasks || false,
        appUpdateUrl: model.appUpdateUrl || '',
        barcodeValidationEnabled: model.barcodeValidationEnabled || false,
        binAuditerName: model.binAuditerName || '',
        binNumber: `${model.binNumber || 0}`,
        binOutForDelivery: model.binOutForDelivery || false,
        binReadyForVerification: model.binReadyForVerification || false,
        binVerified: model.binVerified || false,
        buildNumber: model.buildNumber || '',
        buildNumberTargetOptional: `${model.buildNumberTargetOptional || 0}`,
        buildNumberTargetRequired: `${model.buildNumberTargetRequired || 0}`,
        currentTrackerId: model.currentTrackerId || '',
        disableConfirmation: model.disableConfirmation || false,
        employeeNumber: model.employeeNumber || '',
        extras: model.extras || {},
        friendlyName: model.friendlyName || '',
        hideLastEta: model.hideLastEta || false,
        hideTasksBeforePickup: model.hideTasksBeforePickup || false,
        highMileageAlertKMOverwrite: `${model.highMileageAlertKMOverwrite || 0}`,
        id: model.id || '',
        invitationToken: model.invitationToken || '',
        isDisabled: model.isDisabled || false,
        isOnline: model.isOnline || false,
        isPlaceholder: model.isPlaceholder || false,
        lastEta: model.lastEta || '',
        location: toDriverLocationValues(model.location || {}),
        locationTracker: toDriverLocationValues(model.locationTracker || {}),
        merchantOrgs: (model.merchantOrgs || []).map((e) => toMerchantOrganizationValues(e)),
        name: model.name || '',
        nextShiftStartTime: model.nextShiftStartTime || '',
        note: model.note || '',
        password: model.password || '',
        payGroup: model.payGroup || '',
        payPerPackage: model.payPerPackage || false,
        phone: model.phone || '',
        phoneId: model.phoneId || '',
        phoneOs: model.phoneOs || '',
        phoneStatus: model.phoneStatus || 'Offline',
        plateform: model.plateform || '',
        preferredTrackingMethod: model.preferredTrackingMethod || '',
        punchInMode: model.punchInMode || 'hubscan',
        sendInvite: model.sendInvite || false,
        seqId: `${model.seqId || 0}`,
        serviceCity: model.serviceCity || '',
        smsIfPhoneOffline: model.smsIfPhoneOffline || false,
        smsIfTrackerUnplugged: model.smsIfTrackerUnplugged || false,
        tempTag: model.tempTag || '',
        ttsNoRepeat: model.ttsNoRepeat || false,
        ttsPositionHubSort: model.ttsPositionHubSort || false,
        userId: model.userId || '',
        username: model.username || '',
        vehicleType: model.vehicleType || 'car',
    };
}

export function toModel(values: DriverValues): Driver {
    return {
        ...values,
        address: toGeoAddressModel(values.address),
        binNumber: Number.parseFloat(values.binNumber),
        buildNumberTargetOptional: Number.parseFloat(values.buildNumberTargetOptional),
        buildNumberTargetRequired: Number.parseFloat(values.buildNumberTargetRequired),
        extras: values.extras || {},
        highMileageAlertKMOverwrite: Number.parseFloat(values.highMileageAlertKMOverwrite),
        invitationToken: values.invitationToken === '' ? null : values.invitationToken,
        lastEta: values.lastEta === '' ? null : values.lastEta,
        location: toDriverLocationModel(values.location),
        locationTracker: toDriverLocationModel(values.locationTracker),
        merchantOrgs: (values.merchantOrgs || []).map((e) => toMerchantOrganizationModel(e)),
        nextShiftStartTime: values.nextShiftStartTime === '' ? null : values.nextShiftStartTime,
        phoneStatus: values.phoneStatus || 'Offline',
        punchInMode: values.punchInMode || 'hubscan',
        seqId: Number.parseFloat(values.seqId),
        vehicleType: values.vehicleType || 'car',
    };
}
