import { DriverOrderTaskHistoryItem } from 'models/DriverOrderTaskHistoryItem';

export interface State {
    driverId: string | null;
    orders: DriverOrderTaskHistoryItem[] | null;
    hasMore: boolean;
}

export const defaultState: State = {
    driverId: null,
    orders: null,
    hasMore: false,
};
