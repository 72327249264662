import * as DriverState from './DriverState';
import * as ServerState from './ServerState';

import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from './ApplicationState';
import { Driver } from 'models/Driver';
import { Reducer } from 'redux';
import { eventChannel } from 'redux-saga';
import { getDisplayName } from 'utils/getDisplayName';
import { log } from 'utils';

export interface State {
    isLoadingDriverList: boolean;
    deletedDrivers: Driver[] | null;
}

export const FetchDriverActionType = 'DRIVER_MGNT/FETCHE_DRIVER';
export const ReceiveDriverActionType = 'DRIVER_MGNT/RECEIVE_DRIVER';
export const FetchDeletedDriverActionType = 'DRIVER_MGNT/FETCHE_DELETED_DRIVER';
export const ReceiveDeletedDriverActionType = 'DRIVER_MGNT/RECEIVE_DELETED_DRIVER';

interface ReceiveDeletedDriverAction {
    type: typeof ReceiveDeletedDriverActionType;
    payload: {
        drivers: Driver[];
    };
}

export type KnownAction =
    | {
          type: typeof FetchDriverActionType;
      }
    | {
          type: typeof ReceiveDriverActionType;
      }
    | {
          type: typeof FetchDeletedDriverActionType;
      }
    | ReceiveDeletedDriverAction;

export const actionCreators = {
    fetchDrivers: () => ({ type: FetchDriverActionType }),
    fetchDeletedDrivers: () => ({ type: FetchDeletedDriverActionType }),
};

export const reducer: Reducer<State, KnownAction> = (
    state = {
        isLoadingDriverList: false,
        deletedDrivers: null,
    },
    action,
): State => {
    switch (action.type) {
        case FetchDriverActionType:
            return { ...state, isLoadingDriverList: true };
        case ReceiveDriverActionType:
            return { ...state, isLoadingDriverList: false };
        case FetchDeletedDriverActionType:
            return { ...state, deletedDrivers: null };
        case ReceiveDeletedDriverActionType:
            return { ...state, deletedDrivers: action.payload.drivers };
        default:
            return state;
    }
};

function* fetchDrivers() {
    const state: ApplicationState = yield select();
    if (state.driver.drivers) {
        log('[PREF]', 'DriverManagementState: fetchDrivers');
        yield put({ type: ReceiveDriverActionType });
    } else if (!state.driver.isLoading) {
        log('[PREF]', 'DriverManagementState: fetchDrivers!');
        yield put({ type: DriverState.FetchDriverActionType });
    }
}

function* receiveDrivers() {
    log('[PREF]', 'DriverManagementState: receiveDrivers');
    yield put({ type: ReceiveDriverActionType });
}

function* fetchDeletedDrivers() {
    log('[PREF]', 'DriverManagementState: fetchDeletedDrivers...');
    const { hubs }: ServerState.State = yield select((s: ApplicationState) => s.server);
    const hub = hubs && hubs.admin;
    if (hub) {
        const drivers: Driver[] = yield call(() => hub.getDeletedDrivers());

        // Sort deleted drivers by status and name
        drivers.sort(
            (a, b) =>
                +(b.phoneStatus === 'Active') - +(a.phoneStatus === 'Active') ||
                getDisplayName(a).toLowerCase().localeCompare(getDisplayName(b).toLowerCase()),
        );

        const act: ReceiveDeletedDriverAction = {
            type: ReceiveDeletedDriverActionType,
            payload: {
                drivers,
            },
        };
        yield put(act);
    }
}

function* listenDriverListChanged() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = () => {
                emitter({});
            };

            hub.connection.on('OnDriverListUpdated', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnDriverListUpdated', callback);
            };
        });

        while (true) {
            yield take(channel);
            log('DriverManagementState: OnDriverListUpdated');

            const state: State = yield select((s: ApplicationState) => s.driverManagement);
            if (state.deletedDrivers) {
                const act = {
                    type: FetchDeletedDriverActionType,
                };
                yield put(act);
            }
        }
    }
}

export const sagas = [
    //takeEvery(ServerState.ServerConnectedActionType, listenDriverListChanged), // Do not reload deleted drivers, TODO: Reload 1 deleted driver
    takeEvery(FetchDriverActionType, fetchDrivers),
    takeEvery(FetchDeletedDriverActionType, fetchDeletedDrivers),
    takeEvery(DriverState.ReceiveDriverActionType, receiveDrivers),
];
