import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

import { auth } from '../Auth';
import { connect } from 'formik';
import { log } from './log';

async function start(connection: HubConnection) {
    try {
        await connection.start();
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('SignalR Connected.');
    } catch (err) {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.log('SignalR ConnectionStart Error: ' + err);
        setTimeout(() => start(connection), 5000);
    }
}

export async function connectHub(hubPath: string) {
    const connection = new HubConnectionBuilder()
        .withUrl(hubPath, {
            accessTokenFactory: () => auth.aquireToken(),
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Error)
        //.withHubProtocol(new MessagePackHubProtocol())
        .build();

    connection.serverTimeoutInMilliseconds = 30000; // 1 second * 60 * 3 = 3 minutes.
    connection.keepAliveIntervalInMilliseconds = 15000;

    connection.onreconnecting((error) => {
        console.assert(connection.state === HubConnectionState.Reconnecting);
        log('The connection is reconnecting');
        // alert("The connection is reconnecting");
    });

    connection.onclose((error) => {
        console.assert(connection.state === HubConnectionState.Disconnected);
        log('The connection was killed');
        setTimeout(function () {
            start(connection);
        }, 5000); // Re-start connection after 5 seconds
    });

    connection.onreconnected((callback) => {
        log('The connection is reconnected');
        console.assert(connection.state === HubConnectionState.Connected);
    });

    await start(connection);

    return connection;
}
