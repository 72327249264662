import { ReportInputControlValue } from 'models/ReportInputControlValue';

export const ExecuteReportActionType = 'RPT/EXEC_REPORT';
export interface ExecuteReportAction {
    type: typeof ExecuteReportActionType;
    payload: {
        uri: string;
        values?: ReportInputControlValue[];
        page: number;
    };
}
