import { DriverWorkLogHistoryItem } from '../../models/DriverWorkLogHistoryItem';
import { WorkLog } from '../../models/WorkLog';

export interface State {
    driverId: string | null;
    workLogs: DriverWorkLogHistoryItem[] | null;
    hasMore: boolean;
    workLog: WorkLog | null;
}

export const defaultState: State = {
    driverId: null,
    workLogs: null,
    hasMore: false,
    workLog: null,
};
