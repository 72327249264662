import { CloseWorkLogAction, CloseWorkLogActionType } from './Actions/CloseWorkLogAction';
import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { ReceiveHistoryAction, ReceiveHistoryActionType } from './Actions/ReceiveHistoryAction';
import { ReceiveWorkLogAction, ReceiveWorkLogActionType } from './Actions/ReceiveWorkLogAction';
import { State, defaultState } from './State';

import { FetchMoreHistoryAction } from './Actions/FetchMoreHistoryAction';
import { FetchWorkLogAction } from './Actions/FetchWorkLogAction';
import { ProcessWorkLogStatsAction } from './Actions/ProcessWorkLogStatsAction';
import { Reducer } from 'redux';

type KnownAction =
    | FetchHistoryAction
    | FetchMoreHistoryAction
    | ReceiveHistoryAction
    | FetchWorkLogAction
    | ProcessWorkLogStatsAction
    | ReceiveWorkLogAction
    | CloseWorkLogAction;

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case FetchHistoryActionType: {
            return {
                ...state,
                workLogs: null,
                ...action.payload,
                workLog: null,
            };
        }
        case ReceiveHistoryActionType: {
            return {
                ...state,
                ...action.payload,
                workLog: null,
            };
        }
        case ReceiveWorkLogActionType: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case CloseWorkLogActionType: {
            return {
                ...state,
                workLog: null,
            };
        }
        default:
            return state;
    }
};
