import { defineMessages } from 'react-intl';

const customerForm = defineMessages({
    emailHint: {
        defaultMessage: 'Enter e-mail',
        id: 'hint.customer-email',
    },
    emailLabel: {
        defaultMessage: 'E-mail',
        id: 'label.customer-email',
    },
    firstNameHint: {
        defaultMessage: 'Enter first name',
        id: 'hint.customer-first-name',
    },
    firstNameLabel: {
        defaultMessage: 'First Name',
        id: 'label.customer-first-name',
    },
    lastNameHint: {
        defaultMessage: 'Enter last name',
        id: 'hint.customer-last-name',
    },
    lastNameLabel: {
        defaultMessage: 'Last Name',
        id: 'label.customer-last-name',
    },
});

const addressForm = defineMessages({
    cityHint: {
        defaultMessage: 'Enter city',
        id: 'hint.address-city',
    },
    cityLabel: {
        defaultMessage: 'City',
        id: 'label.address-city',
    },
    latLabel: {
        defaultMessage: 'Latitude',
        id: 'label.address-lat',
    },
    lngLabel: {
        defaultMessage: 'Longitude',
        id: 'label.address-lng',
    },
    lookupLabel: {
        defaultMessage: 'Lookup',
        id: 'label.address-lookup',
    },
    newAddress: {
        defaultMessage: 'New Address',
        id: 'label.address-add-new',
    },
    postalHint: {
        defaultMessage: 'Enter postal code',
        id: 'hint.address-postal',
    },
    postalLabel: {
        defaultMessage: 'Postal Code',
        id: 'label.address-postal',
    },
    provinceHint: {
        defaultMessage: 'Enter province',
        id: 'hint.address-province',
    },
    provinceLabel: {
        defaultMessage: 'Province',
        id: 'label.address-province',
    },
    streetHint: {
        defaultMessage: 'Enter address',
        id: 'hint.address-street',
    },
    streetLabel: {
        defaultMessage: 'Address',
        id: 'label.address-street',
    },
    unitHint: {
        defaultMessage: 'Enter unit',
        id: 'hint.address-unit',
    },
    unitLabel: {
        defaultMessage: 'Unit',
        id: 'label.address-unit',
    },
});

const orderForm = defineMessages({
    addressHint: {
        defaultMessage: 'Select Address',
        id: 'hint.address',
    },
    addressLabel: {
        defaultMessage: 'Address',
        id: 'label.address',
    },
    billMerchant: {
        defaultMessage: 'Bill Merchant',
        id: 'label.billMerchant',
    },
    cancelReasonLabel: {
        defaultMessage: 'Enter reason for cancellation/lost',
        id: 'label.cancelReason',
    },
    commentsHint: {
        defaultMessage: 'Enter comments',
        id: 'hint.comments',
    },
    commentsLabel: {
        defaultMessage: 'Comments',
        id: 'label.comments',
    },
    customerLabel: {
        defaultMessage: 'Customer',
        id: 'label.customer',
    },
    deliveryFeelHint: {
        defaultMessage: 'Enter Delivery Fee',
        id: 'hint.order-deliver-fee',
    },
    deliveryFeelLabel: {
        defaultMessage: 'Delivery Fee',
        id: 'label.order-deliver-fee',
    },
    deliveryServiceRush: {
        defaultMessage: 'Rush',
        id: 'label.order.delivery-service.rush',
    },
    deliveryServiceSpecific: {
        defaultMessage: 'Specific',
        id: 'label.order.delivery-service.specific',
    },
    deliveryServiceType: {
        defaultMessage: 'Delivery Service',
        id: 'label.order.delivery-service.type',
    },
    deliveryTimeAsap: {
        defaultMessage: 'As soon as possible',
        id: 'label.order-deliver-time-asap',
    },
    deliveryTimeLabel: {
        defaultMessage: 'Drop Max',
        id: 'label.order-deliver-time',
    },
    deliveryTimeMinLabel: {
        defaultMessage: 'Drop Min',
        id: 'label.order-deliver-time-min',
    },
    deliveryTimePickupLabel: {
        defaultMessage: 'Pickup',
        id: 'label.order-deliver-time-pickup',
    },
    deliveryTimeToday: {
        defaultMessage: 'Today',
        id: 'label.order-deliver-time-today',
    },
    deliveryTypeMedicinal: {
        defaultMessage: 'Medicinal',
        id: 'label.order.delivery-type.medicinal',
    },
    deliveryTypeRecreational: {
        defaultMessage: 'Recreational',
        id: 'label.order.delivery-type.recreational',
    },
    deliveryTypeTransfer: {
        defaultMessage: 'B to B',
        id: 'label.order.delivery-type.transfer',
    },
    deliveryTypeAlcohol: {
        defaultMessage: 'Alcohol',
        id: 'label.order.delivery-type.alcohol',
    },
    deliveryTypeOther: {
        defaultMessage: 'Other',
        id: 'label.order.delivery-type.other',
    },
    disableOrderConfirmationOnDropOffHint: {
        defaultMessage: 'Disable Order Confirmation on Hub Dropoff',
        id: 'hint.disableOrderConfirmationOnDropOffHint',
    },
    driverHint: {
        defaultMessage: 'Select Driver',
        id: 'hint.driver',
    },
    driverLabel: {
        defaultMessage: 'Driver',
        id: 'label.driver',
    },
    labelApartment: {
        defaultMessage: 'Apartment',
        id: 'label.apartment',
    },
    labelCellPhone: {
        defaultMessage: 'Cell Phone',
        id: 'label.cellPhone',
    },
    labelClientName: {
        defaultMessage: 'Client Name',
        id: 'label.clientName',
    },
    labelDeclaredValue: {
        defaultMessage: 'Declared Value',
        id: 'label.declared-value',
    },
    labelDeliveryType: {
        defaultMessage: 'Delivery Type',
        id: 'label.deliveryType',
    },
    labelDesiredDate: {
        defaultMessage: 'Desired Date',
        id: 'label.desiredDate',
    },
    labelDropMax: {
        defaultMessage: 'Drop Max',
        id: 'label.dropMax',
    },
    labelDropMin: {
        defaultMessage: 'Drop Min',
        id: 'label.dropMin',
    },
    labelPickupTime: {
        defaultMessage: 'Pickup Time',
        id: 'label.pickupTime',
    },
    labelPriceCallInFee: {
        defaultMessage: 'Merchant Fee',
        id: 'label.priceCallInFee',
    },
    labelPackageType: {
        defaultMessage: 'Package Type',
        id: 'label.packages-0-type',
    },
    labelPackageWeight: {
        defaultMessage: 'Weight',
        id: 'label.packages-0-weight',
    },
    labelPackageLength: {
        defaultMessage: 'Length',
        id: 'label.packages-0-length',
    },
    labelPackageHeight: {
        defaultMessage: 'Height',
        id: 'label.packages-0-height',
    },
    labelPackageWidth: {
        defaultMessage: 'Width',
        id: 'label.packages-0-width',
    },
    labelPackageReference: {
        defaultMessage: 'Reference ID',
        id: 'label.packages-0-reference',
    },
    labelPackageProducts: {
        defaultMessage: 'Products',
        id: 'label.packageProducts',
    },
    labelPriceDeliveryFeeForClient: {
        defaultMessage: 'Client Fee',
        id: 'label.priceDeliveryFeeForClient',
    },
    labelRefId: {
        defaultMessage: 'Reference ID',
        id: 'label.order-ref-id',
    },
    labelSearchAddress: {
        defaultMessage: 'Search Address',
        id: 'label.search-address',
    },
    labelSelect: {
        defaultMessage: 'Select..',
        id: 'label.select',
    },
    labelProductUpc: {
        defaultMessage: 'UPC',
        id: 'label.packages-0-productInfo-0-upc',
    },
    labelProductSku: {
        defaultMessage: 'SKU',
        id: 'label.packages-0-productInfo-0-skuExternal',
    },
    labelProductPackageType: {
        defaultMessage: 'Package Type',
        id: 'label.packages-0-productInfo-0-type',
    },
    labelProductMerchant: {
        defaultMessage: 'Merchant',
        id: 'label.packages-0-productInfo-0-merchantName',
    },
    labelPackageDescription: {
        defaultMessage: 'Description',
        id: 'label.packages-0-description',
    },
    manifestLabel: {
        defaultMessage: 'Manifest',
        id: 'label.manifest',
    },
    merchantHint: {
        defaultMessage: 'Select merchant',
        id: 'hint.order-merchant',
    },
    merchantLabel: {
        defaultMessage: 'Merchant',
        id: 'label.order-merchant',
    },
    paymentMethodLabel: {
        defaultMessage: 'Method',
        id: 'label.order-payment-method',
    },
    paymentMethodOptionsCash: {
        defaultMessage: 'Cash',
        id: 'options.order-payment-method-cash',
    },
    paymentMethodOptionsPrepaid: {
        defaultMessage: 'Prepaid',
        id: 'options.order-payment-method-prepaid',
    },
    paymentMethodOptionsTerminal: {
        defaultMessage: 'Terminal',
        id: 'options.order-payment-method-terminal',
    },
    tipHint: {
        defaultMessage: 'Enter tip',
        id: 'hint.order-tip',
    },
    tipLabel: {
        defaultMessage: 'Tip',
        id: 'label.order-tip',
    },
    titleDetails: {
        defaultMessage: 'Details',
        id: 'forms.order.title.details',
    },
    titleDropoff: {
        defaultMessage: 'Dropoff',
        id: 'forms.order.title.dropoff',
    },
    titlePickup: {
        defaultMessage: 'Pickup',
        id: 'forms.order.title.pickup',
    },
    totalHint: {
        defaultMessage: 'Enter Total',
        id: 'hint.order-total',
    },
    totalLabel: {
        defaultMessage: 'Total',
        id: 'label.order-total',
    },
    titlePackage: {
        defaultMessage: 'Packages',
        id: 'label.package',
    },
});

const commonForm = defineMessages({
    msgGeneralError: {
        defaultMessage: 'An error has occurred, please try again later.',
        id: 'form.common.msgGeneralError',
    },
    notesHint: {
        defaultMessage: 'Enter notes',
        id: 'hint.address-notes',
    },
    notesLabel: {
        defaultMessage: 'Notes',
        id: 'label.address-notes',
    },
});

const forms = {
    address: addressForm,
    common: commonForm,
    customer: customerForm,
    order: orderForm,
};

const buttons = defineMessages({
    cancel: {
        defaultMessage: 'Cancel',
        id: 'label.cancel',
    },
    clearFilters: {
        defaultMessage: 'Clear Filters',
        id: 'label.clearFilters',
    },
    clearSelection: {
        defaultMessage: 'Clear Selection',
        id: 'label.clearSelection',
    },
    import: {
        defaultMessage: 'Import',
        id: 'label.import',
    },
    new: {
        defaultMessage: 'New',
        id: 'label.new',
    },
    refresh: {
        defaultMessage: 'Refresh',
        id: 'label.refresh',
    },
    reset: {
        defaultMessage: 'Reset',
        id: 'label.reset',
    },
    save: {
        defaultMessage: 'Save',
        id: 'label.save',
    },
    saveInfo: {
        defaultMessage: 'Save Information',
        id: 'label.save-info',
    },
});

const orderStatus = defineMessages({
    acknowledged: {
        defaultMessage: 'Acknowledged',
        id: 'common.order-status-acknowledged',
    },
    cancel: {
        defaultMessage: 'Cancel',
        id: 'common.order-status-cancel',
    },
    completed: {
        defaultMessage: 'Completed',
        id: 'common.order-status-completed',
    },
    delivery: {
        defaultMessage: 'Delivery',
        id: 'common.order-status-delivery',
    },
    lost: {
        defaultMessage: 'Lost',
        id: 'common.order-status-lost',
    },
    order: {
        defaultMessage: 'Order',
        id: 'common.order-status-order',
    },
    preparation: {
        defaultMessage: 'Preparation',
        id: 'common.order-status-preparation',
    },
    transit: {
        defaultMessage: 'Transit',
        id: 'common.order-status-transit',
    },
    waiting: {
        defaultMessage: 'Waiting',
        id: 'common.order-status-waiting',
    },
});

const orderCategory = defineMessages({
    active: {
        defaultMessage: 'Orders',
        id: 'common.order-category-active',
    },
    canceled: {
        defaultMessage: 'Cancelled',
        id: 'common.order-category-canceled',
    },
    completed: {
        defaultMessage: 'Completed',
        id: 'common.order-category-completed',
    },
    lost: {
        defaultMessage: 'Lost',
        id: 'common.order-category-lost',
    },
    onhold: {
        defaultMessage: 'On-Hold',
        id: 'common.order-category-onhold',
    },
    preorder: {
        defaultMessage: 'Pre-Orders',
        id: 'common.order-category-preorder',
    },
});

const commonMenu = defineMessages({
    updateAvailable: {
        defaultMessage: 'Update Available',
        id: 'menu.update-available',
    },
    updateAvailableHint: {
        defaultMessage: 'A new version is available, click to reload.',
        id: 'menu.update-available-hint',
    },
});

const shared = defineMessages({
    unknownTimeLabel: {
        defaultMessage: 'Unknown',
        id: 'common.label.unknown-time',
    },
});

const common = {
    menu: commonMenu,
    orderCategory,
    orderStatus,
    shared,
};

const orderDetails = defineMessages({
    amountHint: {
        defaultMessage: 'Enter new amount',
        id: 'order.detials.amount-hint',
    },
    amountLabel: {
        defaultMessage: 'Amount',
        id: 'order.detials.amount-label',
    },
    callTypeClient: {
        defaultMessage: 'Client',
        id: 'order.detials.call-type-client',
    },
    callTypeMerchant: {
        defaultMessage: 'Merchant',
        id: 'order.detials.call-type-merchant',
    },
    callTypeVoicemail: {
        defaultMessage: 'Voicemail',
        id: 'order.detials.call-type-voicemail',
    },
    changeAddress: {
        defaultMessage: 'Change Address',
        id: 'order.detials.change-address',
    },
    changeDeliveryTime: {
        defaultMessage: 'Change Delivery Time',
        id: 'order.detials.change-delivery-time',
    },
    changeTime: {
        defaultMessage: 'Change Time',
        id: 'order.detials.change-time',
    },
    currentTime: {
        defaultMessage: 'Current Time',
        id: 'order.detials.current-time',
    },
    feeTypeClient: {
        defaultMessage: 'Client',
        id: 'order.detials.fee-type-client',
    },
    feeTypeDriver: {
        defaultMessage: 'Driver',
        id: 'order.detials.fee-type-driver',
    },
    feeTypeMerchant: {
        defaultMessage: 'Merchant',
        id: 'order.detials.fee-type-merchant',
    },
    paidUpfront: {
        defaultMessage: 'Paid Upfront',
        id: 'order.detials.paid-upfront',
    },
    shippingLabel: {
        defaultMessage: 'Shipping Label',
        id: 'order.detials.shippingLabel',
    },
    signature: {
        defaultMessage: 'Delivery Confirmation',
        id: 'order.detials.signature',
    },
    updateAddress: {
        defaultMessage: 'Update Address',
        id: 'order.detials.update-address',
    },
    updateFee: {
        defaultMessage: 'Edit Delivery Amount',
        id: 'order.detials.update-fee',
    },
});

const orderTabs = defineMessages({
    titleChargesAndAdjustments: {
        defaultMessage: 'Charges & Adjustment',
        id: 'order.tabs.title-charges-adjustment',
    },
    titleDispatch: {
        defaultMessage: 'Dispatch',
        id: 'order.tabs.title-dispatch',
    },
    titleEventLogs: {
        defaultMessage: 'Event Logs',
        id: 'order.tabs.title-event-logs',
    },
    titleEventLogsEta: {
        defaultMessage: 'ETA Logs',
        id: 'order.tabs.title-eta-logs',
    },
    titleMissedAttempts: {
        defaultMessage: 'Missed Attempts',
        id: 'order.tabs.title-order-attempts',
    },
    titleOrderContent: {
        defaultMessage: 'Order Content',
        id: 'order.tabs.title-order-content',
    },
});

const settingsMenu = defineMessages({
    business: {
        defaultMessage: 'Business',
        id: 'menu.settings.business',
    },
    gpstrackerdevices: {
        defaultMessage: 'Gps Trackers',
        id: 'menu.settings.gpstrackerdevices',
    },
    hubs: {
        defaultMessage: 'Hubs',
        id: 'menu.settings.hubs',
    },
    undeliverables: {
        defaultMessage: 'Undeliverable Codes',
        id: 'menu.settings.undeliverables',
    },
    zones: {
        defaultMessage: 'Zones',
        id: 'menu.settings.zones',
    },
    merchantOrgs: {
        defaultMessage: 'Merchant Orgs',
        id: 'menu.settings.merchantOrgs',
    },
    chatwootInboxes: {
        defaultMessage: 'Chatwoot Inboxes',
        id: 'menu.settings.chatwootInboxes',
    },
});

const weekDays = defineMessages({
    friday: {
        defaultMessage: 'Friday',
        id: 'weekDays.friday',
    },
    monday: {
        defaultMessage: 'Monday',
        id: 'weekDays.monday',
    },
    saturday: {
        defaultMessage: 'Saturday',
        id: 'weekDays.saturday',
    },
    sunday: {
        defaultMessage: 'Sunday',
        id: 'weekDays.sunday',
    },
    thursday: {
        defaultMessage: 'Thursday',
        id: 'weekDays.thursday',
    },
    tuesday: {
        defaultMessage: 'Tuesday',
        id: 'weekDays.tuesday',
    },
    wednesday: {
        defaultMessage: 'Wednesday',
        id: 'weekDays.wednesday',
    },
});

const orderMessages = {
    details: orderDetails,
    tabs: orderTabs,
};

const menus = {
    settings: settingsMenu,
};

export { buttons, common, forms, orderMessages, menus, weekDays };
