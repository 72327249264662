import { ActivateOrderAction, ActivateOrderActionType } from './Actions/ActivateOrderAction';
import { SwitchCategoryAction, SwitchCategoryActionType } from './Actions/SwitchCategoryAction';
import { SwitchDriverAction, SwitchDriverActionType } from './Actions/SwitchDriverAction';
import { SwitchZoneAction, SwitchZoneActionType } from './Actions/SwitchZoneAction';
import {
    SwitchCategoryTableViewAction,
    SwitchCategoryTableViewActionType,
} from './Actions/SwitchCategoryTableViewAction';
import { SwitchDriverTableViewAction, SwitchDriverTableViewActionType } from './Actions/SwitchDriverTableViewAction';
import { SwitchZoneTableViewAction, SwitchZoneTableViewActionType } from './Actions/SwitchZoneTableViewAction';
import { UpdateOrderDateFilterAction, UpdateOrderDateFilterActionType } from './Actions/UpdateOrderDateFilterAction';
import { UpdateOrderFilterAction, UpdateOrderFilterActionType } from './Actions/UpdateOrderFilterAction';
import {
    UpdateOrderFilterTableViewAction,
    UpdateOrderFilterTableViewActionType,
} from './Actions/UpdateOrderFilterTableViewAction';
import { VisitTaskAction, VisitTaskActionType } from './Actions/VisitTaskAction';
import { ShowHideOrderToggleAction, ShowHideOrderToggleActionType } from './Actions/ShowHideOrderToggleAction';
import { ShowHideFilterAction, ShowHideFilterActionType } from './Actions/ShowHideFilterAction';
import { ShowTableViewAction, ShowTableViewActionType } from './Actions/ShowTableViewAction';

import { Driver } from 'models/Driver';
import { Order } from 'models/Order';
import { OrderCategory } from 'models/OrderCategory';
import { ServiceZone } from 'models/ServiceZone';
import { TogglePrintAction, TogglePrintActionType } from './Actions/TogglePrintAction';
import { SelectingOrdersAction, SelectingOrdersActionType } from './Actions/SelectingOrdersAction';
import { SelectAllOrdersAction, SelectAllOrdersActionType } from './Actions/SelectAllOrdersAction';
import { OrderRefItem } from 'models/OrderRefItem';
import { SortOrdersAction, SortOrdersActionType } from './Actions/SortOrdersAction';
import {
    TogglePrintOrderSectionAction,
    TogglePrintOrderSectionActionType,
} from './Actions/TogglePrintOrderSectionAction';

export const actionCreators = {
    filterOrder: (filters: {
        filterByKeywords?: string;
        filterByDate?: string;
        filterByDateMax?: string;
        filterMode?: string;
    }) => {
        const act: UpdateOrderFilterAction = { type: UpdateOrderFilterActionType, payload: filters };
        return act;
    },
    filterOrderTableView: (filters: {
        filterByKeywords?: string;
        filterByDate?: string;
        filterByDateMax?: string;
        filterMode?: string;
    }) => {
        const act: UpdateOrderFilterTableViewAction = { type: UpdateOrderFilterTableViewActionType, payload: filters };
        return act;
    },
    activateOrder: (orderId: string) => {
        const act: ActivateOrderAction = { type: ActivateOrderActionType, payload: { orderId } };
        return act;
    },
    visitTask: (taskId: string) => {
        const act: VisitTaskAction = { type: VisitTaskActionType, payload: { taskId } };
        return act;
    },
    switchOrderCategory: (orderCategory: OrderCategory) => {
        const act: SwitchCategoryAction = { type: SwitchCategoryActionType, payload: { orderCategory } };
        return act;
    },
    switchOrderZone: (zone: ServiceZone | null) => {
        const act: SwitchZoneAction = { type: SwitchZoneActionType, payload: { zone } };
        return act;
    },
    switchOrderDriver: (driver: Driver | null) => {
        const act: SwitchDriverAction = { type: SwitchDriverActionType, payload: { driver } };
        return act;
    },
    switchOrderCategoryTableView: (orderCategory: OrderCategory | '') => {
        const act: SwitchCategoryTableViewAction = {
            type: SwitchCategoryTableViewActionType,
            payload: { orderCategory },
        };
        return act;
    },
    switchOrderZoneTableView: (zone: ServiceZone | null) => {
        const act: SwitchZoneTableViewAction = { type: SwitchZoneTableViewActionType, payload: { zone } };
        return act;
    },
    switchOrderDriverTableView: (driver: Driver | null) => {
        const act: SwitchDriverTableViewAction = { type: SwitchDriverTableViewActionType, payload: { driver } };
        return act;
    },
    showHideOrderToggle: (showFullOrder: boolean) => {
        const act: ShowHideOrderToggleAction = { type: ShowHideOrderToggleActionType, payload: { showFullOrder } };
        return act;
    },
    showHideFilter: (filters: boolean[]) => {
        const act: ShowHideFilterAction = { type: ShowHideFilterActionType, payload: { filters } };
        return act;
    },
    showTableView: (toggleTableView: boolean) => {
        const act: ShowTableViewAction = { type: ShowTableViewActionType, payload: { toggleTableView } };
        return act;
    },
    togglePrintModal: (togglePrint: boolean) => {
        const act: TogglePrintAction = { type: TogglePrintActionType, payload: { togglePrint } };
        return act;
    },
    togglePrintModalOrderSection: (togglePrintOrderSection: boolean) => {
        const act: TogglePrintOrderSectionAction = {
            type: TogglePrintOrderSectionActionType,
            payload: { togglePrintOrderSection },
        };
        return act;
    },
    selectingOrderIds: (selectedOrders: string[]) => {
        const act: SelectingOrdersAction = { type: SelectingOrdersActionType, payload: { selectedOrders } };
        return act;
    },
    selectingAllOrders: (selectAllOrders: boolean) => {
        const act: SelectAllOrdersAction = { type: SelectAllOrdersActionType, payload: { selectAllOrders } };
        return act;
    },
    sortOrders: (currentOrderRefs: OrderRefItem[], columnNameToSort: string, direction: boolean) => {
        const act: SortOrdersAction = {
            type: SortOrdersActionType,
            payload: { currentOrderRefs, columnNameToSort, direction },
        };
        return act;
    },
};
