import { Navbar, NavbarBrand } from 'reactstrap';
import { appConfig } from 'appConfig';
import { Route, Switch, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useState } from 'react';
import Axios from 'axios';

const ResetPassword = ({ isDriver }: { isDriver?: boolean }) => {
    const [statusText, setStatusText] = useState('');
    const [updated, setUpdated] = useState(false);
    const { token } = useParams<{ token: string }>();

    if (updated) {
        return (
            <div>
                {isDriver ? (
                    <p>Password updated, please login from the driver app.</p>
                ) : (
                    <p>
                        Password updated, please <a href="/">login</a>.
                    </p>
                )}
            </div>
        );
    }

    const initialValues = {
        token,
        password: '',
        repeatPassword: '',
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const endpoint = isDriver
                        ? `/api/onboarding/reset-driver-password/${token}`
                        : `/api/onboarding/reset-password/${token}`;
                    Axios.post<string>(endpoint, { password: values.password })
                        .then(({ data }) => {
                            setStatusText(data);
                            if (!data) {
                                setUpdated(true);
                            }
                        })
                        .catch((err) => {
                            setStatusText(err.response?.data ?? 'An error has occurred, please try again later.');
                        })
                        .finally(() => setSubmitting(false));
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().required('Password is required'),
                    repeatPassword: Yup.string()
                        .required('Please repeat your password')
                        .oneOf([Yup.ref('password')], 'Passwords must match'),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        dirty,
                        touched,
                        errors,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <div className="form-group">
                                <label htmlFor="acct-setup-password">Password</label>
                                <input
                                    id="acct-setup-password"
                                    name="password"
                                    className={classNames('form-control', {
                                        'is-valid': touched.password && !errors.password,
                                        'is-invalid': touched.password && errors.password,
                                    })}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    autoComplete="off"
                                />
                                {touched.password && !!errors.password && (
                                    <div className="invalid-feedback">{errors.password}</div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="acct-setup-repeat-password">Repeat Password</label>
                                <input
                                    id="acct-setup-repeat-password"
                                    name="repeatPassword"
                                    className={classNames('form-control', {
                                        'is-valid': touched.repeatPassword && !errors.repeatPassword,
                                        'is-invalid': touched.repeatPassword && errors.repeatPassword,
                                    })}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    autoComplete="off"
                                />
                                {touched.repeatPassword && !!errors.repeatPassword && (
                                    <div className="invalid-feedback">{errors.repeatPassword}</div>
                                )}
                            </div>
                            <div className="mt-4 mb-4">
                                <p>{statusText}</p>
                                <button
                                    className="btn btn-primary mr-4 mb-2"
                                    type="submit"
                                    disabled={!dirty || isSubmitting}
                                >
                                    Change Password
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ResetPassword;
