import { string } from 'prop-types';
import { MerchantOrganization } from '../../../models/MerchantOrganization';

import { State } from '../State';

export const FetchMerchantOrgActionType = 'MERCHANT_ORG/FETCH';
export interface FetchMerchantOrgAction {
    type: typeof FetchMerchantOrgActionType;
    payload: { id: string };
}
