import { Reducer } from 'redux';

export interface State {
    isExpanded: boolean;
}

type KnownAction =
    | {
          type: 'EXPAND_MENU';
      }
    | {
          type: 'COLLAPSE_MENU';
      };

const initialState: State = {
    isExpanded: false,
};

export const actionCreators = {
    expand: () => ({ type: 'EXPAND_MENU' }),
    collapse: () => ({ type: 'COLLAPSE_MENU' }),
};

export const reducer: Reducer<State, KnownAction> = (state: State | undefined, action: KnownAction) => {
    state = state || initialState;

    switch (action.type) {
        case 'EXPAND_MENU':
            return { ...state, isExpanded: true };
        case 'COLLAPSE_MENU':
            return { ...state, isExpanded: false };
        default:
            return state;
    }
};
