import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

export const SplashScreen: React.SFC<{
    minHeight?: number;
    className?: string;
    size?: SizeProp;
}> = (props) => {
    const { minHeight, className, size } = props;
    return (
        <div
            className={classNames('d-flex justify-content-center align-items-center', className, {
                'h-100': !minHeight,
            })}
            style={{ minHeight: minHeight }}
        >
            <FontAwesomeIcon size={size || '5x'} icon={faSpinner as IconProp} spin={true} />
        </div>
    );
};
