import { MerchantOrderHistoryItem } from 'models/MerchantOrderHistoryItem';

export interface State {
    merchantId: string | null;
    orders: MerchantOrderHistoryItem[] | null;
    hasMore: boolean;
}

export const defaultState: State = {
    merchantId: null,
    orders: null,
    hasMore: false,
};
