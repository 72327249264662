interface AppConfig {
    companyName: string;
    accountDomain: string;
    serviceCities: string[];
    config: {
        aad: {
            signInPolicy: string;
            signInPolicyPortal: string;
            tenant: string;
            clientId: string;
        };
        api: {
            dispatch: string;
            orders: string;
            admin: string;
        };
        trackingUrlFormat: string;
        googleApiKey: string;
        googleApiKeyServerSide: string;
        hereAppId: string;
        hereAppCode: string;
        hereApiKey: string;
    };
}

const win = window as unknown as { appConfig: AppConfig };
const appConfig = win.appConfig;

export { appConfig };
