import { MerchantOrderHistoryItem } from 'models/MerchantOrderHistoryItem';

export const ReceiveOrdersActionType = 'MERCHANT_ORDER_HIST/RECEIVE_ORDERS';

export interface ReceiveOrdersAction {
    type: typeof ReceiveOrdersActionType;
    payload: {
        orders: MerchantOrderHistoryItem[];
        hasMore: boolean;
    };
}
