import { Order } from 'models/Order';

export const ShowModalActionType = 'HOLD_ORDER/SHOW_MODAL';

export interface ShowModalAction {
    type: typeof ShowModalActionType;
    payload: {
        order: Order;
        deliveryDate: string;
        deliveryTime: string;
    };
}
