import { OrderRefItem } from 'models/OrderRefItem';

export const SortOrdersActionType = 'ORDER_MGNT/SORT_ORDERS';
export interface SortOrdersAction {
    type: typeof SortOrdersActionType;
    payload: {
        currentOrderRefs: OrderRefItem[];
        columnNameToSort: string;
        direction: boolean;
    };
}
