import { GeoLocation } from 'models/GeoLocation';
import { State } from '../State';

export const UpdatePolygonActionType = 'MAP_ASSIGNMENT/UPDATE_POLYGON';
export interface UpdatePolygonAction {
    type: typeof UpdatePolygonActionType;
    payload: {
        points: GeoLocation[];
    };
}
