import { sagas as AccountMgntSagas } from './AccountMgnt';
import ChatSagas from './ChatSagas';
import { sagas as DriverEditorSagas } from './DriverEditorState';
import { sagas as DriverManagementSagas } from './DriverManagementState';
import { sagas as DriverOrderHistorySagas } from './DriverOrderHistory';
import DriverSagas from './DriverSagas';
import { sagas as DriverWorkLogHistorySagas } from './DriverWorkLogHistory';
import { sagas as HoldOrderModalSagas } from './HoldOrderModalState';
import { sagas as MapAssignmentSagas } from './MapAssignment';
import { sagas as MerchantOrderHistorySagas } from './MerchantOrderHistory';
import { sagas as MerchantRepoSagas } from './MerchantRepo';
import { sagas as MerchantOrgsSagas } from './MerchantOrganization';
import { sagas as OrderMgntSagas } from './OrderMgnt';
import { sagas as ReportingSagas } from './Reporting';
import { sagas as TaskMgntSagas } from './TaskMgntState';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        ...DriverSagas,
        ...ChatSagas,
        ...DriverManagementSagas,
        ...TaskMgntSagas,
        ...DriverEditorSagas,
        ...HoldOrderModalSagas,
        ...OrderMgntSagas,
        ...MerchantOrderHistorySagas,
        ...DriverOrderHistorySagas,
        ...DriverWorkLogHistorySagas,
        ...MerchantRepoSagas,
        ...MerchantOrgsSagas,
        ...AccountMgntSagas,
        ...ReportingSagas,
        ...MapAssignmentSagas,
    ]);
}
