// eslint-disable-next-line @typescript-eslint/no-explicit-any
let prevTime = new Date();

export const log = (...messages: any[]) => {
    if (process.env.NODE_ENV === 'development') {
        const currentTime = new Date();
        const elapsed = currentTime.getTime() - prevTime.getTime();
        prevTime = currentTime;
        console.log(new Date().toISOString(), `+${elapsed}ms`, ...messages);
    }
};
