// Libs
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { log } from '../../utils';

// States
import * as ServerState from '../ServerState';
import { AdminHub } from '../../models';
import { ApplicationState } from '../ApplicationState';
import { State } from './State';

// Hubs
import { selectAdminHub } from '../selectAdminHub';

// Types
import { MerchantOrganization } from '../../models/MerchantOrganization';

// Actions
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { SaveMerchantOrgAction, SaveMerchantOrgActionType } from './Actions/SaveMerchantOrgAction';
import { FetchMerchantOrgAction, FetchMerchantOrgActionType } from './Actions/FetchMerchantOrgAction';
import { eventChannel } from 'redux-saga';

// When the server connect, fetch all merchantOrgs using adminHub, then add them to the MerchantOrg state
function* onServerConnected() {
    const hub: AdminHub = yield selectAdminHub();
    log('[PREF]', 'getMerchantOrgs');
    const merchantOrgs: MerchantOrganization[] = yield call(() => hub.getMerchantsOrganizations());
    log('[PREF]', 'getMerchantOrgs END', merchantOrgs.length);

    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: { merchantOrgs },
    });
}

// Save a merchant organization
function* onSaveMerchantOrg(action: SaveMerchantOrgAction) {
    const hub: AdminHub = yield selectAdminHub();
    log('[PREF]', 'saveMerchantOrg');
    const merchantOrg: MerchantOrganization = yield call(() => hub.saveMerchantOrganization(action.payload));
    log('[PREF]', 'saveMerchantOrg END');

    //  Once saved, fetch it
    const act: FetchMerchantOrgAction = {
        type: FetchMerchantOrgActionType,
        payload: {
            id: merchantOrg.id,
        },
    };
    yield put(act);
}

// Fetch a merchant organization
const fetchingMerchantOrgLookup: { [id: string]: boolean } = {};
function* onFetchMerchantOrg(action: FetchMerchantOrgAction) {
    if (!fetchingMerchantOrgLookup[action.payload.id]) {
        fetchingMerchantOrgLookup[action.payload.id] = true;

        const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);
        const hubs = serverState.hubs;
        if (hubs && hubs.admin) {
            log('[PREF]', 'getMerchantOrg', action.payload.id);
            const merchantOrg: MerchantOrganization = yield call(() =>
                hubs.admin.getMerchantOrganization(action.payload.id),
            );
            log('[PREF]', 'getMerchantOrg END');
            const { merchantOrgs }: State = yield select((s: ApplicationState) => s.merchantOrgs);

            let isNewMerchantOrg = true;
            const newAllMerchantOrgs = merchantOrgs.map((m) => {
                if (m.id === merchantOrg.id) {
                    isNewMerchantOrg = false;
                    return merchantOrg;
                }

                return m;
            });

            if (isNewMerchantOrg) {
                newAllMerchantOrgs.push(merchantOrg);
            }

            yield put({
                type: UpdateStateActionType,
                payload: {
                    merchantOrgs: newAllMerchantOrgs,
                },
            });
        }

        fetchingMerchantOrgLookup[action.payload.id] = false;
    }
}

export const sagas = [
    takeEvery(ServerState.ServerConnectedActionType, onServerConnected),
    takeEvery(SaveMerchantOrgActionType, onSaveMerchantOrg),
    takeEvery(FetchMerchantOrgActionType, onFetchMerchantOrg),
];
