import * as DriverState from 'store/DriverState';
import * as React from 'react';

import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { ApplicationState } from 'store/ApplicationState';
import { DriverPunchInMode } from 'models/DriverPunchInMode';
import { NamedOption } from 'models';
import ScrollBar from 'react-perfect-scrollbar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import driverMessages from './messages';
import { getDisplayName } from 'utils/getDisplayName';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DriverVehicleType } from '../../models/DriverVehicleType';

type PunchInPanelProps = { intl: IntlShape } & DriverState.State & typeof DriverState.actionCreators;

interface PunchInPanelState {
    reasons: { [id: string]: string };
    tempTags: { [id: string]: string };
    shiftStartTime: { [id: string]: string | null };
    timeOptions: NamedOption[];
    dateOptions: NamedOption[];
    selectedTimeOption: { [id: string]: string | null };
    selectedDateOption: { [id: string]: string | null };
    punchInModes: string[];
    selectedPunchInMode: { [id: string]: string | null };
    vehicleTypes: string[];
    selectedVehicleType: { [id: string]: string | null };
}

class PunchInPanel extends React.Component<PunchInPanelProps, PunchInPanelState> {
    public state: PunchInPanelState = {
        reasons: {},
        tempTags: {},
        shiftStartTime: {},
        timeOptions: [],
        dateOptions: [],
        selectedTimeOption: {},
        selectedDateOption: {},
        punchInModes: ['Please select...', 'driver', 'hubscan', 'placeholder', 'driverBtoB', 'driverBtoBOwn'],
        selectedPunchInMode: {},
        vehicleTypes: ['car', 'bike', 'ebike'],
        selectedVehicleType: {},
    };

    public componentWillMount() {
        this.populateTimes();
        const { drivers } = this.props;

        // Initialize the default selected values
        drivers &&
            drivers.forEach((d) => {
                if (d.isPlaceholder == true) {
                    this.state.selectedPunchInMode[d.id] = 'placeholder';
                    this.state.selectedDateOption[d.id] = moment().add(1, 'd').format('YYYY-MM-DD');
                } else {
                    this.state.selectedDateOption[d.id] = moment().format('YYYY-MM-DD');
                }

                this.state.selectedTimeOption[d.id] = '12:00';
                this.state.tempTags[d.id] = '';
            });
    }

    public componentWillUpdate() {
        const { drivers } = this.props;

        // Initialize the default selected values
        drivers &&
            drivers.forEach((d) => {
                if (d.isPlaceholder == true) {
                    this.state.selectedPunchInMode[d.id] = 'placeholder';
                }
            });
    }

    public render() {
        const { drivers } = this.props;

        if (!drivers) {
            return null;
        }

        const requestingDrivers = drivers.filter((d) => d.phoneStatus === 'Request');
        return (
            <div className={`punch-in-panel h-100`}>
                <ScrollBar>
                    {requestingDrivers.map((d) => (
                        <div key={d.id} className="list-group-item">
                            <div className="text-green strong h4 m-0">{getDisplayName(d)}</div>
                            {false && (
                                <div className="form-group">
                                    <label htmlFor={`punch-in-reason-${d.id}`}>
                                        <FormattedMessage {...driverMessages.labelPunchInReason} />
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id={`punch-in-reason-${d.id}`}
                                        rows={1}
                                        value={this.state.reasons[d.id]}
                                        onChange={this.handleReasonChange(d.id)}
                                    />
                                </div>
                            )}
                            {this.state.selectedPunchInMode[d.id] == 'placeholder' && (
                                <div className="form-group">
                                    <label htmlFor={`punch-in-tag-${d.id}`}>Tag</label>
                                    <input
                                        className="form-control"
                                        id={`punch-in-tag-${d.id}`}
                                        value={this.state.tempTags[d.id]}
                                        onChange={this.handleTempTagChange(d.id)}
                                        maxLength={20}
                                    />
                                </div>
                            )}
                            <div className="form-group">
                                <select
                                    id="punchin-start-date"
                                    name="datedate"
                                    value={this.state.selectedDateOption[d.id] || ''}
                                    onChange={this.handleStartDateChange(d.id)}
                                >
                                    {this.state.dateOptions.map((d) => (
                                        <option key={d.id} value={d.id}>
                                            {d.name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    id="punchin-start-time"
                                    name="date"
                                    value={this.state.selectedTimeOption[d.id] || ''}
                                    onChange={this.handleStartTimeChange(d.id)}
                                >
                                    {this.state.timeOptions.map((d) => (
                                        <option key={d.id} value={d.id}>
                                            {d.name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className="ml-3"
                                    id="punchin-mode"
                                    name="date"
                                    value={this.state.selectedPunchInMode[d.id] || ''}
                                    onChange={this.handlePunchInModeChange(d.id)}
                                >
                                    {this.state.punchInModes.map((d) => (
                                        <option key={d} value={d}>
                                            {d}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className="ml-3"
                                    id="punchin-vehicletype"
                                    name="vehicletype"
                                    value={this.state.selectedVehicleType[d.id] || ''}
                                    onChange={this.handleVehicleTypeChange(d.id)}
                                >
                                    {this.state.vehicleTypes.map((d) => (
                                        <option key={d} value={d}>
                                            {d}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group hidden">
                                <label htmlFor={`gps-tracker-${d.id}`}>Gps Tracker</label>
                                <select className="form-control" id={`gps-tracker-${d.id}`} />
                            </div>
                            <div>
                                <button className="btn btn-secondary" onClick={this.reject(d.id)}>
                                    <FormattedMessage {...driverMessages.btnReject} />
                                </button>
                                <button className="btn btn-primary ml-2" onClick={this.punchIn(d.id)}>
                                    <FormattedMessage {...driverMessages.btnPunchIn} />
                                </button>
                            </div>
                        </div>
                    ))}
                </ScrollBar>
            </div>
        );
    }

    private punchIn = (id: string) => () => {
        const reason = this.state.reasons[id] || '';
        const tempTag = this.state.tempTags[id] || '';
        const selectedDateOption = this.state.selectedDateOption[id] || '';
        const selectedTimeOption = this.state.selectedTimeOption[id] || '';

        if (!this.state.selectedPunchInMode[id] || this.state.selectedPunchInMode[id] == 'Please select...') {
            toast.error('Please select punch in mode');
            return;
        }

        this.props.punchIn(id, {
            punchInMode: this.state.selectedPunchInMode[id] as DriverPunchInMode,
            nextShiftStartTime: moment(selectedDateOption + 'T' + selectedTimeOption).toISOString(),
            reason: reason,
            tempTag: tempTag,
            vehicleType: this.state.selectedVehicleType[id] as DriverVehicleType,
        });
    };

    private reject = (id: string) => () => {
        const reason = this.state.reasons[id] || '';
        this.props.reject(id, {
            punchInMode: 'driver',
            nextShiftStartTime: '',
            reason: reason,
            tempTag: '',
            vehicleType: 'car',
        });
    };

    private handleReasonChange = (id: string) => (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = evt.target.value;
        this.setState(({ reasons }) => {
            reasons[id] = val;
            return {
                reasons,
            };
        });
    };

    private handleTempTagChange = (id: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
        const val = evt.target.value;
        this.setState(({ tempTags }) => {
            tempTags[id] = val;
            return {
                tempTags,
            };
        });
    };

    private handleStartTimeChange = (id: string) => (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const val = evt.target.value;
        this.setState(({ selectedTimeOption }) => {
            selectedTimeOption[id] = val;
            return {
                selectedTimeOption,
            };
        });
    };

    private handleStartDateChange = (id: string) => (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const val = evt.target.value;
        this.setState(({ selectedDateOption }) => {
            selectedDateOption[id] = val;
            return {
                selectedDateOption,
            };
        });
    };

    private handlePunchInModeChange = (id: string) => (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const val = evt.target.value;
        this.setState(({ selectedPunchInMode }) => {
            selectedPunchInMode[id] = val;
            return {
                selectedPunchInMode,
            };
        });
    };

    private handleVehicleTypeChange = (id: string) => (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const val = evt.target.value;
        this.setState(({ selectedVehicleType }) => {
            selectedVehicleType[id] = val;
            return {
                selectedVehicleType,
            };
        });
    };

    private populateTimes = () => {
        let date = moment();
        const todayText = date.format('YYYY-MM-DD');

        // Add today
        this.state.dateOptions.push({
            id: todayText,
            name: `TODAY`,
        });

        // Add next days
        for (let i = 0; i < 5; ++i) {
            date = date.add(1, 'day');
            this.state.dateOptions.push({
                id: date.format('YYYY-MM-DD'),
                name: date.format('LL'),
            });
        }

        for (let h = 0; h < 24; ++h) {
            const hour = h < 10 ? `0${h}` : `${h}`;
            for (let m = 0; m < 60; m += 15) {
                const min = m < 10 ? `0${m}` : `${m}`;
                this.state.timeOptions.push({
                    id: `${hour}:${min}`,
                    name: `${hour}:${min}`,
                });
            }
        }
    };
}

export default connect(
    (state: ApplicationState) => ({ ...state.driver }),
    (dispatch) => bindActionCreators({ ...DriverState.actionCreators }, dispatch),
)(injectIntl(PunchInPanel));
