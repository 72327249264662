import { HubConnection } from '@microsoft/signalr';

import { Order } from './Order';
import { OrderEventLog } from './OrderEventLog';
import { OrderTrackingLog } from './OrderTrackingLog';

import { OrderCategory } from './OrderCategory';
import { OrderTask } from './OrderTask';
import { SmsMessage } from './SmsMessage';
import { AccountAddress } from './AccountAddress';
import { OrderTaskRef } from './OrderTaskRef';
import { MissedDeliveryAttempt } from './MissedDeliveryAttempt';
import { BulkActionLog } from './BulkActionLog';
import { ShippingLabelType } from './ShippingLabelType';
import { PackageItem } from './PackageItem';

class OrderHub {
    public constructor(public connection: HubConnection) {}

    public addPackage(order: Order, packageItem: PackageItem) {
        return this.connection.invoke<Order>('AddPackage', order, packageItem);
    }

    public removePackage(order: Order, packageItem: PackageItem) {
        return this.connection.invoke<Order>('RemovePackage', order, packageItem);
    }

    public createOrder(order: Order) {
        return this.connection.invoke<Order>('CreateOrder', order);
    }

    public saveOrder(order: Order) {
        return this.connection.invoke<Order>('SaveOrder', order);
    }

    public getOrder(id: string) {
        return this.connection.invoke<Order>('GetOrder', id);
    }

    public findActiveOrders() {
        return this.connection.invoke<Order[]>('FindActiveOrders');
    }

    public appendLog(logEntry: OrderEventLog) {
        this.connection.invoke('AppendLog', logEntry);
    }

    public saveMissedAttemptsAll(orderId: string, missedAttempts: MissedDeliveryAttempt[]) {
        this.connection.invoke('SaveMissedAttemptsAll', orderId, missedAttempts);
    }

    public saveMissedAttemptSingle(orderId: string, missedAttempt: MissedDeliveryAttempt) {
        this.connection.invoke('SaveMissedAttemptSingle', orderId, missedAttempt);
    }

    public getMissedAttemptsForOrder(orderId: string) {
        return this.connection.invoke<MissedDeliveryAttempt[]>('GetMissedAttemptsForOrder', orderId);
    }

    public sendSms(orderId: string, message: string) {
        this.connection.invoke('SendSms', orderId, message);
    }

    public setInstructions(orderId: string, instructions: string) {
        this.connection.invoke('SetInstructions', orderId, instructions);
    }

    public setFlag(orderId: string, flagName: string, flagValue: boolean) {
        this.connection.invoke('SetFlag', orderId, flagName, flagValue);
    }

    public appendTrackingLog(logEntry: OrderTrackingLog) {
        this.connection.invoke('AppendTrackingLog', logEntry);
    }

    public getLogs(orderId: string) {
        return this.connection.invoke<OrderEventLog[]>('GetLogs', orderId);
    }

    public getLogsEta(orderId: string) {
        return this.connection.invoke<OrderEventLog[]>('GetLogsEta', orderId);
    }

    public GetSmsMessages(orderId: string) {
        return this.connection.invoke<SmsMessage[]>('GetSmsMessages', orderId);
    }

    public assignDriver(orderId: string, taskId: string, driverId: string) {
        return this.connection.invoke<string>('AssignDriver', orderId, taskId, driverId);
    }

    public updateTasksPriority(tasks: Partial<OrderTaskRef>[]) {
        return this.connection.invoke<string>('UpdateTasksPriority', tasks);
    }

    public receiveOrders(orderIds: string[]) {
        return this.connection.invoke<BulkActionLog[]>('ReceiveOrders', orderIds);
    }

    public cancelOrder(
        id: string,
        reason: string,
        undeliverableCode: string,
        undeliverableReason: string,
        undeliverableReasonInternal: string,
        billMerchant: boolean,
        isPrecancel: boolean,
    ) {
        return this.connection.invoke<string>(
            'CancelOrder',
            id,
            reason,
            undeliverableCode,
            undeliverableReason,
            undeliverableReasonInternal,
            billMerchant,
            isPrecancel,
        );
    }

    public async setPriority(id: string, up: boolean) {
        return this.connection.invoke<string>('SetPriority', id, up);
    }

    public async setDesiredTime(
        id: string,
        desiredTimePickup: string,
        desiredTimeMin: string,
        desiredTimeMax: string,
        notifyClient: boolean,
        reset5MinAlert: boolean,
        reset1HourAlert: boolean,
    ) {
        return this.connection.invoke<string>(
            'setDesiredTime',
            id,
            desiredTimePickup,
            desiredTimeMin,
            desiredTimeMax,
            notifyClient,
            reset5MinAlert,
            reset1HourAlert,
        );
    }

    public async setAddress(id: string, address: AccountAddress, setPickupAddress: boolean, isFix: boolean) {
        return this.connection.invoke<string>('setAddress', id, address, setPickupAddress, isFix);
    }

    public async getShippingLabel(id: string) {
        return this.connection.invoke<string>('PrintLabel', id, 'Shipping4x6' as ShippingLabelType);
    }

    public async getShippingLabels(orderIds: string[], shippingLabelType: ShippingLabelType) {
        if (!shippingLabelType) {
            shippingLabelType = 'Shipping4x6';
        }
        return this.connection.invoke<string[]>('PrintLabels', orderIds, shippingLabelType);
    }

    public async setLabelPrinted(orderId: string, value: boolean) {
        this.connection.invoke<string[]>('SetLabelPrinted', orderId, value);
    }
}

export { OrderHub };
