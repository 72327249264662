import * as DriverManagementState from './DriverManagementState';
import * as DriverState from './DriverState';
import * as ServerState from './ServerState';

import { Driver, getDefault } from '../models/Driver';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from './ApplicationState';
import { Reducer } from 'redux';
import { WorkLog } from '../models/WorkLog';
import { eventChannel } from 'redux-saga';
import { log } from 'utils';

export interface State {
    currentDriver: Driver | null;
    currentDriverId: string | null;
    workLogs: WorkLog[] | null;
}

export const FetchDriverActionType = 'DRIVER_EDITOR/FETCHE_DRIVER';
export const ReceiveDriverActionType = 'DRIVER_EDITOR/RECEIVE_DRIVER';
export const ReceiveWorkLogsActionType = 'DRIVER_EDITOR/RECEIVE_WORKLOGS';
export const DeleteDriverActionType = 'DRIVER_EDITOR/DELETE_DRIVER';
export const RestoreDriverActionType = 'DRIVER_EDITOR/RESTORE_DRIVER';

export interface DeleteDriverAction {
    type: typeof DeleteDriverActionType;
    payload: {
        driverId: string;
    };
}

export interface RestoreDriverAction {
    type: typeof RestoreDriverActionType;
    payload: {
        driverId: string;
    };
}
export interface ReceiveWorkLogsAction {
    type: typeof ReceiveWorkLogsActionType;
    payload: {
        workLogs: WorkLog[];
    };
}

export interface FetchDriverAction {
    type: typeof FetchDriverActionType;
    payload: {
        driverId: string;
    };
}

export interface ReceiveDriverAction {
    type: typeof ReceiveDriverActionType;
    payload: {
        driver: Driver;
    };
}

export type KnownAction = FetchDriverAction | ReceiveDriverAction | ReceiveWorkLogsAction | RestoreDriverAction;

export const actionCreators = {
    fetchDriver: (driverId?: string) => ({ type: FetchDriverActionType, payload: { driverId: driverId || '' } }),
    deleteDriver: (driverId: string) => ({ type: DeleteDriverActionType, payload: { driverId: driverId } }),
    restoreDriver: (driverId: string) => ({ type: RestoreDriverActionType, payload: { driverId: driverId } }),
};

const defaultState: State = {
    currentDriver: null,
    currentDriverId: null,
    workLogs: null,
};

export const reducer: Reducer<State, KnownAction> = (state = defaultState, action): State => {
    switch (action.type) {
        case FetchDriverActionType:
            return { ...defaultState, currentDriverId: action.payload.driverId };
        case ReceiveDriverActionType:
            return { ...state, currentDriver: action.payload.driver };
        case ReceiveWorkLogsActionType:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

function* receiveDriver(action: DriverState.ReceiveDriverAction) {
    const state: State = yield select((s: ApplicationState) => s.driverEditor);

    if (state.currentDriverId && !state.currentDriver) {
        const driverId = state.currentDriverId;
        const driver = action.payload.drivers.find((d) => d.id === driverId);

        if (driver) {
            yield put({ type: ReceiveDriverActionType, payload: { driver } });
        } else {
            // fetch disabled driver
            const { hubs }: ServerState.State = yield select((s: ApplicationState) => s.server);
            const hub = hubs && hubs.admin;
            if (hub) {
                const disabledDriver: Driver = yield call(() => hub.findDriverById(driverId));
                yield put({ type: ReceiveDriverActionType, payload: { driver: disabledDriver } });
            }
        }
    }
}

function* fetchDriver(action: FetchDriverAction) {
    const driverState: DriverState.State = yield select((s: ApplicationState) => s.driver);
    const state: DriverManagementState.State = yield select((s: ApplicationState) => s.driverManagement);
    if (!action.payload.driverId) {
        // create new driver
        const driver = getDefault();
        driver.sendInvite = true;
        yield put({ type: ReceiveDriverActionType, payload: { driver } });
    } else if (driverState.drivers) {
        const driver =
            driverState.drivers.find((d) => d.id === action.payload.driverId) ||
            (state.deletedDrivers && state.deletedDrivers.find((d) => d.id === action.payload.driverId));

        yield put({ type: ReceiveDriverActionType, payload: { driver } });
    }
}

function* onReceiveDriver(action: ReceiveDriverAction) {
    // if (action.payload.driver.id) {
    //     const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);
    //     /* if (serverState.hubs && serverState.hubs.admin) {
    //         const hub = serverState.hubs.admin;
    //         const logs: WorkLog[] = yield call(() => hub.getWorkLogs(action.payload.driver.id));
    //         log('receive work log', logs);
    //         yield put({ type: ReceiveWorkLogsActionType, payload: { workLogs: logs } });
    //     }*/
    // }
}

function* onServerConnected() {
    const { hubs }: ServerState.State = yield select((s: ApplicationState) => s.server);
    const hub = hubs && hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (driverId: string) => {
                emitter(driverId);
            };

            //0 hub.connection.on('OnWorkLogAppended', callback);

            // Return an unsubscribe method
            return () => {
                // hub.connection.off('OnWorkLogAppended', callback);
            };
        });

        while (true) {
            const driverId: string = yield take(channel);
            log('OnWorkLogAppended', driverId);

            const editorState: State = yield select((s: ApplicationState) => s.driverEditor);

            if (editorState.currentDriverId === driverId) {
                const act: FetchDriverAction = {
                    type: FetchDriverActionType,
                    payload: { driverId },
                };
                yield put(act);
            }
        }
    }
}

function* onDeleteDriver(action: DeleteDriverAction) {
    const { hubs }: ServerState.State = yield select((s: ApplicationState) => s.server);
    if (hubs && hubs.admin) {
        yield call(() => hubs.admin.disableDriver(action.payload.driverId));
    }
}

function* onRestoreDriver(action: RestoreDriverAction) {
    const { hubs }: ServerState.State = yield select((s: ApplicationState) => s.server);
    if (hubs && hubs.admin) {
        yield call(() => hubs.admin.restoreDriver(action.payload.driverId));
    }
}

export const sagas = [
    takeEvery(ServerState.ServerConnectedActionType, onServerConnected),
    takeEvery(FetchDriverActionType, fetchDriver),
    takeEvery(ReceiveDriverActionType, onReceiveDriver),
    takeEvery(DeleteDriverActionType, onDeleteDriver),
    takeEvery(RestoreDriverActionType, onRestoreDriver),
    takeEvery(DriverState.ReceiveDriverActionType, receiveDriver),
];
