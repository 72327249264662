import { HoldOrderRequest } from 'models/HoldOrderRequest';

export const HoldOrderRequestActionType = 'HOLD_ORDER/HOLD_ORDER_REQUEST';

export interface HoldOrderRequestAction {
    type: typeof HoldOrderRequestActionType;
    payload: {
        data: HoldOrderRequest;
        callback: (succeed: boolean) => void;
    };
}
