import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { FetchMoreHistoryAction, FetchMoreHistoryActionType } from './Actions/FetchMoreHistoryAction';
import { ReceiveHistoryAction, ReceiveHistoryActionType } from './Actions/ReceiveHistoryAction';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ApplicationState } from '../ApplicationState';
import { DriverOrderTaskHistoryItem } from 'models/DriverOrderTaskHistoryItem';

function* onFetchOrders(action: FetchHistoryAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const orders: DriverOrderTaskHistoryItem[] = yield call(() =>
            adminHub.getDriverOrderTasks(action.payload.driverId),
        );

        const hasMore = orders.length > 50;
        if (hasMore) {
            orders.length = 50;
        }

        const act: ReceiveHistoryAction = {
            type: ReceiveHistoryActionType,
            payload: {
                orders,
                hasMore,
            },
        };

        yield put(act);
    }
}

function* onFetchMoreOrders(action: FetchMoreHistoryAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    const orderHist = state.driverOrderTasksHistory;
    const currentOrders = orderHist.orders;
    const driverId = orderHist.driverId;
    if (adminHub && driverId && currentOrders) {
        const lastOrder = currentOrders[currentOrders.length - 1];
        const orders: DriverOrderTaskHistoryItem[] = yield call(() =>
            adminHub.getDriverOrderTasks(
                driverId,
                (lastOrder.completedTimeUtcTicks && lastOrder.completedTimeUtcTicks.toString()) || '',
            ),
        );

        const hasMore = orders.length > 50;
        if (hasMore) {
            orders.length = 50;
        }

        const act: ReceiveHistoryAction = {
            type: ReceiveHistoryActionType,
            payload: {
                orders: [...currentOrders, ...orders],
                hasMore,
            },
        };

        yield put(act);
    }
}

export const sagas = [
    takeEvery(FetchHistoryActionType, onFetchOrders),
    takeEvery(FetchMoreHistoryActionType, onFetchMoreOrders),
];
