import { UnassignDriverAction, UnassignDriverActionType } from './Actions/UnassignDriverAction';
import { AssignDriverAction, AssignDriverActionType } from './Actions/AssignDriverAction';
import { RefreshEtasAction, RefreshEtasActionType } from './Actions/RefreshEtasAction';
import { RefreshDriverETAAction, RefreshDriverETAActionType } from './Actions/RefreshDriverETAAction';
import { RefreshTaskAction, RefreshTaskActionType } from './Actions/RefreshTaskAction';
import { UpdateFilterAction, UpdateFilterActionType } from './Actions/UpdateFilterAction';
import { UpdatePolygonAction, UpdatePolygonActionType } from './Actions/UpdatePolygonAction';

import { GeoLocation } from 'models/GeoLocation';
import { MapAssignmentFilterValues } from 'models/MapAssignmentFilter';

export const actionCreators = {
    refreshTasks: () => {
        const act: RefreshTaskAction = { type: RefreshTaskActionType };
        return act;
    },
    updateFilter: (filter: MapAssignmentFilterValues) => {
        const act: UpdateFilterAction = { type: UpdateFilterActionType, payload: { filter } };
        return act;
    },
    updatePolygon: (points: GeoLocation[]) => {
        const act: UpdatePolygonAction = { type: UpdatePolygonActionType, payload: { points } };
        return act;
    },
    assignToDrivers: (driverId: string, autoRoute: boolean) => {
        const act: AssignDriverAction = { type: AssignDriverActionType, payload: { driverId, autoRoute } };
        return act;
    },
    unassignFromDrivers: () => {
        const act: UnassignDriverAction = { type: UnassignDriverActionType };
        return act;
    },
    refreshETAs: () => {
        const act: RefreshEtasAction = { type: RefreshEtasActionType };
        return act;
    },
    refreshDriverETA: (driverId: string) => {
        const act: RefreshDriverETAAction = { type: RefreshDriverETAActionType, payload: { driverId } };
        return act;
    },
};
