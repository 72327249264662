import { Store } from 'redux';
import { log } from './log';
import { push } from 'connected-react-router';

type MessageData = {
    action: 'open-order';
    orderId: string;
    taskId?: string;
};

export function initBroadcasetChannel(store: Store) {
    window.onmessage = (ev: MessageEvent) => {
        const data = ev.data as MessageData;
        if (data.action === 'open-order') {
            if (!data.taskId) {
                store.dispatch(push(`/orders/${data.orderId}`));
            } else {
                store.dispatch(push(`/dispatching/${data.orderId}/${data.taskId}`));
            }
        }
    };
}
