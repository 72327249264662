import * as ChatState from 'store/ChatState';
import * as DriverState from 'store/DriverState';
import * as NaviState from 'store/NaviState';
import * as ServerState from 'store/ServerState';

import { Button, Nav, Navbar, NavbarBrand } from 'reactstrap';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { faComment, faCommentDots, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCommentAlt, faUserAlt } from '@fortawesome/pro-regular-svg-icons';

import { ApplicationState } from 'store/ApplicationState';
import { Driver } from 'models/Driver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NamedOption } from 'models';
import React from 'react';
import { appConfig } from 'appConfig';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import messages from './messages';
import moment from 'moment';
import MySelect from './Fields/MySelect';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type HeaderProps = {
    intl: IntlShape;
    displayName: string;
    drivers: Driver[] | null;
    merchantView: boolean;
} & ChatState.State &
    ServerState.State &
    typeof ChatState.actionCreators &
    typeof DriverState.actionCreators &
    NaviState.State &
    typeof NaviState.actionCreators;

class Header extends React.Component<HeaderProps, {}> {
    private dateOptions: NamedOption[] = [];

    constructor(props: HeaderProps) {
        super(props);

        let date = moment();

        // Add today
        this.dateOptions.push({
            id: date.format('YYYY-MM-DD'),
            name: date.format('LL') + ' TODAY',
        });

        for (let i = 0; i < 3; ++i) {
            date = date.add(1, 'day');
            this.dateOptions.push({
                id: date.format('YYYY-MM-DD'),
                name: date.format('LL'),
            });
        }
    }

    public render() {
        const { intl, merchantView, serviceCitiesFilter, unreadCount, sysSettings } = this.props;

        const unreadCountBasedOnServiceCitiesFilter: number =
            serviceCitiesFilter.length > 0
                ? Array.from(unreadCount)
                      .filter((ele) => serviceCitiesFilter.includes(ele[0]))
                      .reduce((curr, b) => curr + b[1], 0)
                : Array.from(unreadCount).reduce((curr, b) => curr + b[1], 0);

        const chatOutline = this.props.isExpanded ? 'btn-outline-success' : 'btn-outline-secondary';

        const hasRequestingDriver =
            this.props.drivers && this.props.drivers.filter((d) => d.phoneStatus === 'Request').length > 0;

        const options: { label: string; value: string }[] = [];

        for (const city of sysSettings?.serviceCities || appConfig.serviceCities) {
            options.push({ label: city, value: city });
        }

        return (
            <div>
                <Navbar color="light" light={true} expand="md">
                    <NavbarBrand href="/">{sysSettings?.name || appConfig.companyName}</NavbarBrand>
                    <Nav className="ml-auto" navbar={true} style={{ alignItems: 'center' }}>
                        {!merchantView && hasRequestingDriver && (
                            <Button className={`my-1 my-sm-0 blink`} onClick={this.props.togglePunchInPanel}>
                                <FontAwesomeIcon fixedWidth={true} icon={faUserCheck as IconProp} />
                            </Button>
                        )}
                        {!merchantView && (
                            <form className="form-inline">
                                <div className="form-check mr-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="chk-display-order-time-zone"
                                        onChange={this.props.toggleTimeDisplay}
                                        checked={!this.props.showLocalTime}
                                    />
                                    <label
                                        className="form-check-label navbar-text c-pointer"
                                        htmlFor="chk-display-order-time-zone"
                                    >
                                        Display Time in Order's Timezone
                                    </label>
                                </div>

                                <select
                                    className="form-control"
                                    onChange={this.onDispatchDateChanged}
                                    value={this.props.dispatchDateFilter}
                                >
                                    <option value="">Select Dispatch Date</option>
                                    {this.dateOptions.map((d) => (
                                        <option key={d.id} value={d.id}>
                                            {d.name}
                                        </option>
                                    ))}
                                </select>

                                {/* <select
                                    className="form-control"
                                    onChange={this.onServiceCitiesChanged}
                                    value={this.props.serviceCitiesFilter}
                                >
                                    <option value="">
                                        {this.props.serviceCitiesFilter.length > 0
                                            ? this.props.serviceCitiesFilter.length === 1
                                                ? this.props.serviceCitiesFilter[0]
                                                : `${this.props.serviceCitiesFilter.length} cities selected`
                                            : 'Select Service City'}
                                    </option>
                                    {appConfig.serviceCities.map((c) => (
                                        <option key={c} value={c}>
                                            {c}
                                        </option>
                                    ))}
                                </select> */}
                                <div
                                    style={{
                                        width: 370,
                                        marginLeft: 5,
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                >
                                    <MySelect
                                        closeMenuOnSelect
                                        isMulti={true}
                                        options={options}
                                        placeholder="Select Service Cities"
                                        onChange={this.onServiceCitiesChanged}
                                    ></MySelect>
                                </div>
                            </form>
                        )}
                        <span className="navbar-text mr-3 ml-3 d-none d-md-block">{this.props.displayName}</span>
                        <Button
                            onClick={this.props.toggleMessagePanel}
                            className={`my-2 my-sm-0 ${chatOutline}`}
                            title={intl.formatMessage({ id: 'menu.chat' })}
                        >
                            {unreadCountBasedOnServiceCitiesFilter > 0 ? (
                                <FontAwesomeIcon color="red" fixedWidth={true} icon={faCommentDots as IconProp} />
                            ) : (
                                <FontAwesomeIcon fixedWidth={true} icon={faComment as IconProp} />
                            )}
                            <span className="sr-only">
                                <FormattedMessage id="menu.chat" defaultMessage="Chat" />
                            </span>
                        </Button>
                    </Nav>
                </Navbar>
            </div>
        );
    }

    private onDispatchDateChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const date = e.target.value;
        this.props.changeDispatchDate(date);
    };

    private onServiceCitiesChanged = (options: any) => {
        if (!options) {
            this.props.changeServiceCities([]);
            return;
        }

        this.props.changeServiceCities(options.map((o: { label: string }) => o.label));
    };
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        ...state.server,
        ...state.chat,
        ...state.navi,
        drivers: state.driver.drivers,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        { ...ChatState.actionCreators, ...NaviState.actionCreators, ...DriverState.actionCreators },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
