import { Merchant } from '../../models/Merchant';

export interface State {
    merchants: { [id: string]: Merchant };
    timezoneOffsets: { [id: string]: number };
    allMerchants: Merchant[];
    activeMerchants: Merchant[];
}

export const defaultState: State = {
    merchants: {},
    timezoneOffsets: {},
    allMerchants: [],
    activeMerchants: [],
};
