import * as DriverState from './DriverState';
import * as OrderMgnt from './OrderMgnt';
import * as ServerState from './ServerState';
import * as NaviState from './NaviState';

import Axios, { AxiosResponse } from 'axios';
import _, { Dictionary } from 'lodash';
import { call, debounce, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { formatAddress, log } from 'utils';

import { AdminHub } from 'models';
import { ApplicationState } from './ApplicationState';
import { Driver } from 'models/Driver';
import { GeoLocation } from 'models/GeoLocation';
import { HereAppInfo } from 'models/HereAppInfo';
import { HubConnectionState } from '@microsoft/signalr';
import { OrderChangedNotification } from '../models/OrderChangedNotification';
import { OrderTask } from '../models/OrderTask';
import { OrderTaskRef } from 'models/OrderTaskRef';
import { OrderTaskRefItem } from 'models/OrderTaskRefItem';
import { Reducer } from 'redux';
import { Toast } from 'reactstrap';
import { appConfig } from '../appConfig';
import { eventChannel, Task } from 'redux-saga';
import moment from 'moment';
import { selectAdminHub } from './selectAdminHub';
import { toast } from 'react-toastify';
import { TaskGroup } from 'components/Dispatching/TaskGroup';
import { TypeOf } from 'yup';

export const ActivateActionType = 'TASK_MGNT/ACTIVATE';
export const DeactivateActionType = 'TASK_MGNT/DEACTIVATE';
export const ToggleDeliveryTaskFilterActionType = 'TASK_MGNT/TOGGLE_DELIVERY_TASK_FILTER';
export const ToggleDriverTaskListActionType = 'TASK_MGNT/TOGGLE_DRIVER_TASK_LIST';
export const ToggleUnassignedTaskListActionType = 'TASK_MGNT/TOGGLE_UNASSIGNED_TASK_LIST';
export const ToggleOnHoldTaskListActionType = 'TASK_MGNT/TOGGLE_ON_HOLD_TASK_LIST';
export const TogglePreOrderTaskListActionType = 'TASK_MGNT/TOGGLE_PREORDER_TASK_LIST';
export const UpdateStateActionType = 'TASK_MGNT/UPDATE_STATE';
export const BeginAutoRouteActionType = 'TASK_MGNT/BEGIN_AUTO_ROUTE';
export const CompleteTaskActionType = 'TASK_MGNT/COMLETE_TASK';
export const LoadTaskActionType = 'TASK_MGNT/LOAD_TASK';
export const UnassignDriverActionType = 'TASK_MGNT/UNASSIGN_DRIVER';
export const CreateWayPointActionType = 'TASK_MGNT/CREATE_WAYPOINT';
export const CancelWayPointActionType = 'TASK_MGNT/CANCEL_WAYPOINT';
export const WayPointUpdateCompletedActionType = 'TASK_MGNT/WAYPOINT_UPDATED';
export const ChangeAddressActionType = 'TASK_MGNT/CHANGE_ADDRESS';
export const SetTaskGroupAddressActionType = 'TASK_MGNT/SET_TASK_GROUP_ADDRESS';

const newOrderAudio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/NewOrder.mp3`);
newOrderAudio.loop = true;
const wayPointPendingReload: string[] = [];

const mapTaskItem = (t: OrderTaskRef): OrderTaskRefItem => ({
    ...t,
    deliveryAddressText: formatAddress(t.address),
    desiredTimeMnt: t.desiredTime ? moment(t.desiredTime) : moment(),
});

type FilteredOrderTask = {
    time: number;
} & OrderTaskRefItem;

export type DriverWithTasks = {
    tasks: OrderTaskRefItem[];
    expanded: number; // instead of boolean, set to integer to keep track of last expanded list. FIFO. 0 will be not-expanded. 3 will be last dropdown expanded. 1 will be first expanded.
} & Driver;

export interface LoadTaskAction {
    type: typeof LoadTaskActionType;
}

export interface CompleteTaskAction {
    type: typeof CompleteTaskActionType;
    payload: {
        taskIds: string[];
    };
}

export interface UnassignDriverAction {
    type: typeof UnassignDriverActionType;
    payload: {
        taskIds: string[];
    };
}

export interface ToggleDriverTaskListAction {
    type: typeof ToggleDriverTaskListActionType;
    payload: {
        driverId: string;
    };
}

export interface BeginAutoRouteAction {
    type: typeof BeginAutoRouteActionType;
    payload: {
        driverId: string;
        taskGroups: number[];
    };
}

export interface UpdateStateAction {
    type: typeof UpdateStateActionType;
    payload: Partial<State>;
}

export interface CreateWayPointAction {
    type: typeof CreateWayPointActionType;
    payload: {
        orderTask: OrderTask;
    };
}

export interface CancelWayPointAction {
    type: typeof CancelWayPointActionType;
    payload: {
        taskId: string;
    };
}

export interface WayPointUpdateCompletedAction {
    type: typeof WayPointUpdateCompletedActionType;
    payload: {
        orderTaskIds: string[];
    };
}

export interface ChangeAddressAction {
    type: typeof ChangeAddressActionType;
    payload: {
        taskGroup: TaskGroup;
        task: OrderTask;
        showAddress: boolean;
    };
}

export type KnownAction =
    | {
          type: typeof ActivateActionType;
      }
    | {
          type: typeof DeactivateActionType;
      }
    | ToggleDriverTaskListAction
    | {
          type: typeof ToggleDeliveryTaskFilterActionType;
      }
    | {
          type: typeof ToggleUnassignedTaskListActionType;
      }
    | {
          type: typeof ToggleOnHoldTaskListActionType;
      }
    | {
          type: typeof TogglePreOrderTaskListActionType;
      }
    | BeginAutoRouteAction
    | UpdateStateAction
    | CompleteTaskAction
    | UnassignDriverAction
    | CreateWayPointAction
    | CancelWayPointAction
    | ChangeAddressAction;

export interface State {
    showUnassignedDeliveryTasks: boolean;
    unassignedListExpanded: boolean;
    onholdListExpanded: boolean;
    preOrderListExpanded: boolean;

    drivers: DriverWithTasks[];
    unassignedTasks: FilteredOrderTask[];
    onholdTasks: OrderTaskRefItem[];
    preOrderTasks: OrderTaskRefItem[];
    filterByDriverId: string | null;

    taskRefs: OrderTaskRefItem[];
    taskDict: { [id: string]: OrderTaskRefItem }; // lookup version of taskRefs
    taskByOrderDict: { [orderIdSeq: string]: OrderTaskRefItem }; // lookup version of taskRefs

    taskGroup: TaskGroup | null;
    task: OrderTask | null;
    showAddress: boolean;
}

export const actionCreators = {
    activateTaskMgnt: () => ({ type: ActivateActionType }),
    deactivateTaskMgnt: () => ({ type: DeactivateActionType }),
    toggleDeliveryTaskFilter: () => ({ type: ToggleDeliveryTaskFilterActionType }),
    toggleDriverTaskList: (driverId: string) => ({ type: ToggleDriverTaskListActionType, payload: { driverId } }),
    toggleUnassignedTaskList: () => ({ type: ToggleUnassignedTaskListActionType }),
    toggleOnHoldTaskList: () => ({ type: ToggleOnHoldTaskListActionType }),
    togglePreOrderTaskList: () => ({ type: TogglePreOrderTaskListActionType }),
    filterListByDriver: (driverId: string | null) => ({
        type: UpdateStateActionType,
        payload: { filterByDriverId: driverId },
    }),
    autoRoute: (driverId: string, taskGroups: number[]) => ({
        type: BeginAutoRouteActionType,
        payload: { driverId, taskGroups },
    }),
    completeTasks: (taskIds: string[]) => ({
        type: CompleteTaskActionType,
        payload: {
            taskIds,
        },
    }),
    unassignDriver: (taskIds: string[]) => ({
        type: UnassignDriverActionType,
        payload: {
            taskIds,
        },
    }),
    createWaypoint: (orderTask: OrderTask) => ({
        type: CreateWayPointActionType,
        payload: {
            orderTask,
        },
    }),
    cancelWaypoint: (taskId: string) => ({
        type: CancelWayPointActionType,
        payload: {
            taskId,
        },
    }),
    changeAddress: (taskGroup: TaskGroup | null, task: OrderTask | null, showAddress: boolean) => ({
        type: ChangeAddressActionType,
        payload: {
            taskGroup,
            task,
            showAddress,
        },
    }),
};

const defaultState: State = {
    showUnassignedDeliveryTasks: false,
    unassignedListExpanded: false,
    drivers: [],
    unassignedTasks: [],
    onholdListExpanded: false,
    preOrderListExpanded: false,
    onholdTasks: [],
    preOrderTasks: [],
    filterByDriverId: null,

    taskRefs: [],
    taskDict: {},
    taskByOrderDict: {},

    taskGroup: null,
    task: null,
    showAddress: false,
};

export const reducer: Reducer<State, KnownAction> = (state = defaultState, action): State => {
    switch (action.type) {
        case UpdateStateActionType:
            return { ...state, ...action.payload };
        case ToggleDeliveryTaskFilterActionType:
            return { ...state, showUnassignedDeliveryTasks: !state.showUnassignedDeliveryTasks };
        case ToggleUnassignedTaskListActionType:
            return { ...state, unassignedListExpanded: !state.unassignedListExpanded };
        case ToggleOnHoldTaskListActionType:
            return { ...state, onholdListExpanded: !state.onholdListExpanded };
        case TogglePreOrderTaskListActionType:
            return { ...state, preOrderListExpanded: !state.preOrderListExpanded };
        default:
            return state;
    }
};

function* updateTaskList() {
    const { driver, taskMgnt, server }: ApplicationState = yield select();

    if (!server.sysSettings) {
        return;
    }

    const preorderHrs = server.sysSettings.preorderHours > 0 ? server.sysSettings.preorderHours : 15;
    const currentDriverLookup = _.keyBy(taskMgnt.drivers, (d) => d.id);

    const preorderDate = moment().add(preorderHrs, 'hours');

    const drivers = driver.drivers;
    if (!drivers) {
        if (!driver.isLoading) {
            yield put({ type: DriverState.FetchDriverActionType });
        }

        return; // tasks will be loaded when drivers ready
    }

    const extDrivers: DriverWithTasks[] = drivers.map((d) => ({
        ...d,
        expanded:
            currentDriverLookup[d.id] && currentDriverLookup[d.id].expanded ? currentDriverLookup[d.id].expanded : 0,
        tasks: [],
    }));

    const tasks = taskMgnt.taskRefs.filter((t) => t.status !== 'Canceled' && t.status !== 'Completed');

    const byDrivers = _.groupBy(tasks, (t) => {
        if (t.onHold) {
            return 'on-hold';
        }

        if (!t.driverId) {
            if (t.desiredTime && preorderDate.isBefore(t.desiredTime)) {
                return 'pre-order';
            }

            return 'unassigned';
        }
        return t.driverId;
    });

    const filtered = (byDrivers['unassigned'] || [])
        .filter((t) => {
            if (t.driverId !== null) {
                return null;
            }

            return t;
        })
        .map((t) => {
            const time = new Date(t.desiredTime);
            return {
                ...t,
                time: time.getTime(),
            };
        });

    filtered.sort((a, b) => {
        return a.id < b.id ? -1 : 1;
    });

    extDrivers.forEach((d) => {
        d.tasks = (byDrivers[d.id] || []).sort((a, b) => {
            if (a.groupOrder < b.groupOrder) return -1;
            if (a.groupOrder > b.groupOrder) return 1;
            if (a.sequence < b.sequence) return -1;
            return 1;
        });
    });

    const onholdTasksTemp = byDrivers['on-hold'] || [];

    const onholdTasks = _.sortBy(onholdTasksTemp, (t) => {
        return t.driverName + t.order.seqId;
    });

    const preOrderTasks = byDrivers['pre-order'] || [];

    try {
        const { navi }: ApplicationState = yield select();
        const serviceCities = navi.serviceCitiesFilter;
        const filteredUnreadTask = filtered
            .filter((task) => {
                if (serviceCities.length === 0 || serviceCities.includes(task.serviceCity)) {
                    return task;
                } else {
                    return null;
                }
            })
            .find((task) => task.isNew);

        if (filteredUnreadTask) {
            if (newOrderAudio.paused) {
                yield call(() => newOrderAudio.play());
            }
        } else {
            if (!newOrderAudio.paused) {
                yield call(() => newOrderAudio.pause());
            }
        }
    } catch (err) {
        console && console.warn && console.warn('fail to play new order audio', err);
    }

    const act: UpdateStateAction = {
        type: UpdateStateActionType,
        payload: {
            drivers: extDrivers,
            unassignedTasks: filtered,
            onholdTasks,
            preOrderTasks,
        },
    };

    yield put(act);
}

function* onToggleDriverTaskList(action: ToggleDriverTaskListAction) {
    const { taskMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
        taskMgnt: s.taskMgnt,
    }));

    var prevExpanded = 0;
    const driver = taskMgnt.drivers.filter((d) => {
        if (d.id === action.payload.driverId) {
            prevExpanded = d.expanded;
        }
    });

    const drivers = taskMgnt.drivers.map((d) => {
        // check if what we clicked was not expanded before click. if not, expand it and push into queue
        if (prevExpanded == 0) {
            if (d.expanded == 3) {
                d.expanded = 2; // expanded - middle of queue
            } else if (d.expanded == 2) {
                d.expanded = 1; // expanded - start of queue
            } else if (d.expanded == 1) {
                d.expanded = 0; // not expanded
            } else if (d.id === action.payload.driverId) {
                d.expanded = 3; // expanded - end of queue
            }
        } else {
            if (d.id === action.payload.driverId) {
                d.expanded = 0; // not expanded
            }
        }

        return d;
    });

    const act: UpdateStateAction = {
        type: UpdateStateActionType,
        payload: {
            drivers,
        },
    };

    yield put(act);
}

interface WaypointInfo {
    destNum: number;
    id: string;
    location: GeoLocation;
    before: number[];
    tasks: OrderTaskRefItem[];
}

interface HereFindSequenceResponse {
    errors: string[];
    processingTimeDesc: string;
    results: {
        waypoints: {
            id: string;
            lat: number;
            lng: number;
            sequence: number;
        }[];
    }[];
}

//let hereApp: HereAppInfo | null = null;

function* onBeginAutoRoute(action: BeginAutoRouteAction) {
    const { taskMgnt, server }: ApplicationState = yield select();
    const state = taskMgnt;
    const settings = server.sysSettings;

    const selectedGroups = action.payload.taskGroups;
    if (selectedGroups.length < 2) {
        yield call(toast, 'Please select more tasks for auto-route');
        return;
    }

    selectedGroups.sort((a, b) => a - b);
    /*for (let i = 1; i < selectedGroups.length; ++i) {
        if (selectedGroups[i] !== selectedGroups[i - 1] + 1) {
            yield call(toast, 'Only consecutive tasks can be auto-routed');
            return;
        }
    }*/

    //if (!hereApp) {
    //    const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);
    //    //hereApp = yield call(() => serverState.hubs && serverState.hubs.admin.getHereAppInfo());
    //    //log('receive here config', hereApp);
    //}

    if (appConfig?.config?.hereApiKey) {
        const driverTasks = state.drivers.find((d) => d.id === action.payload.driverId);
        if (driverTasks) {
            const groupedTasks = _.groupBy(driverTasks.tasks, (t) => t.groupOrder);
            const vehicleType =
                driverTasks.vehicleType == 'car'
                    ? 'car'
                    : driverTasks.vehicleType == 'bike'
                    ? 'bicycle'
                    : driverTasks.vehicleType == 'ebike'
                    ? 'bicycle'
                    : 'car';

            const waypoints: WaypointInfo[] = selectedGroups.map((g, i) => {
                const groupTasks = groupedTasks[g];

                const waypoint: WaypointInfo = {
                    id: `g-${g}`,
                    destNum: i + 1,
                    location: groupTasks[0].address.location,
                    before: [],
                    tasks: groupTasks,
                };

                return waypoint;
            });

            for (const wp of waypoints) {
                for (const beforeWp of waypoints) {
                    if (wp !== beforeWp) {
                        let isPrerequisite = false;
                        for (const wpTask of wp.tasks) {
                            if (isPrerequisite) {
                                break;
                            }

                            for (const preTask of beforeWp.tasks) {
                                if (preTask.order.id === wpTask.order.id && preTask.sequence < wpTask.sequence) {
                                    isPrerequisite = true;
                                    break;
                                }
                            }
                        }

                        if (isPrerequisite) {
                            beforeWp.before.push(wp.destNum);
                        }
                    }
                }
            }

            const departure = moment().format('YYYY-MM-DDTHH:mm:ssZ');

            const endpoint = `https://wps.hereapi.com/v8/findsequence2`;

            let modeType =
                settings && settings.hereRoutingModeType && settings.hereRoutingModeType.length > 0
                    ? settings?.hereRoutingModeType
                    : `fastest`;
            let modeTransportMode =
                settings && settings.hereRoutingModeTransportModes && settings.hereRoutingModeTransportModes.length > 0
                    ? settings?.hereRoutingModeTransportModes
                    : `${vehicleType}`;
            let modeTrafficMode =
                settings && settings.hereRoutingModeTrafficMode && settings.hereRoutingModeTrafficMode.length > 0
                    ? settings?.hereRoutingModeTrafficMode
                    : `traffic:disabled`;
            let modeRouteFeature1 =
                settings && settings.hereRoutingModeRouteFeature1 && settings.hereRoutingModeRouteFeature1.length > 0
                    ? settings?.hereRoutingModeRouteFeature1
                    : ``;
            let modeRouteFeature2 =
                settings && settings.hereRoutingModeRouteFeature2 && settings.hereRoutingModeRouteFeature2.length > 0
                    ? settings?.hereRoutingModeRouteFeature2
                    : ``;

            let mode = `${modeType};${modeTransportMode};${modeTrafficMode}${
                modeRouteFeature1.length > 0 ? ';' + modeRouteFeature1 : ''
            }${modeRouteFeature2.length > 0 ? ';' + modeRouteFeature2 : ''}`;

            let url = `${endpoint}?apiKey=${appConfig.config.hereApiKey}&mode=${mode}&departure=${departure}`;

            const prevGroup = groupedTasks[selectedGroups[0] - 1];
            if (prevGroup) {
                const loc = prevGroup[0].address.location;
                url += `&start=prev;${loc.latitude},${loc.longitude}`;
            } else {
                const driverState: DriverState.State = yield select((s: ApplicationState) => s.driver);
                const loc = driverState.driverLocations[action.payload.driverId];
                url += `&start=driver;${loc.latitude},${loc.longitude}`;
            }

            for (const wp of waypoints) {
                url += `&destination${wp.destNum}=${wp.id};${wp.location.latitude},${wp.location.longitude}`;
                if (wp.before.length > 0) {
                    url += `;before:${wp.before.map((d) => `destination${d}`).join(',')}`;
                }

                // TODO: customer drop off time
            }

            try {
                log('[HERE] findsequence', url);

                const resp: AxiosResponse<HereFindSequenceResponse> = yield call(() =>
                    Axios.get<HereFindSequenceResponse>(url),
                );
                log('[HERE] findsequence response', resp.data);

                const hereResp = resp.data;
                if ((hereResp.errors && hereResp.errors.length > 0) || !hereResp.results[0]) {
                    console.warn('auto route error', hereResp);
                    yield call(toast, 'Error occurred when auto-route');
                    return;
                }

                const routedWaypoints = hereResp.results[0].waypoints;

                const returnedGroups: number[] = [];
                let minGroup = 1000000;
                let maxGroup = 0;
                for (const wp of routedWaypoints) {
                    if (wp.id.startsWith('g-')) {
                        const taskGroup = Number.parseInt(wp.id.substr(2));
                        returnedGroups.push(taskGroup);
                        minGroup = Math.min(minGroup, taskGroup);
                        maxGroup = Math.max(maxGroup, taskGroup);
                    }
                }

                const reorderedTasks: OrderTaskRef[] = [];
                for (const task of driverTasks.tasks) {
                    if (task.groupOrder < minGroup) {
                        reorderedTasks.push(task);
                    }
                }

                let groupIdx = minGroup;
                for (const g of returnedGroups) {
                    for (const task of groupedTasks[g]) {
                        task.groupOrder = groupIdx;
                        reorderedTasks.push(task);
                    }

                    ++groupIdx;
                }

                for (const task of driverTasks.tasks) {
                    if (task.groupOrder > maxGroup) {
                        reorderedTasks.push(task);
                    }
                }

                log(
                    '[HERE] reorderedTasks',
                    reorderedTasks.map((t) => `${t.groupOrder}:${t.order.seqId}`),
                );
                const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);

                yield call(() => serverState.hubs && serverState.hubs.admin.updateTasksPriority(reorderedTasks));
                log('[HERE] reorderedTasks COMPLETED');

                yield call(
                    () => serverState.hubs && serverState.hubs.admin.refreshEtaForDriver(action.payload.driverId),
                );
                log('[HERE] refreshEtaForDriver COMPLETED');
            } catch (err) {
                log('[HERE]', err);

                yield call(toast.error, 'Error when auto-route');
            }
        }
    }
}

function* onCompleteTask(action: CompleteTaskAction) {
    const hub: AdminHub = yield selectAdminHub();
    log('[PREF]', 'completeTasks', action.payload.taskIds.length);
    yield call(() => hub.completeTasks(action.payload.taskIds));
    log('[PREF]', 'completeTasks end');
}

function* onUnassignDriver(action: UnassignDriverAction) {
    try {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'unassignDriver', action.payload.taskIds);
        yield call(() => hub.unassignDriver(action.payload.taskIds));
        log('[PREF]', 'unassignDriver end');
    } catch (err) {
        yield call(() => toast.error('Failed to unassign task!'));
        log('faild to unassign task', err);
    }
}

function* updateTaskRefs(tasks?: OrderTaskRefItem[]) {
    if (tasks) {
        const { taskMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
            taskMgnt: s.taskMgnt,
        }));

        if (taskMgnt.taskRefs !== tasks) {
            const dict = _.keyBy(tasks, (t) => t.id);
            const taskByOrderDict = _.keyBy(tasks, (t) => `${t.order.id}/${t.sequence}`);
            yield put<UpdateStateAction>({
                type: UpdateStateActionType,
                payload: {
                    taskRefs: tasks,
                    taskDict: dict,
                    taskByOrderDict,
                },
            });
        }
    }

    yield updateTaskList();
}

function* onDriverListUpdate() {
    yield updateTaskRefs();
}

function* onOrderReloadCompleted(action: OrderMgnt.OrderReloadCompletedAction) {
    const hub: AdminHub = yield selectAdminHub();

    if (hub.connection.state == HubConnectionState.Connected) {
        log('[PREF]', 'getTasksByOrders', action.payload.orderIds.length);
        const tasks: OrderTaskRef[] = yield call(() => hub.getTasksByOrders(action.payload.orderIds));
        log('[PREF]', 'getTasksByOrders end', tasks?.length ?? 'NO TASKS');

        if (tasks) {
            const { taskMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
                taskMgnt: s.taskMgnt,
            }));

            const lookup = _.keyBy(tasks, (t) => t.id);
            const newTasks = taskMgnt.taskRefs.map((t) => {
                const updated = lookup[t.id];
                if (updated) {
                    delete lookup[t.id];
                    return mapTaskItem(updated);
                }

                return t;
            });

            for (const newTaskId of Object.keys(lookup)) {
                newTasks.push(mapTaskItem(lookup[newTaskId]));
            }

            yield updateTaskRefs(newTasks);
        } else {
            // if hub.getTasksByOrders failed, tasks will be undefined, retry
            yield call(() => toast.warn('Could not reload tasks, retrying...'));
            log('[ERR]', 'onOrderReloadCompleted: Could not reload tasks, retrying...');
            yield delay(2000);
            yield put<OrderMgnt.OrderReloadCompletedAction>({
                type: OrderMgnt.OrderReloadCompletedActionType,
                payload: {
                    orderIds: action.payload.orderIds,
                },
            });
        }
    } else {
        yield call(() => toast.warn('Could not reload tasks, retrying...'));
        log('[ERR]', 'onOrderReloadCompleted: Could not reload tasks, retrying...');
        yield delay(2000);
        yield put<OrderMgnt.OrderReloadCompletedAction>({
            type: OrderMgnt.OrderReloadCompletedActionType,
            payload: {
                orderIds: action.payload.orderIds,
            },
        });
    }
}

function* onChangeAddress(action: ChangeAddressAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            taskGroup: action.payload.taskGroup,
            task: action.payload.task,
            showAddress: action.payload.showAddress,
        },
    });
}

function* reloadTasks() {
    try {
        const state: ApplicationState = yield select();
        const hub = state.server.hubs && state.server.hubs.adminOrderHub;
        if (hub) {
            log('[PREF]', 'getAllTasks');
            const tasks: OrderTaskRef[] = yield call(() => hub.getAllTasks());
            log('[PREF]', 'getAllTasks end', tasks?.length ?? 'NO TASKS reloadTasks');

            const items: OrderTaskRefItem[] = tasks.map((t) => ({
                ...t,
                deliveryAddressText: formatAddress(t.address),
                desiredTimeMnt: t.desiredTime ? moment(t.desiredTime) : moment(),
            }));

            yield updateTaskRefs(items);
        }
    } catch (err) {
        yield call(() => toast.error('Failed to reload tasks!'));
        log('fail to fetch tasks', err);
    }
}

function* onCreateWayPoint(action: CreateWayPointAction) {
    try {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'createWayPoint');

        const { taskMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
            taskMgnt: s.taskMgnt,
        }));

        const taskRef: OrderTaskRef = yield call(() => hub.createWayPoint(action.payload.orderTask));
        yield call(() => toast.success('WayPoint created!'));

        log('[PREF]', 'createWayPoint end');
    } catch (err) {
        yield call(() => toast.error('Failed to create waypoint :('));
        log('fail to create waypoint', err);
    }
}

function* onCancelWayPoint(action: CancelWayPointAction) {
    try {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'cancelWayPoint');
        yield call(() => hub.cancelTask(action.payload.taskId));
        yield call(() => toast.success('WayPoint cancelled!'));
        yield reloadTasks();
        log('[PREF]', 'cancelWayPoint end');
    } catch (err) {
        yield call(() => toast.error('Failed to cancel waypoint :('));
        log('fail to cancel waypoint', err);
    }
}

function* onWayPointUpdateCompleted(action: WayPointUpdateCompletedAction) {
    const hub: AdminHub = yield selectAdminHub();

    log('[PREF]', 'getTasksByIds', action.payload.orderTaskIds.length);
    const tasks: OrderTaskRef[] = yield call(() => hub.getTasksByIds(action.payload.orderTaskIds));
    log('[PREF]', 'getTasksByIds end', tasks?.length ?? 'NO TASKS on waypoint updatecompleted');

    if (tasks) {
        const { taskMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
            taskMgnt: s.taskMgnt,
        }));

        const lookup = _.keyBy(tasks, (t) => t.id);
        const newTasks = taskMgnt.taskRefs.map((t) => {
            const updated = lookup[t.id];
            if (updated) {
                delete lookup[t.id];
                return mapTaskItem(updated);
            }

            return t;
        });

        for (const newTaskId of Object.keys(lookup)) {
            newTasks.push(mapTaskItem(lookup[newTaskId]));
        }

        yield updateTaskRefs(newTasks);
    } else {
        // if hub.getTasksByIds failed, tasks will be undefined, retry
        yield call(() => toast.warn('Could not reload tasks, retrying...'));
        log('[ERR]', 'getTasksByIds: Could not reload tasks, retrying...');
        yield delay(2000);
        yield put<WayPointUpdateCompletedAction>({
            type: WayPointUpdateCompletedActionType,
            payload: {
                orderTaskIds: action.payload.orderTaskIds,
            },
        });
    }
}

function* listenWayPointUpdate() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (notification: OrderChangedNotification) => {
                emitter(notification);
            };

            hub.connection.on('OnOrderTaskUpdated', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnOrderTaskUpdated', callback);
            };
        });

        while (true) {
            const notification: OrderChangedNotification = yield take(channel);

            //if (notification.ids) {
            //    for (const updatedOrderTaskId of notification.ids) {
            //        wayPointPendingReload.push(updatedOrderTaskId);
            //    }
            //}

            yield put<WayPointUpdateCompletedAction>({
                type: WayPointUpdateCompletedActionType,
                payload: {
                    orderTaskIds: notification.ids,
                },
            });
        }
    }
}

function* onServerConnected() {
    yield reloadTasks();
}

export const sagas = [
    takeEvery(BeginAutoRouteActionType, onBeginAutoRoute),
    takeEvery(CompleteTaskActionType, onCompleteTask),
    takeEvery(ChangeAddressActionType, onChangeAddress),
    takeEvery(CreateWayPointActionType, onCreateWayPoint),
    takeEvery(WayPointUpdateCompletedActionType, onWayPointUpdateCompleted),
    takeEvery(CancelWayPointActionType, onCancelWayPoint),
    takeEvery(UnassignDriverActionType, onUnassignDriver),
    takeEvery(ServerState.ServerConnectedActionType, listenWayPointUpdate),
    takeEvery(ToggleDriverTaskListActionType, onToggleDriverTaskList),
    takeEvery(DriverState.DriverUpdateActionType, onDriverListUpdate),
    takeEvery(ServerState.ServerConnectedActionType, onServerConnected),
    takeEvery(OrderMgnt.OrderReloadCompletedActionType, onOrderReloadCompleted),
    takeEvery(NaviState.ChangeServiceCityActionType, updateTaskList),
    debounce(100, ServerState.ReconnectedActionType, reloadTasks),
];
