import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { ReceiveHistoryAction, ReceiveHistoryActionType } from './Actions/ReceiveHistoryAction';
import { State, defaultState } from './State';

import { FetchMoreHistoryAction } from './Actions/FetchMoreHistoryAction';
import { Reducer } from 'redux';

type KnownAction = FetchHistoryAction | FetchMoreHistoryAction | ReceiveHistoryAction;

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case FetchHistoryActionType: {
            return {
                ...state,
                orders: null,
                ...action.payload,
            };
        }
        case ReceiveHistoryActionType: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
