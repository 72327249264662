import { MapAssignmentFilterValues } from '../../models/MapAssignmentFilter';
import { MyMultiSelectItem } from 'models/MyMultiSelectItem';
import { OrderTaskRef } from 'models/OrderTaskRef';

export interface State {
    filter: MapAssignmentFilterValues | null;
    tasks: OrderTaskRef[];
    selectedTasks: OrderTaskRef[];
    postals: MyMultiSelectItem[];
    domainServiceLevels: MyMultiSelectItem[];
    polygon: google.maps.Polygon | null;
    isLoading: boolean;
}

export const defaultState: State = {
    filter: null,
    tasks: [],
    selectedTasks: [],
    postals: [],
    domainServiceLevels: [],
    polygon: null,
    isLoading: false,
};
