import * as ServerState from '../ServerState';

import { HideModalAction, HideModalActionType } from './HideModalActionType';
import { HoldOrderRequestAction, HoldOrderRequestActionType } from './HoldOrderRequestActionType';
import { UnholdOrderRequestAction, UnholdOrderRequestActionType } from './UnholdOrderRequestActionType';
import { UpdateStateAction, UpdateStateActionType } from '../ChatState';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ApplicationState } from '../ApplicationState';
import { TransportHub } from '../../models/TransportHub';

function* onHoldOrderRequest(action: HoldOrderRequestAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const succeed: boolean = yield call(() => adminHub.holdOrder(action.payload.data));
        action.payload.callback(succeed);
        if (succeed) {
            const act: HideModalAction = { type: HideModalActionType };
            yield put(act);
        }
    }
}

function* fetchTransportHubs() {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const tHubs: TransportHub[] = yield call(() => adminHub.getTransportHubs());
        if (tHubs) {
            const updateAct: UpdateStateAction = {
                type: UpdateStateActionType,
                payload: {
                    //transportHubs: tHubs,
                },
            };
        }
    }
}

function* onUnholdOrderRequest(action: UnholdOrderRequestAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const succeed: boolean = yield call(() =>
            adminHub.unholdOrder(action.payload.orderId, action.payload.removeDriver),
        );
        action.payload.callback(succeed);
        if (succeed) {
            const act: HideModalAction = { type: HideModalActionType };
            yield put(act);
        }
    }
}

export const sagas = [
    takeEvery(HoldOrderRequestActionType, onHoldOrderRequest),
    takeEvery(UnholdOrderRequestActionType, onUnholdOrderRequest),
    takeEvery(ServerState.ServerConnectedActionType, fetchTransportHubs),
];
