import * as React from 'react';

import { ApplicationState } from 'store/ApplicationState';
import { ChatMessage } from 'models/ChatMessage';
import { Driver } from 'models/Driver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { faSms } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const BatchMessage: React.FC = () => {
    const { drivers, adminHub, serviceCitiesFilter } = useSelector((s: ApplicationState) => ({
        drivers: s.driver.activeDrivers,
        adminHub: s.server.hubs?.admin,
        serviceCitiesFilter: s.navi.serviceCitiesFilter,
    }));

    const [selectedDrivers, setSelectedDrivers] = React.useState<
        Array<
            | Driver
            | {
                  id: string;
                  friendlyName: string;
                  name: string;
                  userId?: string;
              }
        >
    >([]);
    const [messageText, setMessageText] = React.useState('');
    const [isSending, setIsSending] = React.useState(false);

    const filteredDrivers = React.useMemo(() => {
        return drivers
            ? drivers.filter((d) => (serviceCitiesFilter.length ? serviceCitiesFilter.includes(d.serviceCity) : d))
            : [];
    }, [drivers, serviceCitiesFilter]);

    const options = React.useMemo(() => {
        return [
            {
                id: '*',
                friendlyName: '',
                name: 'Select All',
            },
            ...filteredDrivers,
        ];
    }, [filteredDrivers]);

    if (!filteredDrivers || !adminHub) {
        return null;
    }

    const handleSendMessage = (evt: React.SyntheticEvent) => {
        evt.preventDefault();

        if (messageText.length == 0) {
            toast.warn('Empty message..');
            return;
        }

        setIsSending(true);
        const messages = selectedDrivers.map((d) => {
            const msg: Partial<ChatMessage> = {
                driverId: d.id,
                recipientName: d.friendlyName || d.name,
                recipientUserId: d.userId,
                message: messageText,
            };

            return msg;
        });

        adminHub
            .sendChatMessages(messages)
            .then(
                () => {
                    setSelectedDrivers([]);
                    setMessageText('');
                },
                () => {
                    toast.error('Fail to send message');
                },
            )
            .finally(() => {
                setIsSending(false);
            });
    };

    const handleSendSms = (evt: React.SyntheticEvent) => {
        evt.preventDefault();

        setIsSending(true);
        const messages = selectedDrivers.map((d) => {
            const msg: Partial<ChatMessage> = {
                driverId: d.id,
                recipientName: d.friendlyName || d.name,
                recipientUserId: d.userId,
                message: messageText,
            };

            return msg;
        });

        adminHub
            .sendChatMessages(messages)
            .then(
                () => {
                    setSelectedDrivers([]);
                    setMessageText('');
                },
                () => {
                    toast.error('Fail to send sms');
                },
            )
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <div className="m-1">
            <div className="mb-1">
                <Select
                    placeholder={'Send message to multiple drivers'}
                    value={selectedDrivers}
                    isMulti
                    options={options}
                    getOptionValue={(d) => d.id}
                    getOptionLabel={(d) => d.friendlyName || d.name}
                    isDisabled={isSending}
                    closeMenuOnSelect={false}
                    onChange={(val) => {
                        const selected = (val as Driver[]) || [];
                        if (selected.filter((v) => v.id === '*').length > 0) {
                            setSelectedDrivers(filteredDrivers);
                        } else {
                            setSelectedDrivers(selected);
                        }
                    }}
                />
            </div>
            {selectedDrivers.length > 0 && (
                <form className="input-group send-message-container">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Message..."
                        value={messageText}
                        disabled={isSending}
                        onChange={(evt) => setMessageText(evt.target.value)}
                    />
                    {false && (
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary ml-2"
                                type="submit"
                                onClick={handleSendSms}
                                disabled={isSending}
                            >
                                <FontAwesomeIcon fixedWidth={true} icon={faSms as IconProp} />
                            </button>
                        </div>
                    )}
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary ml-2"
                            type="submit"
                            onClick={handleSendMessage}
                            disabled={isSending}
                        >
                            <FontAwesomeIcon fixedWidth={true} icon={faPaperPlane as IconProp} />
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default BatchMessage;
