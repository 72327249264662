import * as ServerState from '../ServerState';

import { DisableMerchantAction, DisableMerchantActionType } from './Actions/DisableMerchantAction';
import { FetchMerchantAction, FetchMerchantActionType } from './Actions/FetchMerchantAction';
import { FetchTimeZoneOffsetAction, FetchTimeZoneOffsetActionType } from './Actions/FetchTimeZoneOffsetAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { AdminHub } from 'models';
import { ApplicationState } from '../ApplicationState';
import { Merchant } from '../../models/Merchant';
import { State } from './State';
import _ from 'lodash';
import { log } from 'utils';
import { selectAdminHub } from 'store/selectAdminHub';

const fetchingMerchantLookup: { [id: string]: boolean } = {};

function* onFetchMerchant(action: FetchMerchantAction) {
    if (!fetchingMerchantLookup[action.payload.id]) {
        fetchingMerchantLookup[action.payload.id] = true;

        const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);
        const hubs = serverState.hubs;
        if (hubs && hubs.admin) {
            log('[PREF]', 'getMerchant', action.payload.id);
            const merchant: Merchant = yield call(() => hubs.admin.getMerchant(action.payload.id));
            log('[PREF]', 'getDrivers END');
            const { merchants, allMerchants }: State = yield select((s: ApplicationState) => s.merchantRepo);
            const newMerchants = { ...merchants };
            newMerchants[merchant.id] = merchant;

            let isNewMerchant = true;
            const newAllMerchants = allMerchants.map((m) => {
                if (m.id === merchant.id) {
                    isNewMerchant = false;
                    return merchant;
                }

                return m;
            });

            if (isNewMerchant) {
                newAllMerchants.push(merchant);
            }

            yield put({
                type: UpdateStateActionType,
                payload: {
                    merchants: newMerchants,
                    allMerchants: newAllMerchants,
                    activeMerchants: newAllMerchants.filter((m) => !m.isDisabled),
                },
            });
        }

        fetchingMerchantLookup[action.payload.id] = false;
    }
}

const fetchingOffsetLookup: { [id: string]: boolean } = {};

function* onFetchTimeZoneOffset(action: FetchTimeZoneOffsetAction) {
    if (!fetchingOffsetLookup[action.payload.id]) {
        fetchingOffsetLookup[action.payload.id] = true;

        const serverState: ServerState.State = yield select((s: ApplicationState) => s.server);
        const hubs = serverState.hubs;
        if (hubs && hubs.admin) {
            log('[PREF]', 'getMerchantTimeZoneOffset', action.payload.id);
            const offset: number = yield call(() => hubs.admin.getMerchantTimeZoneOffset(action.payload.id));
            log('[PREF]', 'getMerchantTimeZoneOffset END');
            const { timezoneOffsets }: State = yield select((s: ApplicationState) => s.merchantRepo);
            const newOffsets = { ...timezoneOffsets };
            newOffsets[action.payload.id] = offset;

            const act: UpdateStateAction = { type: UpdateStateActionType, payload: { timezoneOffsets: newOffsets } };
            yield put(act);
        }

        fetchingOffsetLookup[action.payload.id] = false;
    }
}

function* onDisableMerchant(action: DisableMerchantAction) {
    const hub: AdminHub = yield selectAdminHub();
    log('[PREF]', 'disableMerchant');
    yield call(() => hub.disableMerchant(action.payload.id, action.payload.disable));
    log('[PREF]', 'disableMerchant END');

    yield put<FetchMerchantAction>({ type: FetchMerchantActionType, payload: { id: action.payload.id } });
}

function* onServerConnected() {
    const hub: AdminHub = yield selectAdminHub();
    log('[PREF]', 'getMerchants');
    const allMerchants: Merchant[] = yield call(() => hub.getMerchants());
    log('[PREF]', 'getMerchants END', allMerchants.length);

    const merchants = _.keyBy(allMerchants, (m) => m.id);
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: { allMerchants, merchants, activeMerchants: allMerchants.filter((m) => !m.isDisabled) },
    });
}

export const sagas = [
    takeEvery(ServerState.ServerConnectedActionType, onServerConnected),

    takeEvery(FetchMerchantActionType, onFetchMerchant),
    takeEvery(FetchTimeZoneOffsetActionType, onFetchTimeZoneOffset),
    takeEvery(DisableMerchantActionType, onDisableMerchant),
];
