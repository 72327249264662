import moment from 'moment';

const localOffset = -new Date().getTimezoneOffset();

export function formatTime(
    dateTime?: string | Date | null,
    offset: number = localOffset,
    withTimeZone = false,
): string | undefined {
    if (dateTime) {
        const mnt = moment(dateTime);
        const str = mnt.utcOffset(offset).format(withTimeZone ? 'LT ZZ' : 'LT');
        return str;
    }
}
