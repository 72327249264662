import * as ServerState from '../ServerState';

import { ActivateOrderAction, ActivateOrderActionType } from './Actions/ActivateOrderAction';
import { OrderReloadCompletedAction, OrderReloadCompletedActionType } from './Actions/OrderReloadCompletedAction';
import { ReceiveOrderAction, ReceiveOrderActionType } from './Actions/ReceiveOrderAction';
import { ReloadUpdatedOrderAction, ReloadUpdatedOrderActionType } from './Actions/ReloadUpdatedOrderAction';
import { SwitchCategoryAction, SwitchCategoryActionType } from './Actions/SwitchCategoryAction';
import { SwitchDriverAction, SwitchDriverActionType } from './Actions/SwitchDriverAction';
import { SwitchZoneAction, SwitchZoneActionType } from './Actions/SwitchZoneAction';
import {
    SwitchCategoryTableViewAction,
    SwitchCategoryTableViewActionType,
} from './Actions/SwitchCategoryTableViewAction';
import { SwitchDriverTableViewAction, SwitchDriverTableViewActionType } from './Actions/SwitchDriverTableViewAction';
import { SwitchZoneTableViewAction, SwitchZoneTableViewActionType } from './Actions/SwitchZoneTableViewAction';
import { UpdateOrderDateFilterAction, UpdateOrderDateFilterActionType } from './Actions/UpdateOrderDateFilterAction';
import { UpdateOrderFilterAction, UpdateOrderFilterActionType } from './Actions/UpdateOrderFilterAction';
import {
    UpdateOrderFilterTableViewAction,
    UpdateOrderFilterTableViewActionType,
} from './Actions/UpdateOrderFilterTableViewAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { VisitTaskAction, VisitTaskActionType } from './Actions/VisitTaskAction';
import { call, debounce, put, race, select, take, takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { formatAddress, log } from 'utils';

import { AdminHub } from 'models';
import { ApplicationState } from '../ApplicationState';
import LRUCache from 'lru-cache';
import { Order } from 'models/Order';
import { OrderChangedNotification } from 'models/OrderChangedNotification';
import { OrderRef } from 'models/OrderRef';
import { OrderRefItem } from 'models/OrderRefItem';
import { State } from './State';
import { UndeliverableCode } from 'models/UndeliverableCode';
import _, { size } from 'lodash';
import { eventChannel } from 'redux-saga';
import moment from 'moment';
import { selectAdminHub } from '../selectAdminHub';
import { toast } from 'react-toastify';

const orderCache = new LRUCache<string, Order>({
    max: 100,
    maxSize: 1000000,
    sizeCalculation: (n: Order) => JSON.stringify(n).length,
});
const orderPendingReload: string[] = [];

export const mapOrderItem = (o: OrderRef) => ({
    ...o,
    deliveryAddressText: formatAddress(o.deliveryAddress),
    desiredTimeMnt: o.desiredTime ? moment(o.desiredTime) : moment(),
});

function* onVisitTask(action: VisitTaskAction) {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.admin;
    if (hub) {
        yield call(() => hub.markTaskVisited(action.payload.taskId));
    }
}

function* updateOrderRefs(orders?: OrderRefItem[]) {
    const { orderMgnt: state, server: serverState }: ApplicationState = yield select((s: ApplicationState) => ({
        orderMgnt: s.orderMgnt,
        server: s.server,
    }));

    if (!orders) {
        orders = state.orderRefs;
    }

    const filtered = state.filterByDate || state.filterByKeywords;
    const preOrderHrs = (serverState.sysSettings && serverState.sysSettings.preorderHours) || 15;

    let currentItems: OrderRefItem[] = orders;

    // backend filter on dates, search, search mode
    if (filtered) {
        currentItems = state.filterOrders;
    }

    if (state.filterByKeywords == '') {
        // filter on driver
        if (state.currentDriver) {
            const driver = state.currentDriver;
            currentItems = currentItems.filter((o) => {
                return o.driverId === driver.id;
            });
        }

        // filter on zone
        if (state.currentZone) {
            const zone = state.currentZone;
            currentItems = currentItems.filter((o) => {
                return o.zoneId === zone.id;
            });
        }

        // filter on category (Active/Preorder)
        if (state.currentCategory === 'Active' || state.currentCategory === 'Preorder') {
            const cutoff = moment().add(preOrderHrs, 'h');
            currentItems = currentItems.filter((o) => {
                return (
                    !o.paused &&
                    (o.status === 'Acknowledged' ||
                        o.status === 'Delivery' ||
                        o.status === 'Order' ||
                        o.status === 'Preparation' ||
                        o.status === 'Ready' ||
                        o.status === 'Transit' ||
                        o.status === 'Waiting')
                );
            });

            currentItems =
                state.currentCategory === 'Active'
                    ? currentItems.filter((o) => o.desiredTimeMnt.isSameOrBefore(cutoff))
                    : currentItems.filter((o) => o.desiredTimeMnt.isAfter(cutoff));
        }

        // filter on category (Cancelled)
        if (state.currentCategory === 'Canceled') {
            currentItems = currentItems.filter((o) => {
                return o.status === 'Cancelled';
            });
        }

        // filter on category (On Hold)
        if (state.currentCategory === 'Onhold') {
            currentItems = currentItems.filter((o) => {
                return o.paused;
            });
        }

        // filter on category (Completed)
        if (state.currentCategory === 'Completed') {
            currentItems = currentItems.filter((o) => {
                return o.status === 'Completed';
            });
        }
    }

    // re-apply users sort selection
    if (state.columnNameToSort != '') {
        currentItems = _.orderBy(
            currentItems,
            state.columnNameToSort,
            state.direction ? 'asc' : 'desc',
        ) as OrderRefItem[];
    } else {
        currentItems
            .sort((a, b) => b.seqId - a.seqId)
            .sort((a, b) => Number(b.flagToBeCancelled) - Number(a.flagToBeCancelled))
            .sort((a, b) => Number(b.flagRequiresAttention) - Number(a.flagRequiresAttention));
    }

    const updateAct: UpdateStateAction = {
        type: UpdateStateActionType,
        payload: {
            currentOrderRefs: currentItems,
            isLoading: false,
        },
    };

    // order list updated
    if (state.orderRefs !== orders) {
        updateAct.payload.orderRefs = orders;
        const orderDict: { [id: string]: OrderRefItem } = {};
        const allTags: { [tag: string]: true } = {};
        for (const order of orders) {
            orderDict[order.id] = order;
            if (order.tag) {
                allTags[order.tag] = true;
            }
        }

        updateAct.payload.allTags = Object.keys(allTags);
        updateAct.payload.orderDict = orderDict;
    }

    yield put(updateAct);
}

function* onUpdateOrderFilter(action: UpdateOrderFilterAction) {
    const { orderMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
        orderMgnt: s.orderMgnt,
    }));

    const filterByDate = orderMgnt.filterByDate || '';
    const filterByDateMax = orderMgnt.filterByDateMax || '';
    const filterByKeywords = orderMgnt.filterByKeywords || '';
    const filterMode = orderMgnt.filterMode || '';
    if (filterByDate || filterByKeywords) {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'hub.searchOrdersByFilter', filterByDate, filterByKeywords, filterMode);
        const filteredOrders: OrderRef[] = yield call(() =>
            hub.searchOrdersByFilter(filterByKeywords, filterMode, filterByDate, filterByDateMax),
        );

        log('[PREF]', 'hub.searchOrdersByFilter', filteredOrders.length);

        const items: OrderRefItem[] = filteredOrders.map(mapOrderItem);

        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                filterOrders: items,
            },
        });
    } else {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                filterOrders: [],
            },
        });
    }

    yield updateOrderRefs();
}

function* onUpdateOrderFilterTableView(action: UpdateOrderFilterTableViewAction) {
    const { orderMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
        orderMgnt: s.orderMgnt,
    }));

    const filterByDate = orderMgnt.filterByDate || '';
    const filterByDateMax = orderMgnt.filterByDateMax || '';
    const filterByKeywords = orderMgnt.filterByKeywords || '';
    const filterMode = orderMgnt.filterMode || '';
    if (filterByDate || filterByKeywords) {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'hub.searchOrdersByFilter', filterByDate, filterByKeywords, filterMode);
        const filteredOrders: OrderRef[] = yield call(() =>
            hub.searchOrdersByFilter(filterByKeywords, filterMode, filterByDate, filterByDateMax),
        );

        log('[PREF]', 'hub.searchOrdersByFilter', filteredOrders.length);

        const items: OrderRefItem[] = filteredOrders.map(mapOrderItem);

        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                filterOrders: items,
            },
        });
    } else {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                filterOrders: [],
            },
        });
    }

    yield updateOrderRefs();
}

function* fetchUndeliverableCodes() {
    const hub: AdminHub = yield selectAdminHub();
    const codes: UndeliverableCode[] = yield call(() => hub.getUndeliverableCodes());
    const updateAct: UpdateStateAction = {
        type: UpdateStateActionType,
        payload: {
            undeliverableCodes: codes,
        },
    };

    yield put(updateAct);
}

function* reloadOrders() {
    try {
        const state: ApplicationState = yield select();
        const hub = state.server.hubs && state.server.hubs.adminOrderHub;
        if (hub) {
            log('[PREF]', 'getAllOrders');
            const orders: OrderRef[] = yield call(() => hub.getAllOrders());
            log('[PREF]', 'getAllOrders end', orders.length);

            const items: OrderRefItem[] = orders.map((o) => ({
                ...o,
                deliveryAddressText: formatAddress(o.deliveryAddress),
                desiredTimeMnt: o.desiredTime ? moment(o.desiredTime) : moment(),
            }));

            yield updateOrderRefs(items);
        }
    } catch (err) {
        log('fail to fetch orders', err);
    }
}

function* onServerConnected() {
    yield reloadOrders();
}

function* onSwitchCategory(action: SwitchCategoryAction) {
    const state: State = yield select((s: ApplicationState) => s.orderMgnt);
    if (action.payload.orderCategory !== state.currentCategory) {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                currentCategory: action.payload.orderCategory,
            },
        });

        yield updateOrderRefs();
    }
}

function* onSwitchZone(action: SwitchZoneAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            currentZone: action.payload.zone,
        },
    });

    yield updateOrderRefs();
}

function* onSwitchDriver(action: SwitchDriverAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            currentDriver: action.payload.driver,
        },
    });

    yield updateOrderRefs();
}

function* onSwitchCategoryTableView(action: SwitchCategoryAction) {
    const state: State = yield select((s: ApplicationState) => s.orderMgnt);
    if (action.payload.orderCategory !== state.currentCategory) {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                currentCategory: action.payload.orderCategory,
            },
        });

        yield updateOrderRefs();
    }
}

function* onSwitchZoneTableView(action: SwitchZoneAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            currentZone: action.payload.zone,
        },
    });

    yield updateOrderRefs();
}

function* onSwitchDriverTableView(action: SwitchDriverAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            currentDriver: action.payload.driver,
        },
    });

    yield updateOrderRefs();
}

let orderLoadingInProgress = '';
function* loadActiveOrder(orderId: string) {
    orderLoadingInProgress = orderId;

    try {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'getOrder');
        const order: Order = yield call(() => hub.getOrder(orderId));
        log('[PREF]', 'getOrder end');

        orderCache.set(order.id, order);

        if (order.id === orderLoadingInProgress) {
            yield put<UpdateStateAction>({
                type: UpdateStateActionType,
                payload: {
                    activeOrder: order,
                },
            });
        }
    } catch (err) {
        console.error(err);
    }

    orderLoadingInProgress = '';
}

function* onActivateOrder(action: ActivateOrderAction) {
    const { orderMgnt, server: serverState }: ApplicationState = yield select((s: ApplicationState) => ({
        orderMgnt: s.orderMgnt,
        server: s.server,
    }));

    if (!orderMgnt.activeOrder || orderMgnt.activeOrder.id !== action.payload.orderId) {
        const loadSingleOrderFromCache =
            (serverState.sysSettings && serverState.sysSettings.serviceOrderLoadFromCache) || false;

        const orderFromCache = orderCache.get(action.payload.orderId);
        if (orderFromCache && loadSingleOrderFromCache) {
            log('[PREF]', 'Load order from cache');
            yield put<UpdateStateAction>({
                type: UpdateStateActionType,
                payload: {
                    activeOrder: orderFromCache,
                },
            });
        } else if (orderLoadingInProgress !== action.payload.orderId) {
            log('[PREF]', 'Fetch order from server');
            yield loadActiveOrder(action.payload.orderId);
        }
    }
}

function* onReloadUpdatedOrder() {
    const ids = _.uniq(orderPendingReload);
    orderPendingReload.length = 0;

    if (ids.length) {
        const hub: AdminHub = yield selectAdminHub();
        log('[PREF]', 'getOrders', ids.length);
        const updatedOrders: OrderRef[] = yield call(() => hub.getOrders(ids));
        log('[PREF]', 'getOrders end', updatedOrders?.length ?? 'NO ORDERS');

        if (updatedOrders) {
            const lookup: { [id: string]: OrderRefItem } = {};
            for (const order of updatedOrders) {
                lookup[order.id] = mapOrderItem(order);
                orderCache.delete(order.id);
            }

            const { orderMgnt }: ApplicationState = yield select((s: ApplicationState) => ({
                orderMgnt: s.orderMgnt,
            }));

            // if in filter mode, update filtered orders
            if (orderMgnt.filterOrders.length > 0) {
                let listUpdated = false;
                const newFilterOrders = orderMgnt.filterOrders.map((o) => {
                    const updated = lookup[o.id];
                    if (updated) {
                        listUpdated = true;
                        return updated;
                    }

                    return o;
                });

                if (listUpdated) {
                    yield put<UpdateStateAction>({
                        type: UpdateStateActionType,
                        payload: {
                            filterOrders: newFilterOrders,
                        },
                    });
                }
            }

            // the current active order is updated, reload it
            if (orderMgnt.activeOrder && lookup[orderMgnt.activeOrder.id]) {
                yield loadActiveOrder(orderMgnt.activeOrder.id);
            }

            var newOrders = orderMgnt.orderRefs.map((o) => {
                const updated = lookup[o.id];
                if (updated) {
                    delete lookup[o.id];
                    return updated;
                }

                return o;
            });

            for (const newOrderId of Object.keys(lookup)) {
                newOrders.push(lookup[newOrderId]);
            }

            if (orderMgnt.columnNameToSort != '') {
                newOrders = _.orderBy(
                    newOrders,
                    orderMgnt.columnNameToSort,
                    orderMgnt.direction ? 'asc' : 'desc',
                ) as OrderRefItem[];
            }

            yield updateOrderRefs(newOrders);

            // notify order reloaded
            yield put<OrderReloadCompletedAction>({
                type: OrderReloadCompletedActionType,
                payload: {
                    orderIds: ids,
                },
            });
        } else {
            log('Did not reload orders, nothing to reload.');
        }
    }
}

function* listenOrderUpdate() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (notification: OrderChangedNotification) => {
                emitter(notification);
            };

            hub.connection.on('OnOrderUpdated', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnOrderUpdated', callback);
            };
        });

        while (true) {
            const notification: OrderChangedNotification = yield take(channel);

            if (notification.ids) {
                for (const updatedOrderId of notification.ids) {
                    orderPendingReload.push(updatedOrderId);
                }
            }

            yield put<ReloadUpdatedOrderAction>({ type: ReloadUpdatedOrderActionType });
        }
    }
}

function* listenManifestImported() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (notification: OrderChangedNotification) => {
                emitter(notification);
            };

            hub.connection.on('OnManifestImported', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnManifestImported', callback);
            };
        });

        while (true) {
            const notification: OrderChangedNotification = yield take(channel);
            log('manifest imported');

            yield reloadOrders();

            // notify order reloaded
            yield put<OrderReloadCompletedAction>({
                type: OrderReloadCompletedActionType,
                payload: {
                    orderIds: notification.ids,
                },
            });

            toast.success('Manifest imported!');
        }
    }
}

function* listenUndeliverableCodesUpdated() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = () => {
                emitter('');
            };

            hub.connection.on('OnUndeliverableCodeSaved', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnUndeliverableCodeSaved', callback);
            };
        });

        while (true) {
            yield take(channel);
            log('UndeliverableCodes updated');

            yield fetchUndeliverableCodes();

            toast.success('UndeliverableCodes updated!');
        }
    }
}

export const sagas = [
    takeEvery(ServerState.ServerConnectedActionType, onServerConnected),
    takeEvery(SwitchCategoryActionType, onSwitchCategory),
    takeEvery(SwitchZoneActionType, onSwitchZone),
    takeEvery(SwitchDriverActionType, onSwitchDriver),
    takeEvery(SwitchCategoryTableViewActionType, onSwitchCategoryTableView),
    takeEvery(SwitchZoneTableViewActionType, onSwitchZoneTableView),
    takeEvery(SwitchDriverTableViewActionType, onSwitchDriverTableView),
    takeEvery(ServerState.ServerConnectedActionType, listenOrderUpdate),
    takeEvery(ServerState.ServerConnectedActionType, listenManifestImported),
    takeEvery(ServerState.ServerConnectedActionType, listenUndeliverableCodesUpdated),
    takeEvery(ServerState.ServerConnectedActionType, fetchUndeliverableCodes),
    takeEvery(VisitTaskActionType, onVisitTask),
    takeEvery(ActivateOrderActionType, onActivateOrder),
    debounce(200, UpdateOrderFilterActionType, onUpdateOrderFilter),
    debounce(200, UpdateOrderFilterTableViewActionType, onUpdateOrderFilterTableView),
    race([
        throttle(3000, ReloadUpdatedOrderActionType, onReloadUpdatedOrder),
        debounce(1000, ReloadUpdatedOrderActionType, onReloadUpdatedOrder),
    ]),

    //throttle(1000, ReloadUpdatedOrderActionType, onReloadUpdatedOrder),
    debounce(200, ServerState.ReconnectedActionType, reloadOrders),
];
