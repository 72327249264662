import { HideModalAction, HideModalActionType } from './HideModalActionType';
import { HoldOrderRequestAction, HoldOrderRequestActionType } from './HoldOrderRequestActionType';
import { ShowModalAction, ShowModalActionType } from './ShowModalActionType';
import { UnholdOrderRequestAction, UnholdOrderRequestActionType } from './UnholdOrderRequestActionType';
import { DateChangedAction, DateChangedActionType } from './DateChangedActionType';
import { TimeChangedAction, TimeChangedActionType } from './TimeChangedActionType';

import { HoldOrderRequest } from 'models/HoldOrderRequest';
import { Order } from 'models/Order';

export const actionCreators = {
    showHoldOrderModal: (order: Order, deliveryDate: string, deliveryTime: string) => {
        const act: ShowModalAction = { type: ShowModalActionType, payload: { order, deliveryDate, deliveryTime } };
        return act;
    },
    hideHoldOrderModal: () => {
        const act: HideModalAction = { type: HideModalActionType };
        return act;
    },
    holdOrder: (request: HoldOrderRequest, callback: (succeed: boolean) => void) => {
        const act: HoldOrderRequestAction = { type: HoldOrderRequestActionType, payload: { data: request, callback } };
        return act;
    },
    setDateChanged: (dateChanged: string) => {
        const act: DateChangedAction = {
            type: DateChangedActionType,
            payload: { dateChanged },
        };
        return act;
    },
    setTimeChanged: (timeChanged: string) => {
        const act: TimeChangedAction = {
            type: TimeChangedActionType,
            payload: { timeChanged },
        };
        return act;
    },
    unholdOrder: (orderId: string, removeDriver: boolean, callback: (succeed: boolean) => void) => {
        const act: UnholdOrderRequestAction = {
            type: UnholdOrderRequestActionType,
            payload: { orderId, removeDriver, callback },
        };
        return act;
    },
};
