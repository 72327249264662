import { DriverWorkLogHistoryItem } from 'models/DriverWorkLogHistoryItem';

export const ReceiveHistoryActionType = 'DRIVER_WORKLOG_HIST/RECEIVE_HIST';

export interface ReceiveHistoryAction {
    type: typeof ReceiveHistoryActionType;
    payload: {
        workLogs: DriverWorkLogHistoryItem[];
        hasMore: boolean;
    };
}
