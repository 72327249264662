import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { FetchMoreHistoryAction, FetchMoreHistoryActionType } from './Actions/FetchMoreHistoryAction';
import { FetchWorkLogAction, FetchWorkLogActionType } from './Actions/FetchWorkLogAction';
import { ProcessWorkLogStatsAction, ProcessWorkLogStatsActionType } from './Actions/ProcessWorkLogStatsAction';
import { ReceiveHistoryAction, ReceiveHistoryActionType } from './Actions/ReceiveHistoryAction';
import { ReceiveWorkLogAction, ReceiveWorkLogActionType } from '../DriverWorkLogHistory/Actions/ReceiveWorkLogAction';
import { ReceiveWorkLogsAction, ReceiveWorkLogsActionType } from '../DriverEditorState';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ApplicationState } from '../ApplicationState';
import { DriverWorkLogHistoryItem } from 'models/DriverWorkLogHistoryItem';
import { WorkLog } from '../../models/WorkLog';
import { log } from 'utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DisableMerchantActionType } from '../MerchantRepo/Actions/DisableMerchantAction';
import { DiscardMileageAction, DiscardMileageActionType } from './Actions/DiscardMileageAction';

function* onFetchWorkLogs(action: FetchHistoryAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const workLogs: DriverWorkLogHistoryItem[] = yield call(() =>
            adminHub.getDriverWorkLogs(action.payload.driverId),
        );

        const hasMore = workLogs.length > 30;
        if (hasMore) {
            workLogs.length = 30;
        }

        const act: ReceiveHistoryAction = {
            type: ReceiveHistoryActionType,
            payload: {
                workLogs,
                hasMore,
            },
        };

        yield put(act);
    }
}

function* onProcessWorkLogStats(action: ProcessWorkLogStatsAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    log('onProcessWorkLogStats', action.payload.workLogId);
    toast.info('WorkLog regenerating...');
    if (adminHub) {
        var result: string = yield call(() => adminHub.processWorkLogStats(action.payload.workLogId));
        toast.info('Result:' + result);

        const act: FetchWorkLogAction = {
            type: FetchWorkLogActionType,
            payload: {
                workLogId: action.payload.workLogId,
            },
        };
        yield put(act);
    }
}

function* onFetchWorkLog(action: FetchWorkLogAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const workLog: WorkLog = yield call(() => adminHub.getWorkLog(action.payload.workLogId));

        const act: ReceiveWorkLogAction = {
            type: ReceiveWorkLogActionType,
            payload: {
                workLog,
            },
        };

        yield put(act);
    }
}

function* onFetchMoreWorkLogs(action: FetchMoreHistoryAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    const workLogHist = state.driverWorkLogsHistory;
    const currentWorkLogs = workLogHist.workLogs;
    const driverId = workLogHist.driverId;
    if (adminHub && driverId && currentWorkLogs) {
        const lastWorkLog = currentWorkLogs[currentWorkLogs.length - 1];

        const ticks =
            (lastWorkLog.punchInOn && moment(lastWorkLog.punchInOn).valueOf() * 10000 + 621355968000000000) || '';

        const workLogs: DriverWorkLogHistoryItem[] = yield call(() =>
            adminHub.getDriverWorkLogs(driverId, ticks.toString()),
        );

        const hasMore = workLogs.length > 30;
        if (hasMore) {
            workLogs.length = 30;
        }

        const act: ReceiveHistoryAction = {
            type: ReceiveHistoryActionType,
            payload: {
                workLogs: [...currentWorkLogs, ...workLogs],
                hasMore,
            },
        };

        yield put(act);
    }
}

function* onDiscardMileage(action: DiscardMileageAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;

    if (adminHub) {
        const discarded: boolean = yield call(() =>
            adminHub.taskDiscardMileage(action.payload.orderTaskId, action.payload.discard),
        );
    }
}

export const sagas = [
    takeEvery(FetchHistoryActionType, onFetchWorkLogs),
    takeEvery(FetchMoreHistoryActionType, onFetchMoreWorkLogs),
    takeEvery(FetchWorkLogActionType, onFetchWorkLog),
    takeEvery(ProcessWorkLogStatsActionType, onProcessWorkLogStats),
    takeEvery(DiscardMileageActionType, onDiscardMileage),
];
