import { Driver } from '../models/Driver';
import { DriverLocation } from 'models/DriverLocation';
import { PunchInData } from 'models/PunchInData';
import { Reducer } from 'redux';
import { log } from 'utils';
import { ServerConnectedAction } from './ServerState';

export interface State {
    isLoading: boolean;
    drivers: Driver[] | null;
    activeDrivers: Driver[] | null;
    isPunchInPanelExpanded: boolean;
    driverLocations: { [id: string]: DriverLocation };
}

export const FetchDriverActionType = 'APP/FETCHE_DRIVER';
export const ReceiveDriverActionType = 'APP/RECEIVE_DRIVER';
export const DriverUpdateActionType = 'APP/DRIVER_UPDATE';
export const AcceptPunchInDriverActionType = 'DRIVER/ACCEPT_PUNCH_IN';
export const RejectPunchInDriverActionType = 'DRIVER/REJECT_PUNCH_IN';
export const PunchOutDriverActionType = 'DRIVER/PUNCH_OUT';
export const ForcePunchInActionType = 'DRIVER/FORCE_PUNCH_IN';
export const OpenPunchInPanelActionType = 'DRIVER/OPEN_PUNCH_IN_PANEL';
export const ClosePunchInPanelActionType = 'DRIVER/CLOSE_PUNCH_IN_PANEL';
export const TogglePunchInPanelActionType = 'DRIVER/TOGGLE_PUNCH_IN_PANEL';
export const LocationUpdatedActionType = 'DRIVER/LOCATION_UPDATED';
export const ResetBinNumbersActionType = 'DRIVER/RESET_BIN_NUMBERS';

export interface LocationUpdatedAction {
    type: typeof LocationUpdatedActionType;
    payload: {
        locations: { [id: string]: DriverLocation };
    };
}

export interface AcceptPunchInDriverAction {
    type: typeof AcceptPunchInDriverActionType;
    payload: {
        driverId: string;
        punchInData: PunchInData;
    };
}

export interface RejectPunchInDriverAction {
    type: typeof RejectPunchInDriverActionType;
    payload: {
        driverId: string;
        punchInData: PunchInData;
    };
}

export interface DriverUpdateAction {
    type: typeof DriverUpdateActionType;
    payload: {
        drivers: Driver[];
        activeDrivers: Driver[];
    };
}

export interface PunchOutDriverAction {
    type: typeof PunchOutDriverActionType;
    payload: {
        driverId: string;
    };
}

export interface ForcePunchInAction {
    type: typeof ForcePunchInActionType;
    payload: {
        driverId: string;
    };
}

export interface ResetBinNumbersAction {
    type: typeof ResetBinNumbersActionType;
}

export interface ReceiveDriverAction {
    type: typeof ReceiveDriverActionType;
    payload: {
        drivers: Driver[];
    };
}

export type KnownAction =
    | ServerConnectedAction
    | ReceiveDriverAction
    | DriverUpdateAction
    | PunchOutDriverAction
    | ForcePunchInAction
    | ResetBinNumbersAction
    | {
          type: typeof OpenPunchInPanelActionType;
      }
    | {
          type: typeof ClosePunchInPanelActionType;
      }
    | {
          type: typeof TogglePunchInPanelActionType;
      }
    | {
          type: typeof FetchDriverActionType;
      }
    | LocationUpdatedAction;

export const actionCreators = {
    punchIn: (driverId: string, punchInData: PunchInData) => ({
        type: AcceptPunchInDriverActionType,
        payload: {
            driverId,
            punchInData,
        },
    }),
    reject: (driverId: string, punchInData: PunchInData) => ({
        type: RejectPunchInDriverActionType,
        payload: {
            driverId,
            punchInData,
        },
    }),
    forcePunchIn: (driverId: string) => {
        log('DriverState: forcePunchIn', driverId);
        return {
            type: ForcePunchInActionType,
            payload: {
                driverId,
            },
        };
    },
    punchOut: (driverId: string) => {
        log('DriverState: punchOut', driverId);
        return {
            type: PunchOutDriverActionType,
            payload: {
                driverId,
            },
        };
    },
    togglePunchInPanel: () => ({ type: TogglePunchInPanelActionType }),
    fetchDrivers: () => {
        log('DriverState: fetchDrivers');
        return {
            type: FetchDriverActionType,
        };
    },
    resetBinNumbers: () => {
        log('DriverState: resetBinNumbers');
        return {
            type: ResetBinNumbersActionType,
        };
    },
};

export const reducer: Reducer<State, KnownAction> = (
    state = {
        isLoading: false,
        drivers: null,
        activeDrivers: null,
        isPunchInPanelExpanded: false,
        driverLocations: {},
    },
    action,
): State => {
    switch (action.type) {
        case FetchDriverActionType:
            return { ...state, isLoading: true };
        case ReceiveDriverActionType:
            return { ...state, isLoading: false };
        case DriverUpdateActionType:
            const hasPendingRequests = action.payload.drivers.filter((d) => d.phoneStatus === 'Request').length > 0;
            return {
                ...state,
                ...action.payload,
                isPunchInPanelExpanded: hasPendingRequests ? state.isPunchInPanelExpanded : false,
            };
        case OpenPunchInPanelActionType:
            return { ...state, isPunchInPanelExpanded: true };
        case ClosePunchInPanelActionType:
            return { ...state, isPunchInPanelExpanded: false };
        case TogglePunchInPanelActionType:
            return { ...state, isPunchInPanelExpanded: !state.isPunchInPanelExpanded };
        case LocationUpdatedActionType:
            const locations = { ...state.driverLocations, ...action.payload.locations };
            return {
                ...state,
                driverLocations: locations,
            };
        default:
            return state;
    }
};
