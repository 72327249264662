export const UnholdOrderRequestActionType = 'HOLD_ORDER/UNHOLD_ORDER_REQUEST';

export interface UnholdOrderRequestAction {
    type: typeof UnholdOrderRequestActionType;
    payload: {
        orderId: string;
        removeDriver: boolean;
        callback: (succeed: boolean) => void;
    };
}
