import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';

import { GeoLocation } from 'models/GeoLocation';
import { MerchantZone } from 'models/MerchantZone';

export const actionCreators = {
    copyZone: (path: GeoLocation[]) => {
        const act: UpdateStateAction = { type: UpdateStateActionType, payload: { zoneToCopy: path } };
        return act;
    },
    copyServiceZone: (zone: MerchantZone) => {
        const act: UpdateStateAction = { type: UpdateStateActionType, payload: { serviceZoneToCopy: zone } };
        return act;
    },
};
