import { SwitchTabAction, SwitchTabActionType } from './Actions/SwitchTabAction';

import { TabNames } from './State';

export const actionCreators = {
    switchTab: (tabName: TabNames) => {
        const act: SwitchTabAction = { type: SwitchTabActionType, payload: { tabName } };
        return act;
    },
};
