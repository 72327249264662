import * as ChatState from './ChatState';
import * as Deployment from './Deployment';
import * as DriverEditorState from './DriverEditorState';
import * as DriverManagementState from './DriverManagementState';
import * as DriverState from './DriverState';
import * as MainMenuState from './MainMenuState';
import * as NaviState from './NaviState';
import * as ServerState from './ServerState';
import * as TaskMgntState from './TaskMgntState';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { reducer as AccountMgntReducer } from './AccountMgnt';
import { ApplicationState } from './ApplicationState';
import { reducer as DriverOrderHistoryReducer } from './DriverOrderHistory';
import { reducer as DriverWorkLogsHistoryReducer } from './DriverWorkLogHistory';
import { History } from 'history';
import { reducer as HoldOrderModalStateReducer } from './HoldOrderModalState';
import { reducer as MapAssignmentReducer } from './MapAssignment';
import { reducer as MerchantInfoTabsReducer } from './MerchantInfoTabs';
import { reducer as MerchantOrderHistoryReducer } from './MerchantOrderHistory';
import { reducer as MerchantRepoReducer } from './MerchantRepo';
import { reducer as MerchantOrgsReducer } from './MerchantOrganization';
import { reducer as OrderMgntReducer } from './OrderMgnt';
import { reducer as ReportingReducer } from './Reporting';
import { reducer as ZoneCopyReducer } from './ZoneCopy';
import createSagaMiddleware from 'redux-saga';
import { devToolsEnhancer } from 'redux-devtools-extension';
import sagas from './sagas';
import thunk from 'redux-thunk';

export default function configureStore(history: History<{}>, initialState?: ApplicationState) {
    const reducers = {
        deployment: Deployment.reducer,
        mainMenu: MainMenuState.reducer,
        chat: ChatState.reducer,
        server: ServerState.reducer,
        driver: DriverState.reducer,
        driverManagement: DriverManagementState.reducer,
        taskMgnt: TaskMgntState.reducer,
        navi: NaviState.reducer,
        driverEditor: DriverEditorState.reducer,
        holdOrderModal: HoldOrderModalStateReducer,
        orderMgnt: OrderMgntReducer,
        merchantInfoTabs: MerchantInfoTabsReducer,
        merchantOrderHistory: MerchantOrderHistoryReducer,
        driverOrderTasksHistory: DriverOrderHistoryReducer,
        driverWorkLogsHistory: DriverWorkLogsHistoryReducer,
        merchantRepo: MerchantRepoReducer,
        merchantOrgs: MerchantOrgsReducer,
        accountMgnt: AccountMgntReducer,
        reporting: ReportingReducer,
        zoneCopy: ZoneCopyReducer,
        mapAssignment: MapAssignmentReducer,
    };

    const sagaMiddleware = createSagaMiddleware();
    const middleware = [thunk, sagaMiddleware, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers: Function[] = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    //if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    //    enhancers.push(window.devToolsExtension());
    //}

    if (isDevelopment) {
        enhancers.push(devToolsEnhancer({ trace: true }));
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers as any));

    sagaMiddleware.run(sagas);

    return store;
}
