import { State, defaultState } from './State';
import { KnownAction } from './KnownAction';
import { Reducer } from 'redux';
import { ShowModalActionType } from './ShowModalActionType';
import { HideModalActionType } from './HideModalActionType';
import { DateChangedActionType } from './DateChangedActionType';
import { TimeChangedActionType } from './TimeChangedActionType';

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case ShowModalActionType:
            return {
                ...state,
                order: action.payload.order,
                deliveryDate: action.payload.deliveryDate,
                deliveryTime: action.payload.deliveryTime,
                isModalVisiable: true,
            };
        case HideModalActionType:
            return { ...state, order: null, isModalVisiable: false };
        case DateChangedActionType:
            return { ...state, dateChanged: action.payload.dateChanged };
        case TimeChangedActionType:
            return { ...state, timeChanged: action.payload.timeChanged };
        default:
            return state;
    }
};
