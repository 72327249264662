import * as Deployment from 'store/Deployment';
import * as MainMenuState from 'store/MainMenuState';
import * as React from 'react';

import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import {
    IconDefinition,
    faArrowSquareLeft,
    faArrowSquareRight,
    faCar,
    faCog,
    faExclamationSquare,
    faMapMarkerAlt,
    faShoppingBag,
    faStore,
} from '@fortawesome/pro-light-svg-icons';
import { faBoxes, faFileChartPie, faScanner, faTasks, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faPowerOff, faSort } from '@fortawesome/pro-solid-svg-icons';

import { ApplicationState } from 'store/ApplicationState';
import { FeatureAccess } from 'models/FeatureAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';
import { default as Scrollbar } from 'react-perfect-scrollbar';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { common } from '../messages';
import { connect } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const NaviItem: React.FC<
    {
        labelId: string;
        to: string;
        icon: IconProp;
        isExpanded: boolean;
    } & { intl: IntlShape }
> = (props) => (
    <LinkContainer to={props.to} activeClassName="bg-success">
        <li className="nav-item pt-1 pb-1">
            <NavLink
                to={props.to}
                className="text-light nav-link text-nowrap"
                activeClassName="active"
                title={props.intl.formatMessage({ id: props.labelId })}
            >
                <FontAwesomeIcon fixedWidth={true} icon={props.icon as IconProp} />
                <span className={props.isExpanded ? 'ml-2' : 'sr-only'}>{props.children}</span>
            </NavLink>
        </li>
    </LinkContainer>
);

type SideNavProps = { intl: IntlShape } & {
    onLogout: () => void;
    features: FeatureAccess;
} & Deployment.DeploymentState &
    typeof Deployment.actionCreators &
    MainMenuState.State &
    typeof MainMenuState.actionCreators;

class SideNav extends React.Component<SideNavProps, {}> {
    public render() {
        const { intl, newServiceWorker, isExpanded, features } = this.props;
        const hasUpdate = !!newServiceWorker;
        return (
            <nav className="text-light bg-dark left-nav">
                <Scrollbar className="nav flex-column flex-nowrap" component="ul">
                    {(features.dispatch || features.merchantOrder) && (
                        <NaviItem
                            to="/orders"
                            labelId="menu.orders"
                            icon={faShoppingBag as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage
                                id="menu.orders"
                                defaultMessage="Orders"
                                description="Menu name for managing orders"
                            />
                        </NaviItem>
                    )}
                    {features.dispatch && (
                        <NaviItem
                            to="/dispatching"
                            labelId="menu.dispatching"
                            icon={faTasks as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.dispatching" defaultMessage="Dispatch" />
                        </NaviItem>
                    )}
                    {features.dispatch && (
                        <NaviItem
                            to="/map"
                            labelId="menu.map"
                            icon={faMapMarkerAlt as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.map" defaultMessage="Map" />
                        </NaviItem>
                    )}

                    {features.driver && (
                        <NaviItem
                            to="/drivers"
                            labelId="menu.drivers"
                            icon={faCar as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage
                                id="menu.drivers"
                                defaultMessage="Drivers"
                                description="Menu name for managing drivers"
                            />
                        </NaviItem>
                    )}
                    {features.dispatch && (
                        <NaviItem
                            to="/hub-sort"
                            labelId="menu.hubSort"
                            icon={faSort as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.hubSort" defaultMessage="Hub Sort" />
                        </NaviItem>
                    )}

                    {features.merchant && (
                        <NaviItem
                            to="/merchants"
                            labelId="menu.merchants"
                            icon={faStore as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.merchants" defaultMessage="Merchants" />
                        </NaviItem>
                    )}

                    {features.dispatch && (
                        <NaviItem
                            to="/bulk-action"
                            labelId="menu.bulkAction"
                            icon={faBoxes as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.bulkAction" defaultMessage="Bulk Actions" />
                        </NaviItem>
                    )}

                    {features.report && (
                        <NaviItem
                            to="/reporting"
                            labelId="menu.reporting"
                            icon={faFileChartPie as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.reporting" defaultMessage="Reporting" />
                        </NaviItem>
                    )}
                    {features.admin && (
                        <NaviItem
                            to="/users"
                            labelId="menu.users"
                            icon={faUser as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage id="menu.users" defaultMessage="Users" />
                        </NaviItem>
                    )}
                    {features.admin && (
                        <NaviItem
                            to="/settings"
                            labelId="menu.system"
                            icon={faCog as IconProp}
                            isExpanded={isExpanded}
                            intl={intl}
                        >
                            <FormattedMessage
                                id="menu.system"
                                defaultMessage="System"
                                description="Menu name for system settings"
                            />
                        </NaviItem>
                    )}
                    {hasUpdate && (
                        <li className="nav-item pt-1 pb-1 mt-auto">
                            <button
                                type="button"
                                className="btn btn-link text-danger nav-link"
                                title={intl.formatMessage(common.menu.updateAvailableHint)}
                                onClick={this.onUpdate}
                            >
                                <FontAwesomeIcon fixedWidth={true} icon={faExclamationSquare as IconProp} />
                                <span className={isExpanded ? 'ml-2' : 'sr-only'}>
                                    <FormattedMessage {...common.menu.updateAvailable} />
                                </span>
                            </button>
                        </li>
                    )}
                    <li className={classNames('nav-item pt-1 pb-1', { 'mt-auto': !hasUpdate })}>
                        <button
                            type="button"
                            className="btn btn-link text-light nav-link"
                            title={intl.formatMessage({ id: 'menu.menu' })}
                            onClick={this.toggleTitle}
                        >
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={(isExpanded ? faArrowSquareLeft : faArrowSquareRight) as IconProp}
                            />
                            <span className={isExpanded ? 'ml-2' : 'sr-only'}>
                                <FormattedMessage id="menu.menu" defaultMessage="Menu" />
                            </span>
                        </button>
                    </li>
                    <li className="nav-item pt-1 pb-1">
                        <button
                            type="button"
                            className="btn btn-link text-light nav-link"
                            title={intl.formatMessage({ id: 'menu.logout' })}
                            onClick={this.props.onLogout}
                        >
                            <FontAwesomeIcon fixedWidth={true} icon={faPowerOff as IconProp} />
                            <span className={isExpanded ? 'ml-2' : 'sr-only'}>
                                <FormattedMessage id="menu.logout" defaultMessage="Logout" />
                            </span>
                        </button>
                    </li>
                </Scrollbar>
            </nav>
        );
    }

    private toggleTitle = () => {
        const { collapse, expand, isExpanded } = this.props;

        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    private onUpdate = () => {
        const { newServiceWorker } = this.props;
        if (newServiceWorker) {
            newServiceWorker.postMessage('skipWaiting');
        }
    };
}

export default connect(
    (state: ApplicationState) => ({ ...state.deployment, ...state.mainMenu }),
    (dispatch) => bindActionCreators({ ...Deployment.actionCreators, ...MainMenuState.actionCreators }, dispatch),
)(injectIntl(SideNav));
