import { FetchMoreOrdersAction, FetchMoreOrdersActionType } from './Actions/FetchMoreOrdersAction';
import { FetchOrdersAction, FetchOrdersActionType } from './Actions/FetchOrdersAction';
import { ReceiveOrdersAction, ReceiveOrdersActionType } from './Actions/ReceiveOrdersAction';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ApplicationState } from '../ApplicationState';
import { MerchantOrderHistoryItem } from 'models/MerchantOrderHistoryItem';

function* onFetchOrders(action: FetchOrdersAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        const orders: MerchantOrderHistoryItem[] = yield call(() =>
            adminHub.getMerchantOrders(action.payload.merchantId),
        );

        const hasMore = orders.length > 20;
        if (hasMore) {
            orders.length = 20;
        }

        const act: ReceiveOrdersAction = {
            type: ReceiveOrdersActionType,
            payload: {
                orders,
                hasMore,
            },
        };

        yield put(act);
    }
}

function* onFetchMoreOrders(action: FetchMoreOrdersAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    const orderHist = state.merchantOrderHistory;
    const currentOrders = orderHist.orders;
    const merchantId = orderHist.merchantId;
    if (adminHub && merchantId && currentOrders) {
        const lastOrder = currentOrders[currentOrders.length - 1];
        const orders: MerchantOrderHistoryItem[] = yield call(() =>
            adminHub.getMerchantOrders(merchantId, lastOrder.id),
        );

        const hasMore = orders.length > 20;
        if (hasMore) {
            orders.length = 20;
        }

        const act: ReceiveOrdersAction = {
            type: ReceiveOrdersActionType,
            payload: {
                orders: [...currentOrders, ...orders],
                hasMore,
            },
        };

        yield put(act);
    }
}

export const sagas = [
    takeEvery(FetchOrdersActionType, onFetchOrders),
    takeEvery(FetchMoreOrdersActionType, onFetchMoreOrders),
];
