import { ExecuteReportAction, ExecuteReportActionType } from './Actions/ExecuteReportAction';
import { ExportReportAction, ExportReportActionType } from './Actions/ExportReportAction';
import { FetchReportsAction, FetchReportsActionType } from './Actions/FetchReportsAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';

import { ReportExportType } from 'models/ReportExportType';
import { ReportInputControlValue } from 'models/ReportInputControlValue';

export const actionCreators = {
    fetchReports: () => {
        const act: FetchReportsAction = { type: FetchReportsActionType };
        return act;
    },
    executeReport: (uri: string, values?: ReportInputControlValue[]) => {
        const act: ExecuteReportAction = { type: ExecuteReportActionType, payload: { uri, values, page: 1 } };
        return act;
    },
    goToPage: (uri: string, pageNo: number, values?: ReportInputControlValue[]) => {
        const act: ExecuteReportAction = { type: ExecuteReportActionType, payload: { uri, values, page: pageNo } };
        return act;
    },
    export: (type: ReportExportType) => {
        const act: ExportReportAction = { type: ExportReportActionType, payload: { type } };
        return act;
    },
};
