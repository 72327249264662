import { FetchOrdersAction, FetchOrdersActionType } from './Actions/FetchOrdersAction';
import { ReceiveOrdersAction, ReceiveOrdersActionType } from './Actions/ReceiveOrdersAction';
import { State, defaultState } from './State';

import { Reducer } from 'redux';

type KnownAction = FetchOrdersAction | ReceiveOrdersAction;

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case FetchOrdersActionType: {
            return {
                ...state,
                orders: null,
                ...action.payload,
            };
        }
        case ReceiveOrdersActionType: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
