import { ComponentType } from 'react';
import { log } from 'utils/log';

export default function retryLazy(fn: Function, retriesLeft = 5, interval = 1000) {
    return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error: any) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        log('[LAZY]', 'Maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    log('[LAZY]', 'Retrying');
                    retryLazy(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
