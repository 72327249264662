import { HubConnection } from '@microsoft/signalr';
import { OrderRef } from './OrderRef';
import { OrderTaskRef } from './OrderTaskRef';

class AdminOrderHub {
    public constructor(public connection: HubConnection) {}

    public getAllOrders() {
        return this.connection.invoke<OrderRef[]>('GetAllOrders').catch((err) => {
            console.error(err);
        });
    }
    public getAllTasks() {
        return this.connection.invoke<OrderTaskRef[]>('GetAllTasks').catch((err) => {
            console.error(err);
        });
    }
}

export { AdminOrderHub };
