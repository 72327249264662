import * as ChatState from 'store/ChatState';
import * as NavState from 'store/NaviState';
import React, { useEffect, useRef } from 'react';

import { IntlShape, injectIntl } from 'react-intl';

import { ApplicationState } from 'store/ApplicationState';
import BatchMessage from './BatchMessage';
import DriverStatusIcon from 'components/Driver/DriverStatusIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageThread from './MessageThread';
import Moment from 'react-moment';
import ScrollBar from 'react-perfect-scrollbar';
import { SplashScreen } from 'components/SplashScreen';
import { bindActionCreators, Dispatch } from 'redux';

import { connect } from 'react-redux';
import { faStore, faEye } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const newMsgAudio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/alert.mp3`);

var chatSoundInterval: NodeJS.Timeout;

type ChatPanelProps = { intl: IntlShape } & ChatState.State & typeof ChatState.actionCreators & NavState.State;

const ChatPanel: React.FC<ChatPanelProps> = (props: ChatPanelProps) => {
    const prevUnreadCountFiltered = useRef<number>(0);

    const startConversation = (modelId: string) => () => {
        props.startConversation(modelId);
    };

    const { isExpanded, recipients, threads, currentTargetModelId, serviceCitiesFilter, unreadCount } = props;

    const unreadCountFilteredWithServiceCities: number = Array.from(unreadCount)
        .filter((u) => (serviceCitiesFilter.length ? serviceCitiesFilter.includes(u[0]) : u))
        .reduce((acc, u) => acc + u[1], 0);

    // new message audio handler
    useEffect(() => {
        if (unreadCountFilteredWithServiceCities > prevUnreadCountFiltered.current) {
            //newMsgAudio.loop = true;
            newMsgAudio.play();
            clearInterval(chatSoundInterval);

            chatSoundInterval = setInterval(function () {
                newMsgAudio.play();
            }, 12000);
        } else {
            clearInterval(chatSoundInterval);
        }
        prevUnreadCountFiltered.current = unreadCountFilteredWithServiceCities;

        return () => {
            // Return callback to run on unmount.
            clearInterval(chatSoundInterval);
        };
    }, [unreadCountFilteredWithServiceCities]);

    if (!isExpanded) {
        return null;
    }

    if (!recipients) {
        return (
            <div className={`chat-panel h-100`}>
                <SplashScreen />
            </div>
        );
    }

    if (currentTargetModelId) {
        return (
            <div className={`chat-panel h-100`}>
                <MessageThread />
            </div>
        );
    }

    return (
        <div className={`chat-panel h-100`}>
            {threads ? (
                <ScrollBar>
                    <BatchMessage />
                    <div className="list-group">
                        {threads
                            .filter(
                                (thread) =>
                                    serviceCitiesFilter.length == 0 || serviceCitiesFilter.includes(thread.serviceCity),
                            )
                            .map((r) => {
                                const msg = r.messages[r.messages.length - 1];
                                const modelId = r.driverId || r.merchantId;
                                return (
                                    <div
                                        key={modelId}
                                        className="list-group-item c-pointer"
                                        onClick={startConversation(modelId)}
                                    >
                                        <div className="recipient-name">
                                            {r.driverId ? (
                                                <DriverStatusIcon driver={{ ...r, payPerPackage: false }} />
                                            ) : (
                                                <FontAwesomeIcon fixedWidth={true} icon={faStore as IconProp} />
                                            )}
                                            {r.name}
                                            {r.unread > 0 && (
                                                <span className="badge badge-pill badge-danger ml-2">{r.unread}</span>
                                            )}
                                        </div>
                                        <div>
                                            {msg.message}
                                            <div className="float-right small">
                                                {msg.toAdmin == false && msg.dateRead != null && (
                                                    <FontAwesomeIcon
                                                        style={{
                                                            fontSize: 14,
                                                            verticalAlign: 'text-bottom',
                                                            color: 'green',
                                                        }}
                                                        fixedWidth={true}
                                                        icon={faEye as IconProp}
                                                    />
                                                )}
                                                <Moment date={msg.dateSent} fromNow={true} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </ScrollBar>
            ) : (
                <SplashScreen />
            )}
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return { ...state.chat, ...state.navi, ...state.taskMgnt };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...ChatState.actionCreators }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChatPanel));
