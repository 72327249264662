import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/app.scss';
import './css/styles.scss';

import * as DriverState from 'store/DriverState';
import * as ServerState from 'store/ServerState';

import { ChatPanel, Header, SideNav, SplashScreen } from './components';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ApplicationState } from 'store/ApplicationState';
import Moment from 'react-moment';

import OrderRepo from './services/OrderRepo';
import PunchInPanel from 'components/Driver/PunchInPanel';
import React from 'react';
import { appConfig } from './appConfig';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import retryLazy from 'utils/componentLoader';
import scriptLoader from 'react-async-script-loader';
import { toast } from 'react-toastify';

//import OrderManagement from './components/Order/OrderManagement';
//import OrderSimpleViewer from './components/Order/OrderSimpleViewer';
//import MerchantManagement from './components/Merchant/MerchantManagement';
//import SystemSettings from './components/Settings/SystemSettings';
//import DriverManagement from './components/Driver/DriverManagement';
//import TaskManagement from './components/Dispatching/TaskManagement';
//import MapView from './components/Map/MapView';
//import AccountMgnt from './components/UserAccount/AccountMgnt';
//import ReportRunner from './components/Reporting/ReportRunner';
//import OrderBulkAction from './components/BulkAction/OrderBulkAction';
//import HubSortPanel from './components/HubSort/HubSortPanel';
//import MerchantChatPanel from 'components/Chat/MerchantChatPanel';

const OrderManagement = React.lazy(() => retryLazy(() => import('./components/Order/OrderManagement')));
const OrderSimpleViewer = React.lazy(() => retryLazy(() => import('./components/Order/OrderSimpleViewer')));
const MerchantManagement = React.lazy(() => retryLazy(() => import('./components/Merchant/MerchantManagement')));
const SystemSettings = React.lazy(() => retryLazy(() => import('./components/Settings/SystemSettings')));
const DriverManagement = React.lazy(() => retryLazy(() => import('./components/Driver/DriverManagement')));
const TaskManagement = React.lazy(() => retryLazy(() => import('./components/Dispatching/TaskManagement')));
const MapView = React.lazy(() => retryLazy(() => import('./components/Map/MapView')));
const AccountMgnt = React.lazy(() => retryLazy(() => import('./components/UserAccount/AccountMgnt')));
const ReportRunner = React.lazy(() => retryLazy(() => import('./components/Reporting/ReportRunner')));
const OrderBulkAction = React.lazy(() => retryLazy(() => import('./components/BulkAction/OrderBulkAction')));
const HubSortPanel = React.lazy(() => retryLazy(() => import('./components/HubSort/HubSortPanel')));
const MerchantChatPanel = React.lazy(() => retryLazy(() => import('./components/Chat/MerchantChatPanel')));

interface AppState {
    repos: {
        orderRepo: OrderRepo;
    } | null;
}

type AppProps = ServerState.State & typeof ServerState.actionCreators & DriverState.State;

toast.configure();

@scriptLoader(
    `https://maps.googleapis.com/maps/api/js?key=${appConfig.config.googleApiKey}&libraries=places&callback=Function.prototype`,
)
class App extends React.Component<AppProps, AppState> {
    public constructor(props: AppProps) {
        super(props);

        this.renderMerchantManagement = this.renderMerchantManagement.bind(this);
        this.renderOrderManagement = this.renderOrderManagement.bind(this);
        this.renderOrderSimpleViewer = this.renderOrderSimpleViewer.bind(this);
        this.renderSystemSettings = this.renderSystemSettings.bind(this);

        this.state = {
            repos: null,
        };
    }

    public componentDidMount() {
        this.props.connect();
    }

    public render() {
        const { connectingTimestamp, user, features } = this.props;
        if (user) {
            return (
                <div className="h-100 d-flex flex-column">
                    <Header displayName={user.displayName} merchantView={features.merchantOrder} />
                    <div className="flex-grow-1 d-flex align-items-stretch overflow-hidden">
                        <SideNav onLogout={this.props.logout} features={features} />
                        <main className="flex-grow-1">
                            <Switch>
                                {features.merchant && (
                                    <Route path="/merchants" render={this.renderMerchantManagement} />
                                )}
                                {(features.dispatch || features.merchantOrder) && (
                                    <Route path="/orders" render={this.renderOrderManagement} />
                                )}
                                {(features.dispatch || features.merchantOrder) && (
                                    <Route path="/orderviewer" render={this.renderOrderSimpleViewer} />
                                )}
                                {features.dispatch && (
                                    <Route path="/dispatching">
                                        <React.Suspense fallback={<SplashScreen />}>
                                            <TaskManagement />
                                        </React.Suspense>
                                    </Route>
                                )}
                                {features.driver && <Route path="/drivers" render={this.renderDriverManagement} />}
                                {features.dispatch && <Route path="/map" render={this.renderMapView} />}
                                {features.dispatch && (
                                    <Route path="/bulk-action">
                                        <React.Suspense fallback={<SplashScreen />}>
                                            <OrderBulkAction />
                                        </React.Suspense>
                                    </Route>
                                )}
                                {features.dispatch && (
                                    <Route path="/hub-sort">
                                        <React.Suspense fallback={<SplashScreen />}>
                                            <HubSortPanel />
                                        </React.Suspense>
                                    </Route>
                                )}
                                {features.admin && <Route path="/settings" render={this.renderSystemSettings} />}
                                {features.admin && <Route path="/users" render={this.renderAccountMgnt} />}
                                {features.report && <Route path="/reporting" render={this.renderReporting} />}
                                {features.dispatch && <Redirect to={`/dispatching`} />}
                                {features.merchantOrder && !features.dispatch && <Redirect to={`/orders`} />}
                                {features.report && !features.merchantOrder && !features.dispatch && (
                                    <Redirect to={`/dispatching`} />
                                )}
                            </Switch>
                        </main>
                        {this.props.isPunchInPanelExpanded && <PunchInPanel />}
                        {features.merchantOrder ? <MerchantChatPanel /> : <ChatPanel />}
                    </div>

                    {connectingTimestamp && (
                        <div className="server-connection-mask">
                            <div className="bg-white p-4">
                                <SplashScreen size="sm" />
                                <span>
                                    Connection lost since <Moment date={connectingTimestamp} format="HH:mm:ss" /> (
                                    <Moment date={connectingTimestamp} fromNow={true} />
                                    ), reconnecting...
                                </span>
                            </div>
                        </div>
                    )}
                    {this.props.isDisconnected && (
                        <div className="server-connection-mask">
                            <div className="bg-white p-4">
                                <p>Connection lost, please reload page.</p>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <SplashScreen />;
        }
    }

    private renderOrderManagement(props: RouteComponentProps<{}>) {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <OrderManagement {...props} hubs={this.props.hubs} />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    }

    private renderOrderSimpleViewer(props: RouteComponentProps<{}>) {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <OrderSimpleViewer {...props} hubs={this.props.hubs} />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    }

    private renderMerchantManagement(props: RouteComponentProps<{}>) {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <MerchantManagement {...props} hubs={this.props.hubs} />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    }

    private renderSystemSettings(props: RouteComponentProps<{}>) {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <SystemSettings {...props} hubs={this.props.hubs} />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    }

    private renderDriverManagement = (props: RouteComponentProps<{}>) => {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <DriverManagement {...props} hubs={this.props.hubs} />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    };

    private renderMapView = (props: RouteComponentProps<{}>) => {
        if (this.props.hubs) {
            return (
                <React.Suspense fallback={<SplashScreen />}>
                    <MapView />
                </React.Suspense>
            );
        } else {
            return <SplashScreen />;
        }
    };

    private renderAccountMgnt = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <AccountMgnt {...props} />
            </React.Suspense>
        );
    };

    private renderReporting = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <ReportRunner {...props} />
            </React.Suspense>
        );
    };
}

export default connect(
    (state: ApplicationState) => ({ ...state.server, ...state.driver }),
    (dispatch) => bindActionCreators({ ...ServerState.actionCreators }, dispatch),
)(App);
