import PropTypes from 'prop-types';
import React from 'react';
import { default as ReactSelect } from 'react-select';

const MySelect = (props: any) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={(selected: any) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};

MySelect.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    isMulti: PropTypes.any,
    closeMenuOnSelect: PropTypes.any,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
};

MySelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};

export default MySelect;
