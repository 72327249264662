import { Reducer } from 'redux';

export interface DeploymentState {
    newServiceWorker?: ServiceWorker;
}

interface KnownAction {
    newServiceWorker: ServiceWorker;
    type: 'NEW_DEPLOYMENT';
}

const initialState: DeploymentState = {};

export const actionCreators = {
    newDeployment: (serviceWorker: ServiceWorker) => ({ newServiceWorker: serviceWorker }),
};

export const reducer: Reducer<DeploymentState, KnownAction> = (
    state: DeploymentState | undefined,
    action: KnownAction,
) => {
    state = state || initialState;

    if (action.type === 'NEW_DEPLOYMENT') {
        return { ...state, serviceWorker: action.newServiceWorker };
    }

    return state;
};
