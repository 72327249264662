import { FetchMoreOrdersAction, FetchMoreOrdersActionType } from './Actions/FetchMoreOrdersAction';
import { FetchOrdersAction, FetchOrdersActionType } from './Actions/FetchOrdersAction';

export const actionCreators = {
    fetchOrders: (merchantId: string) => {
        const act: FetchOrdersAction = { type: FetchOrdersActionType, payload: { merchantId } };
        return act;
    },
    fetchMoreOrders: () => {
        const act: FetchMoreOrdersAction = { type: FetchMoreOrdersActionType };
        return act;
    },
};
