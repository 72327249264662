import { FetchUsersAction, FetchUsersActionType } from './Actions/FetchUsersAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from '../ApplicationState';
import { UserModel } from 'models/UserModel';
import { log } from 'utils/log';
import { selectAdminHub } from 'store/selectAdminHub';
import { AdminHub } from '../../models';

function* onFetchUsers(action: FetchUsersAction) {
    const adminHub: AdminHub = yield selectAdminHub();

    try {
        log('[PREF]', 'getDispatcherUsers');
        const users: UserModel[] = yield call(() => adminHub.getDispatcherUsers());
        log('[PREF]', 'getDispatcherUsers', users);

        users.sort((a, b) => (a.name < b.name ? -1 : 1));
        const act: UpdateStateAction = {
            type: UpdateStateActionType,
            payload: {
                users,
            },
        };

        yield put(act);
    } catch (err) {
        log('Fail to fetch user accounts', err);
    }
}

export const sagas = [takeEvery(FetchUsersActionType, onFetchUsers)];
