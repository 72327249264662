import { State, defaultState } from './State';

import { KnownAction } from './Actions/KnownActions';
import { Reducer } from 'redux';
import { UpdateStateActionType } from './Actions/UpdateStateAction';

export const reducer: Reducer<State, KnownAction> = (
    state: State | undefined = defaultState,
    action: KnownAction,
): State => {
    switch (action.type) {
        case UpdateStateActionType:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
