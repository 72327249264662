import * as ChatState from 'store/ChatState';
import React, { useState, useEffect, useRef } from 'react';
import * as ServerState from 'store/ServerState';
import { IntlShape, injectIntl } from 'react-intl';
import { faArrowLeft, faEye, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { ApplicationState } from 'store/ApplicationState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import ScrollBar from 'react-perfect-scrollbar';
import { bindActionCreators, Dispatch } from 'redux';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ChatMessage } from 'models/ChatMessage';
import { Merchant } from 'models/Merchant';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
//import useCounter from '../../utils/useCounter';

type MessageThreadProps = { intl: IntlShape } & ServerState.State & ChatState.State & typeof ChatState.actionCreators;

const MessageThread: React.FC<MessageThreadProps> = (props) => {
    const merchants = useSelector<ApplicationState, { [id: string]: Merchant }>((s) => s.merchantRepo.merchants);
    const { currentTargetModelId, threads, user, recipients } = props;

    const messageBottomRef = React.createRef<HTMLDivElement>();

    const numberOfMessagesInThread = useRef<number>(0);

    //  const currentTime = useCounter();

    const [messageText, setMessageText] = useState<string>('');
    // const [lastEventTime, setLastEventTime] = useState<number>(0);

    // bind event listeners for mousemove and keypress to the component
    //useEffect(() => {
    //    document.addEventListener('keypress', () => {
    //        setLastEventTime(currentTime);
    //    });
    //    document.addEventListener('mousemove', () => {
    //        setLastEventTime(currentTime);
    //    });
    //}, [currentTime]);

    //// if the dispatcher has been inactive for 30 sec in a chat thread, it will go back to list
    //useEffect(() => {
    //    if (currentTime - lastEventTime >= 30) {
    //        props.backToList();
    //    }
    //}, [currentTime]);

    // after the thread is updated, scroll down to the bottom
    useEffect(() => {
        if (!threads || !currentTargetModelId || !user || !recipients) {
            return;
        }

        const currentThread = threads.filter(
            (r) => r.driverId === currentTargetModelId || r.merchantId === currentTargetModelId,
        )[0];

        if (currentThread?.messages.length > numberOfMessagesInThread.current && messageBottomRef.current) {
            messageBottomRef.current.scrollIntoView();
            numberOfMessagesInThread.current = currentThread.messages.length;
        }
    }, [threads]);

    const markMessagesAsRead = (): void => {
        if (props.currentTargetModelId) {
            props.markMessagesAsRead(props.currentTargetModelId);
        }
    };

    const onMessageChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        setMessageText(evt.target.value);
    };

    const onKeyPress = (evt: React.KeyboardEvent): void => {
        if (!evt.shiftKey && evt.charCode === 13) {
            sendMessage();
        }
    };

    const sendMessage = (): void => {
        if (!messageText.length) {
            toast.warn('Empty message..');
            return;
        }

        if (recipients && currentTargetModelId) {
            const recipient = recipients.filter(
                (r) => r.driverId === currentTargetModelId || r.merchantId === currentTargetModelId,
            )[0];
            props.sendMessage(messageText, recipient);
            setMessageText('');
        }
    };

    if (!threads || !currentTargetModelId || !user || !recipients) {
        return null;
    }

    const userId = user.userId;
    let thread = threads.filter((r) => r.driverId === currentTargetModelId || r.merchantId === currentTargetModelId)[0];
    if (!thread) {
        const recipient = recipients.filter(
            (r) => r.driverId === currentTargetModelId || r.merchantId === currentTargetModelId,
        )[0];
        thread = {
            ...recipient,
            messages: [],
            unread: 0,
            serviceCity: '',
        };
    }

    return (
        <div className="d-flex flex-column h-100 conversation">
            <div className="sender-container">
                <button type="button" className="btn btn-primary mr-2" onClick={props.backToList}>
                    <FontAwesomeIcon fixedWidth={true} icon={faArrowLeft as IconProp} />
                </button>
                <span>{thread.name}</span>
            </div>
            <div className="flex-grow-1 pl-2 pr-2 overflow-hidden" onClick={markMessagesAsRead}>
                <ScrollBar>
                    {thread.messages.map((m) => (
                        <div
                            key={m.id}
                            className={classNames('thread-message', {
                                'received-message': m.senderUserId !== userId,
                                'sent-message': m.senderUserId === userId,
                            })}
                        >
                            <div
                                className="message-text"
                                style={{
                                    background: m.toAdmin
                                        ? '#52b848'
                                        : m.senderUserId === props.user?.userId
                                        ? '#dcdcdc'
                                        : '#dbebff',
                                    color: m.toAdmin ? '#fff' : undefined,
                                }}
                            >
                                {m.message}
                            </div>
                            <div
                                className={classNames('small', {
                                    'text-left': m.senderUserId !== userId,
                                    'text-right': m.senderUserId === userId,
                                })}
                            >
                                {m.senderUserId !== userId && m.senderName}
                                {m.senderUserId !== userId &&
                                    m.toAdmin &&
                                    m.merchantId &&
                                    `@${merchants[m.merchantId]?.name}`}
                                <span className="ml-2 mr-2 text-green">
                                    <Moment date={m.dateSent} format={`MMM Do hh:mmA Z `} />
                                </span>
                                <span>
                                    {m.dateRead != null && (
                                        <>
                                            <FontAwesomeIcon
                                                style={{
                                                    fontSize: 14,
                                                    verticalAlign: 'text-bottom',
                                                    color: 'green',
                                                    paddingRight: 2,
                                                }}
                                                fixedWidth={true}
                                                icon={faEye as IconProp}
                                            />
                                            {<span className="small color-theme">by {m.readByUserName}</span>}
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    ))}
                    <div className="d-hidden" ref={messageBottomRef} />
                </ScrollBar>
            </div>
            <div className="input-group send-message-container">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Message..."
                    value={messageText}
                    onChange={onMessageChange}
                    onKeyPress={onKeyPress}
                />
                <div className="input-group-append">
                    <button className="btn btn-primary ml-2" type="button" onClick={sendMessage}>
                        <FontAwesomeIcon fixedWidth={true} icon={faPaperPlane as IconProp} />
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        ...state.chat,
        ...state.server,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...ChatState.actionCreators }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MessageThread));
