import moment from 'moment';
import { Driver } from '../models/Driver';

export const getTrackerIconColor = (driver: Driver): string => {
    if (driver.locationTracker?.trackerStatus === 'Plugged') {
        return 'green';
    } else if (driver.locationTracker?.trackerStatus === 'Unplugged') {
        // orange: when tracker is unplugged but this driver does not have a upcoming shift or the upcoming shift is not yet come
        if (driver.nextShiftStartTime === null || moment().isBefore(driver.nextShiftStartTime)) {
            return 'orange';
        } else {
            return 'red';
        }
    } else if (driver.locationTracker?.latitude !== 0) {
        return 'grey';
    } else {
        return 'black';
    }
};

export const getTrackerIconAnimation = (driver: Driver): string => {
    if (driver.locationTracker?.trackerStatus === 'Plugged') {
        return '';
    } else if (driver.locationTracker?.trackerStatus === 'Unplugged') {
        // orange: when tracker is unplugged but this driver does not have a upcoming shift or the upcoming shift is not yet come
        if (driver.nextShiftStartTime === null || moment().isBefore(driver.nextShiftStartTime)) {
            return '';
        } else if (moment().subtract('4h').isAfter(moment(driver.locationTracker.timestamp))) {
            return 'blink';
        } else {
            return '';
        }
    } else if (driver.locationTracker?.latitude !== 0) {
        return '';
    } else {
        return '';
    }
};
