import moment from 'moment';

const localOffset = -new Date().getTimezoneOffset();

export function formatDateTime(
    dateTime?: string | Date | null,
    offset: number | string = localOffset,
    withTimeZone = false,
): string | undefined {
    if (dateTime) {
        const mnt = moment(dateTime);
        const str = mnt.utcOffset(offset).format(withTimeZone ? 'lll ZZ' : 'lll');
        return str;
    }
}
