import { ReportContent } from 'models/ReportContent';
import { ReportInfo } from 'models/ReportInfo';
import { ReportInputControlValue } from 'models/ReportInputControlValue';

export interface State {
    reports: ReportInfo[] | null;
    currentReportUri: string | null;
    currentInputControls: ReportInputControlValue[] | null;
    currentReport: ReportContent | null;
    currentPage: number;
    showInputControls: boolean;
    runningReport: boolean;
}

export const defaultState: State = {
    reports: null,
    currentReportUri: null,
    currentInputControls: null,
    currentReport: null,
    currentPage: 0,
    showInputControls: false,
    runningReport: false,
};
