import { defineMessages } from 'react-intl';

const idPrefix = 'driver.';

const messages = defineMessages({
    payGroup: {
        defaultMessage: 'Pay Group',
        id: idPrefix + 'payGroup',
    },
    labelCreateDriver: {
        defaultMessage: 'Create Driver',
        id: idPrefix + 'labelCreateDriver',
    },
    labelFriendlyName: {
        defaultMessage: 'Friendly Name',
        id: idPrefix + 'labelFriendlyName',
    },
    labelEmail: {
        defaultMessage: 'Email',
        id: idPrefix + 'labelEmail',
    },
    labelName: {
        defaultMessage: 'Name',
        id: idPrefix + 'labelName',
    },
    labelPassword: {
        defaultMessage: 'Password',
        id: idPrefix + 'labelPassword',
    },
    labelNote: {
        defaultMessage: 'Note',
        id: idPrefix + 'labelNote',
    },
    labelPhone: {
        defaultMessage: 'Phone',
        id: idPrefix + 'labelPhone',
    },
    labelServiceCity: {
        defaultMessage: 'Service City',
        id: idPrefix + 'labelServiceCity',
    },
    labelPhoneOs: {
        defaultMessage: 'Phone Os',
        id: idPrefix + 'labelPhoneOs',
    },
    labelDisableOrderConfirmation: {
        defaultMessage: 'Disable Order Confirmation',
        id: idPrefix + 'labelDisableOrderConfirmation',
    },
    labelHideLastEta: {
        defaultMessage: 'Hide Last Eta',
        id: idPrefix + 'labelHideLastEta',
    },
    labelRequireBarcodeScan: {
        defaultMessage: 'Require Barcode Scan',
        id: idPrefix + 'labelRequireBarcodeScan',
    },
    msgSelectPhoneOs: {
        defaultMessage: 'Select Phone Os',
        id: idPrefix + 'msgSelectPhoneOs',
    },
    msgSelectServiceCity: {
        defaultMessage: 'Select Service City',
        id: idPrefix + 'msgSelectServiceCity',
    },
    msgSelectDriver: {
        defaultMessage: 'Select a driver to view information',
        id: idPrefix + 'msgSelectDriver',
    },
    btnPunchOut: {
        defaultMessage: 'Punch Out',
        id: idPrefix + 'btnPunchOut',
    },
    btnReject: {
        defaultMessage: 'Reject',
        id: idPrefix + 'btnReject',
    },
    btnPunchIn: {
        defaultMessage: 'Punch In',
        id: idPrefix + 'btnPunchIn',
    },
    btnDelete: {
        defaultMessage: 'Delete',
        id: idPrefix + 'btnDelete',
    },
    btnRestore: {
        defaultMessage: 'Restore',
        id: idPrefix + 'btnRestore',
    },
    labelPunchInReason: {
        defaultMessage: 'Reason',
        id: idPrefix + 'labelPunchInReason',
    },
    tabNameWorkLogs: {
        defaultMessage: 'Work Logs',
        id: idPrefix + 'tabNameWorkLogs',
    },
    tabNameCurrent: {
        defaultMessage: 'Current',
        id: idPrefix + 'tabNameCurrent',
    },
    tabNameDeleted: {
        defaultMessage: 'Deleted',
        id: idPrefix + 'tabNameDeleted',
    },
    btnLoadMore: {
        defaultMessage: 'Load more results...',
        id: idPrefix + 'btnLoadMore',
    },
    tabNameHistory: {
        defaultMessage: 'History',
        id: idPrefix + 'tabNameHistory',
    },
    msgPickupFrom: {
        defaultMessage: 'Pickup from {addressName}',
        id: idPrefix + 'msgPickupFrom',
    },
    msgDeliverTo: {
        defaultMessage: 'Deliver to {addressName}',
        id: idPrefix + 'msgDeliverTo',
    },
    validationPassword: {
        defaultMessage: 'Please enter a strong password.',
        id: idPrefix + 'validationPassword',
    },
    validateServiceCity: {
        defaultMessage: 'Please select a service city',
        id: idPrefix + 'validateServiceCity',
    },
    msgGeneralError: {
        defaultMessage: 'An error has occurred, please try again later.',
        id: idPrefix + 'msgGeneralError',
    },
});

export default messages;
