import { State, defaultState } from './State';

import { KnownAction } from './Actions/KnownAction';
import { ReceiveOrderActionType } from './Actions/ReceiveOrderAction';
import { Reducer } from 'redux';
import { UpdateOrderFilterActionType } from './Actions/UpdateOrderFilterAction';
import { UpdateOrderFilterTableViewActionType } from './Actions/UpdateOrderFilterTableViewAction';
import { UpdateStateActionType } from './Actions/UpdateStateAction';
import { ShowHideOrderToggleActionType } from './Actions/ShowHideOrderToggleAction';
import { ShowHideFilterActionType } from './Actions/ShowHideFilterAction';
import { ShowTableViewActionType } from './Actions/ShowTableViewAction';
import { TogglePrintActionType } from './Actions/TogglePrintAction';
import { SelectingOrdersActionType } from './Actions/SelectingOrdersAction';
import { SelectAllOrdersActionType } from './Actions/SelectAllOrdersAction';
import { SortOrdersActionType } from './Actions/SortOrdersAction';
import { TogglePrintOrderSectionActionType } from './Actions/TogglePrintOrderSectionAction';

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case UpdateStateActionType:
            return {
                ...state,
                ...action.payload,
            };
        case UpdateOrderFilterActionType:
            return {
                ...state,
                ...action.payload,
            };
        case UpdateOrderFilterTableViewActionType:
            return {
                ...state,
                ...action.payload,
            };
        case ShowHideOrderToggleActionType:
            return {
                ...state,
                ...action.payload,
            };
        case ShowTableViewActionType:
            return {
                ...state,
                ...action.payload,
            };
        case ShowHideFilterActionType:
            return {
                ...state,
                ...action.payload,
            };
        case TogglePrintActionType:
            return {
                ...state,
                ...action.payload,
            };
        case TogglePrintOrderSectionActionType:
            return {
                ...state,
                ...action.payload,
            };
        case SelectingOrdersActionType:
            return {
                ...state,
                ...action.payload,
            };
        case SelectAllOrdersActionType:
            return {
                ...state,
                ...action.payload,
            };
        case SortOrdersActionType:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
