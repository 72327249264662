/* eslint-disable @typescript-eslint/no-unused-vars */
import { Without } from './helpers';

export interface DriverLocation {
    accuracy: number;
    fromAddress: boolean;
    fromTracker: boolean;
    latitude: number;
    longitude: number;
    timestamp: string | null;
    trackerStatus: string;
}

export type DriverLocationValues = Without<
    DriverLocation,
    keyof {
        accuracy: {};
        latitude: {};
        longitude: {};
        timestamp: {};
    }
> & {
    accuracy: string;
    latitude: string;
    longitude: string;
    timestamp: string;
};

export function getDefault(): DriverLocation {
    return {
        accuracy: 0,
        fromAddress: false,
        fromTracker: false,
        latitude: 0,
        longitude: 0,
        timestamp: '',
        trackerStatus: '',
    };
}

export function toValues(model: Partial<DriverLocation>): DriverLocationValues {
    model = model || {};
    return {
        accuracy: `${model.accuracy || 0}`,
        fromAddress: model.fromAddress || false,
        fromTracker: model.fromTracker || false,
        latitude: `${model.latitude || 0}`,
        longitude: `${model.longitude || 0}`,
        timestamp: model.timestamp || '',
        trackerStatus: model.trackerStatus || '',
    };
}

export function toModel(values: DriverLocationValues): DriverLocation {
    return {
        ...values,
        accuracy: Number.parseFloat(values.accuracy),
        latitude: Number.parseFloat(values.latitude),
        longitude: Number.parseFloat(values.longitude),
        timestamp: values.timestamp === '' ? null : values.timestamp,
    };
}
