import { Order } from 'models/Order';
import { TransportHub } from '../../models/TransportHub';

export interface State {
    order?: Order | null;
    deliveryDate: string | '';
    deliveryTime: string | '';
    dateChanged: string | '';
    timeChanged: string | '';
    isModalVisiable: boolean;
    transportHubs: TransportHub[] | null;
}

export const defaultState: State = {
    isModalVisiable: false,
    order: null,
    deliveryDate: '',
    deliveryTime: '',
    dateChanged: '',
    timeChanged: '',
    transportHubs: null,
};
