import * as ServerState from './ServerState';

import {
    AcceptPunchInDriverAction,
    AcceptPunchInDriverActionType,
    DriverUpdateAction,
    DriverUpdateActionType,
    FetchDriverActionType,
    ForcePunchInAction,
    ForcePunchInActionType,
    LocationUpdatedAction,
    LocationUpdatedActionType,
    PunchOutDriverAction,
    PunchOutDriverActionType,
    ReceiveDriverAction,
    ReceiveDriverActionType,
    RejectPunchInDriverActionType,
    ResetBinNumbersAction,
    ResetBinNumbersActionType,
    State,
} from './DriverState';
import { call, put, select, take, takeEvery, throttle } from 'redux-saga/effects';

import { ApplicationState } from './ApplicationState';
import { Driver } from 'models/Driver';
import { DriverLocation } from 'models/DriverLocation';
import { DriverStatusNotification } from 'models/DriverStatusNotification';
import { HubConnectionState } from '@microsoft/signalr';
import { eventChannel } from 'redux-saga';
import { getDisplayName } from 'utils/getDisplayName';
import { log } from 'utils';
import { toast } from 'react-toastify';

const ProcessDriverLocationUpdateActionType = 'DRIVER/ProcessDriverLocationUpdate';
let locationUpdateBuffer: { [driverId: string]: DriverLocation } = {};

function* processDriverLocationUpdate() {
    const locations = locationUpdateBuffer;
    locationUpdateBuffer = {};

    log('[PREF]', 'processDriverLocationUpdate', locations);
    const act: LocationUpdatedAction = {
        type: LocationUpdatedActionType,
        payload: { locations },
    };

    yield put(act);
    log('[PREF]', 'processDriverLocationUpdate');
}

function* fetchAllDrivers() {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;

    if (adminHub && adminHub.connection.state == HubConnectionState.Connected) {
        log('[PREF]', 'getDrivers');
        const drivers: Driver[] = yield call(() =>
            adminHub.getDrivers().catch(() => {
                return;
            }),
        );
        log('[PREF]', 'getDrivers', drivers?.length ?? 'NO DRIVERS');

        if (drivers) {
            // Sort drivers by status and name
            drivers.sort(
                (a, b) =>
                    +(b.phoneStatus === 'Active') - +(a.phoneStatus === 'Active') ||
                    getDisplayName(a).toLowerCase().localeCompare(getDisplayName(b).toLowerCase()),
            );

            const act: ReceiveDriverAction = {
                type: ReceiveDriverActionType,
                payload: { drivers },
            };
            yield put(act);
        }
    }
}

function* processDrivers(action: ReceiveDriverAction) {
    log('[PREF]', 'processDrivers', action.payload.drivers.length);
    const activeDrivers = action.payload.drivers.filter((d) => d.phoneStatus === 'Active');
    const act: DriverUpdateAction = {
        type: DriverUpdateActionType,
        payload: { ...action.payload, activeDrivers },
    };
    yield put(act);

    const driverLocations: { [id: string]: DriverLocation } = yield select(
        (s: ApplicationState) => s.driver.driverLocations,
    );

    let locationUpdated = false;
    for (const d of action.payload.drivers) {
        if (d.location && !driverLocations[d.id]) {
            locationUpdateBuffer[d.id] = d.location;
            locationUpdated = true;
        }
    }

    if (locationUpdated) {
        yield put({ type: ProcessDriverLocationUpdateActionType });
    }
}

function* punchOutDriver(action: PunchOutDriverAction) {
    log('punchOutDriver', action);
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        yield call(() => adminHub.punchOutDriver(action.payload.driverId));
    }
}

function* forcePunchIn(action: ForcePunchInAction) {
    log('forcePunchInAction', action);
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        yield call(() => adminHub.forcePunchInDriver(action.payload.driverId));
    }
}

function* listenDriverStatus() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (notification: DriverStatusNotification) => {
                emitter(notification);
            };

            hub.connection.on('OnDriverStatusChanged', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnDriverStatusChanged', callback);
            };
        });

        while (true) {
            const notification: DriverStatusNotification = yield take(channel);
            log('OnDriverStatusChanged', notification);

            const driverState: State = yield select((s: ApplicationState) => s.driver);

            if (driverState.drivers) {
                const drivers = driverState.drivers.map((d) => {
                    if (d.id === notification.driverId) {
                        return {
                            ...d,
                            phoneStatus: notification.status,
                            isOnline: notification.isOnline,
                            isDisabled: notification.isDisabled,
                            tempTag: notification.tempTag,
                            binNumber: notification.binNumber,
                            binReadyForVerification: notification.binReadyForVerification,
                            binVerified: notification.binVerified,
                            binAuditerName: notification.binAuditerName,
                            binOutForDelivery: notification.binOutForDelivery,
                            punchInMode: notification.punchInMode,
                            lastEta: notification.lastEta,
                            nextShiftStartTime: notification.nextShiftStartTime,
                        };
                    } else {
                        return d;
                    }
                });

                const act: ReceiveDriverAction = {
                    type: ReceiveDriverActionType,
                    payload: { drivers },
                };
                yield put(act);
            }
        }
    }
}

function* listenDriverSingleChanged() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (notification: Driver) => {
                emitter(notification);
            };

            hub.connection.on('OnDriverSingleChanged', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnDriverSingleChanged', callback);
            };
        });

        while (true) {
            const notification: Driver = yield take(channel);
            log('OnDriverSingleChanged', notification);

            const driverState: State = yield select((s: ApplicationState) => s.driver);

            if (driverState.drivers) {
                const drivers = driverState.drivers.map((d) => {
                    if (d.id === notification.id) {
                        return {
                            ...notification,
                        };
                    } else {
                        return d;
                    }
                });

                const act: ReceiveDriverAction = {
                    type: ReceiveDriverActionType,
                    payload: { drivers },
                };
                yield put(act);
            }
        }
    }
}

function* accpetPunchIn(action: AcceptPunchInDriverAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        yield call(() => adminHub.acceptPunchIn(action.payload.driverId, action.payload.punchInData));
    }
}

function* rejectPunchIn(action: AcceptPunchInDriverAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        yield call(() => adminHub.rejectPunchIn(action.payload.driverId, action.payload.punchInData));
    }
}

function* listenDriverListChanged() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = () => {
                emitter({});
            };

            hub.connection.on('OnDriverListUpdated', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnDriverListUpdated', callback);
            };
        });

        while (true) {
            yield take(channel);
            log('OnDriverListUpdated');
            yield call(toast, 'Drivers list updated');
            const act = {
                type: FetchDriverActionType,
            };
            yield put(act);
        }
    }
}

function* listenDriverLocationChanged() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.driverStatus;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (driverId: string, location: DriverLocation) => {
                emitter({
                    driverId,
                    location,
                });
            };

            hub.connection.on('OnDriverLocationUpdated', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnDriverLocationUpdated', callback);
            };
        });

        while (true) {
            const notification: {
                driverId: string;
                location: DriverLocation;
            } = yield take(channel);
            // log('OnDriverLocationUpdated', notification);
            locationUpdateBuffer[notification.driverId] = notification.location;
            yield put({ type: ProcessDriverLocationUpdateActionType });
        }
    }
}

function* resetBinNumbers(action: ResetBinNumbersAction) {
    log('resetBinNumbers', action);
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        yield call(() => adminHub.resetBinNumbersDrivers());
        yield call(() => toast.success('Bin Number Reset to 0!'));
    }
}

export default [
    takeEvery(ServerState.ServerConnectedActionType, listenDriverLocationChanged),
    takeEvery(ServerState.ServerConnectedActionType, listenDriverStatus),
    takeEvery(ServerState.ServerConnectedActionType, listenDriverSingleChanged),
    takeEvery(ServerState.ServerConnectedActionType, listenDriverListChanged),

    throttle(0.5 * 1000, FetchDriverActionType, fetchAllDrivers),
    takeEvery(ReceiveDriverActionType, processDrivers),
    takeEvery(PunchOutDriverActionType, punchOutDriver),
    takeEvery(ForcePunchInActionType, forcePunchIn),
    takeEvery(AcceptPunchInDriverActionType, accpetPunchIn),
    takeEvery(RejectPunchInDriverActionType, rejectPunchIn),
    takeEvery(ResetBinNumbersActionType, resetBinNumbers),
    throttle(1 * 4000, ProcessDriverLocationUpdateActionType, processDriverLocationUpdate),
];
