import { DriverOrderTaskHistoryItem } from 'models/DriverOrderTaskHistoryItem';

export const ReceiveHistoryActionType = 'DRIVER_ORDER_HIST/RECEIVE_HIST';

export interface ReceiveHistoryAction {
    type: typeof ReceiveHistoryActionType;
    payload: {
        orders: DriverOrderTaskHistoryItem[];
        hasMore: boolean;
    };
}
