import { GeoAddress } from 'models/GeoAddress';
import { GeoStopType } from 'models/GeoStopType';
import { log } from './log';

const componentLookup: { [fieldName: string]: string[] } = {
    city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
    ],
    country: ['country'],
    num: ['street_number'],
    postal_code: ['postal_code'],
    region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
    ],
    street: ['street_address', 'route'],
};

export function parseGeocoderResult(
    geocoderResults: google.maps.GeocoderResult,
    latLng: google.maps.LatLngLiteral,
): GeoAddress {
    const address: { [fieldName: string]: string } = {
        city: '',
        country: '',
        num: '',
        postal_code: '',
        region: '',
        street: '',
        stopType: 'Unknown',
    };

    const lookup: { [componentType: string]: string } = {};
    for (const component of geocoderResults.address_components) {
        const componentType = component.types[0];
        if (!lookup[componentType]) {
            lookup[componentType] = component.long_name;
        }
    }

    for (const fieldName of Object.keys(componentLookup)) {
        if (!address[fieldName]) {
            const componentTypes = componentLookup[fieldName];
            for (const componentType of componentTypes) {
                if (lookup[componentType]) {
                    address[fieldName] = lookup[componentType];
                    break;
                }
            }
        }
    }

    const addr = {
        address: `${address.num} ${address.street}`.trim(),
        address2: '',
        city: address.city || '',
        country: address.country || '',
        location: {
            latitude: latLng ? latLng.lat : 0,
            longitude: latLng ? latLng.lng : 0,
        },
        postal: address.postal_code || '',
        state: address.region || '',
        stopType: (address.stopType as GeoStopType) || ('Unknown' as GeoStopType),
    };

    log('addr mapped', geocoderResults, addr);
    return addr;
}
