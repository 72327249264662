import { MerchantOrganization } from 'models/MerchantOrganization';
import { FetchMerchantOrgActionType } from './Actions/FetchMerchantOrgAction';
import { SaveMerchantOrgActionType } from './Actions/SaveMerchantOrgAction';

export const actionCreators = {
    getMerchantOrg: (id: string) => ({ type: FetchMerchantOrgActionType, payload: { id } }),
    saveMerchantOrg: (merchantOrganization: MerchantOrganization) => ({
        type: SaveMerchantOrgActionType,
        payload: merchantOrganization,
    }),
};
