import * as DriverState from './DriverState';
import * as ServerState from './ServerState';

import { AppThunkAction } from './AppThunkAction';
import { ChatMessage } from 'models/ChatMessage';
import { ChatRecipient } from 'models/ChatRecipient';
import { ChatRecipientThread } from 'models/ChatRecipientThread';
import { Reducer } from 'redux';

export const OpenMessagePanelActionType = 'CHAT/OPEN_MESSAGE_PANEL';
export const ReceiveRecipientsActionType = 'CHAT/RECEIVE_RECIPIENTS';
export const BackToListActionType = 'CHAT/BACK_TO_LIST';
export const SendMessageActionType = 'CHAT/SEND_MESSAGE';
export const FetchAllMessagesActionType = 'CHAT/FETCH_ALL_MESSAGES';
export const ReceiveAllMessagesActionType = 'CHAT/RECEIVE_ALL_MESSAGES';
export const ReceiveNewMessageActionType = 'CHAT/RECEIVE_NEW_MESSAGE';
export const CloseMessageActionType = 'CHAT/CLOSE_MESSAGE_PANEL';
export const MarkMessageAsReadActionType = 'CHAT/MARK_MESSAGE_READ';
export const StartConversationActionType = 'CHAT/START_CONVERSATION';
export const StartConversation2ActionType = 'CHAT/START_CONVERSATION2';
export const UpdateStateActionType = 'CHAT/UPDATE_STATE';

export interface UpdateStateAction {
    type: typeof UpdateStateActionType;
    payload: Partial<State>;
}

export interface MarkMessageAsReadAction {
    type: typeof MarkMessageAsReadActionType;
    payload: {
        modelId: string;
    };
}

export interface SendMessageAction {
    type: typeof SendMessageActionType;
    payload: {
        driverId: string;
        merchantId: string;
        senderName: string;
        senderUserId: string;
        recipientName: string;
        recipientUserId: string;
        message: string;
    };
}

export interface ReceiveNewMessageAction {
    type: typeof ReceiveNewMessageActionType;
    payload: {
        message: ChatMessage;
    };
}
export interface ReceiveRecipientsAction {
    type: typeof ReceiveRecipientsActionType;
    payload: {
        recipients: ChatRecipient[];
    };
}

export interface FetchAllMessagesAction {
    type: typeof FetchAllMessagesActionType;
}

export interface ReceiveAllMessagesAction {
    type: typeof ReceiveAllMessagesActionType;
    payload: {
        threads: ChatRecipientThread[];
        unreadCount: Map<string, number>;
    };
}

export interface BackToListAction {
    type: typeof BackToListActionType;
}

export interface StartConversationAction {
    type: typeof StartConversationActionType;
    userId: string;
}

export interface StartConversation2Action {
    type: typeof StartConversation2ActionType;
    modelId: string;
}

export interface OpenMessagePanelAction {
    type: typeof OpenMessagePanelActionType;
}

export interface CloseMessagePanelAction {
    type: typeof CloseMessageActionType;
}

type KnownAction =
    | StartConversationAction
    | StartConversation2Action
    | OpenMessagePanelAction
    | CloseMessagePanelAction
    | DriverState.KnownAction
    | ReceiveRecipientsAction
    | FetchAllMessagesAction
    | ReceiveAllMessagesAction
    | SendMessageAction
    | ReceiveNewMessageAction
    | BackToListAction
    | MarkMessageAsReadAction
    | UpdateStateAction
    | {
          type: typeof ServerState.ClearSidePanelActionType;
      };

export interface State {
    isExpanded: boolean;
    currentTargetModelId: string | null;
    recipients: ChatRecipient[] | null;
    threads: ChatRecipientThread[] | null;
    // key: serviceCity, value: number of unread msgs from selected service city
    unreadCount: Map<string, number>;
    merchant: boolean;
    ringing: boolean;
}

const initialState: State = {
    isExpanded: false,
    currentTargetModelId: null,
    recipients: null,
    threads: null,
    unreadCount: new Map<string, number>(),
    merchant: false,
    ringing: false,
};

export const actionCreators = {
    startConversation:
        (modelId: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: OpenMessagePanelActionType });
            dispatch({ type: StartConversation2ActionType, modelId });
            dispatch({ type: MarkMessageAsReadActionType, payload: { modelId } });
        },
    toggleMessagePanel: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        if (state.chat.isExpanded) {
            dispatch({ type: CloseMessageActionType });
        } else {
            dispatch({ type: ServerState.ClearSidePanelActionType });
            dispatch({ type: OpenMessagePanelActionType });
        }
    },
    backToList: () => ({ type: BackToListActionType }),
    sendMessage:
        (message: string, recipient: ChatRecipient): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const state = getState();
            const user = state.server.user;
            if (user) {
                dispatch({
                    type: SendMessageActionType,
                    payload: {
                        driverId: recipient.driverId,
                        merchantId: recipient.merchantId,
                        senderName: user.displayName,
                        senderUserId: user.userId,
                        recipientName: recipient.name,
                        recipientUserId: recipient.userId,
                        message,
                    },
                });
            }
        },
    markMessagesAsRead: (modelId: string) => ({ type: MarkMessageAsReadActionType, payload: { modelId } }),
};

export const reducer: Reducer<State, KnownAction> = (
    state: State | undefined = initialState,
    action: KnownAction,
): State => {
    switch (action.type) {
        case UpdateStateActionType:
            return { ...state, ...action.payload };
        case StartConversation2ActionType:
            return { ...state, currentTargetModelId: action.modelId };
        case OpenMessagePanelActionType:
            return { ...state, isExpanded: true };
        case CloseMessageActionType:
            return { ...state, isExpanded: false };
        case ReceiveRecipientsActionType:
            return { ...state, ...action.payload };
        case BackToListActionType:
            return { ...state, currentTargetModelId: null };
        case ReceiveAllMessagesActionType:
            return { ...state, ...action.payload };
        case ReceiveNewMessageActionType:
            const msg = action.payload.message;
            let isUnread = true;
            if (state.merchant) {
                if (msg.toAdmin && !msg.dateRead) {
                    isUnread = false; // msg sent by merchant
                    // msg.dateRead = new Date().toISOString();
                }
            } else {
                if (!msg.toAdmin && !msg.dateRead) {
                    isUnread = false; // msg sent by dispatcher
                    // msg.dateRead = new Date().toISOString();
                }
            }

            const modelId = msg.driverId || msg.merchantId;
            const recipient =
                state.recipients &&
                state.recipients.filter((r) => r.driverId === modelId || r.merchantId === modelId)[0];
            const thread =
                state.threads && state.threads.filter((r) => r.driverId === modelId || r.merchantId === modelId)[0];
            //debugger;
            if (state.merchant && thread) {
                const newThread = {
                    ...thread,
                    unread: thread.unread + (isUnread ? 1 : 0),
                    messages: [...thread.messages, msg],
                    serviceCity: msg.serviceCity || '',
                };

                let updatedUnreadcount = state.unreadCount;
                if (isUnread) {
                    const currCount = state.unreadCount.get(msg.serviceCity) || 0;
                    updatedUnreadcount = state.unreadCount.set(msg.serviceCity, currCount + 1);
                }

                return {
                    ...state,
                    unreadCount: updatedUnreadcount,
                    threads: [newThread],
                };
            } else if (recipient) {
                const otherThreads = state.threads ? state.threads.filter((t) => t !== thread) : [];
                //debugger;
                if (thread && thread.messages.find((m) => m.id === msg.id)) {
                    return state;
                }

                const newThread = thread
                    ? {
                          ...thread,
                          unread: thread.unread + (isUnread ? 1 : 0),
                          messages: [...thread.messages, msg],
                          serviceCity: msg.serviceCity || '',
                      }
                    : {
                          ...recipient,
                          unread: isUnread ? 1 : 0,
                          messages: [msg],
                          serviceCity: msg.serviceCity || '',
                      };

                let updatedUnreadcount = state.unreadCount;
                if (isUnread) {
                    const currCount = state.unreadCount.get(msg.serviceCity) || 0;
                    updatedUnreadcount = state.unreadCount.set(msg.serviceCity, currCount + 1);
                }

                return {
                    ...state,
                    unreadCount: updatedUnreadcount,
                    threads: [newThread, ...otherThreads],
                };
            }

            return state;
        default:
            return state;
    }
};
