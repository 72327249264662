import * as ChatState from './ChatState';
import * as DriverState from './DriverState';
import * as ServerState from './ServerState';

import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import { AdminHub } from 'models';
import { ApplicationState } from './ApplicationState';
import { ChatMessage } from 'models/ChatMessage';
import { ChatRecipientThread } from 'models/ChatRecipientThread';
import { Driver } from 'models/Driver';
import { Merchant } from 'models/Merchant';
import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import { getDisplayName } from 'utils/getDisplayName';
import { log } from 'utils';
import { selectAdminHub } from './selectAdminHub';
import { ServerConnectedAction } from './ServerState';

const newMsgAudio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/alert.mp3`);
newMsgAudio.loop = true;

function* updateAlertState() {
    // const { chat }: ApplicationState = yield select((s: ApplicationState) => ({
    //     chat: s.chat,
    // }));
    // const { navi }: ApplicationState = yield select((s: ApplicationState) => ({
    //     navi: s.navi,
    // }));
    // let succeeded = false;
    // while (!succeeded) {
    //     try {
    //         const unreadCount = Object.values(chat.unreadCount.values).reduce((curr, a) => curr + a, 0);
    //         if (unreadCount) {
    //             yield call(() => newMsgAudio.play());
    //         } else {
    //             newMsgAudio.pause();
    //         }
    //         succeeded = true;
    //     } catch (err) {
    //         log('Fail to play message alert', err);
    //     }
    //     if (!succeeded) {
    //         yield delay(500);
    //     }
    // }
}

function* getOrFetchMerchants() {
    const state: ApplicationState = yield select();
    let merchants = state.merchantRepo.allMerchants;
    if (merchants.length === 0) {
        const hub: AdminHub = yield selectAdminHub();
        merchants = yield hub.getMerchants();
    }

    return merchants;
}

function* mapRecipients(drivers: Driver[]) {
    const recipients = drivers
        .filter((d) => !!d.userId)
        .map((d) => ({
            merchantId: '',
            driverId: d.id,
            userId: d.userId,
            name: getDisplayName(d),
            phoneStatus: d.phoneStatus,
            vehicleType: d.vehicleType,
            isOnline: d.isOnline,
        }));

    const merchants: Merchant[] = yield getOrFetchMerchants();
    for (const merchant of merchants) {
        recipients.push({
            merchantId: merchant.id,
            driverId: '',
            userId: '',
            name: merchant.name,
            phoneStatus: 'Active',
            vehicleType: 'car',
            isOnline: true,
        });
    }

    yield put<ChatState.ReceiveRecipientsAction>({
        type: ChatState.ReceiveRecipientsActionType,
        payload: {
            recipients,
        },
    });
}

function* updateRecipients(action: DriverState.DriverUpdateAction) {
    yield mapRecipients(action.payload.drivers);
}

function* fetchRecipients() {
    const state: ApplicationState = yield select();
    if (state.driver.drivers) {
        yield mapRecipients(state.driver.drivers);
    } else {
        if (!state.driver.isLoading) {
            yield put({ type: DriverState.FetchDriverActionType });
        }

        const action: DriverState.DriverUpdateAction = yield take(DriverState.DriverUpdateActionType);
        yield mapRecipients(action.payload.drivers);
    }
}

function* fecthMessages() {
    const state: ApplicationState = yield select();
    if (state.server.hubs && state.server.hubs.admin && state.server.user && state.chat.recipients) {
        const merchantMode = state.chat.merchant;
        const adminHub = state.server.hubs.admin;
        const messages: ChatMessage[] = yield call([adminHub, adminHub.getChatMessages]);

        const recipientLookup = _.keyBy(state.chat.recipients, (r) => r.driverId || r.merchantId);
        const groups = _.groupBy(messages, (m) => m.driverId || m.merchantId);

        const unreadCount = new Map<string, number>();
        const threads = Object.keys(groups)
            .map((driverOrMerchantId) => {
                const threadMessages = groups[driverOrMerchantId].sort((a, b) => (a.id < b.id ? -1 : 1));
                const unreadCount = merchantMode
                    ? threadMessages.filter((m) => !m.toAdmin && !m.dateRead).length
                    : threadMessages.filter((m) => m.toAdmin && !m.dateRead).length;

                const recipient = recipientLookup[driverOrMerchantId];
                if (recipient) {
                    return {
                        ...recipient,
                        messages: threadMessages,
                        unread: unreadCount,
                        serviceCity: threadMessages[threadMessages.length - 1].serviceCity ?? '',
                    };
                }

                return null;
            })
            .filter((t): t is ChatRecipientThread | any => t !== null && t.messages.length > 0);

        threads.forEach((thread) => {
            const curr = unreadCount.get(thread.serviceCity) || 0;
            unreadCount.set(thread.serviceCity, curr + thread.unread);
        });

        log('fecthMessages', messages, threads);
        yield put<ChatState.ReceiveAllMessagesAction>({
            type: ChatState.ReceiveAllMessagesActionType,
            payload: {
                threads: threads,
                unreadCount: unreadCount,
            },
        });

        yield updateAlertState();
    }
}

function* onRecipientsReady() {
    const state: ApplicationState = yield select();
    if (state.chat.threads) {
        const recipientLookup = _.keyBy(state.chat.recipients, (r) => r.driverId || r.merchantId);
        const newThreads = state.chat.threads.map((t) => {
            const recipient = recipientLookup[t.driverId || t.merchantId];
            return {
                ...t,
                ...recipient,
            };
        });

        yield put<ChatState.ReceiveAllMessagesAction>({
            type: ChatState.ReceiveAllMessagesActionType,
            payload: {
                threads: newThreads,
                unreadCount: new Map<string, number>(),
            },
        });

        yield updateAlertState();
    } else {
        yield put<ChatState.FetchAllMessagesAction>({
            type: ChatState.FetchAllMessagesActionType,
        });
    }
}

function* sendMessage(action: ChatState.SendMessageAction) {
    const state: ApplicationState = yield select();
    const adminHub = state.server.hubs && state.server.hubs.admin;
    if (adminHub) {
        adminHub.sendChatMessage(action.payload);
    }
}

function* onClearSidePanel() {
    yield put({ type: ChatState.CloseMessageActionType });
}

function* listenNewMessages() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.admin;
    if (hub) {
        const channel = eventChannel((emitter) => {
            const callback = (message: ChatMessage) => {
                emitter({ message });
            };

            hub.connection.on('OnNewChatMessage', callback);

            // Return an unsubscribe method
            return () => {
                hub.connection.off('OnNewChatMessage', callback);
            };
        });

        while (true) {
            const { message } = yield take(channel);
            log('receive new message', message);
            yield put<ChatState.ReceiveNewMessageAction>({
                type: ChatState.ReceiveNewMessageActionType,
                payload: { message },
            });

            yield updateAlertState();
        }
    }
}

function* onMarkMessageAsReadAction(action: ChatState.MarkMessageAsReadAction) {
    const hub: AdminHub = yield selectAdminHub();
    const state: ChatState.State = yield select((s: ApplicationState) => s.chat);

    if (state.recipients && state.threads) {
        if (state.merchant) {
            const thread = state.threads.find((t) => t.merchantId === action.payload.modelId);

            if (thread && thread.unread > 0) {
                yield hub.markMerchantMessageAsRead(action.payload.modelId);
            }
        } else {
            const recipient = state.recipients.find(
                (r) => r.driverId === action.payload.modelId || r.merchantId === action.payload.modelId,
            );
            const thread = state.threads.find(
                (t) => t.driverId === action.payload.modelId || t.merchantId === action.payload.modelId,
            );

            if (recipient && thread && thread.unread > 0) {
                if (recipient.driverId) {
                    yield hub.markMessageAsRead(recipient.driverId);
                } else if (recipient.merchantId) {
                    yield hub.markMerchantMessageAsRead(recipient.merchantId);
                }
            }
        }
    }
}

function* listenOnMessageClear() {
    const state: ApplicationState = yield select();
    const hub = state.server.hubs && state.server.hubs.admin;
    const channel = eventChannel((emitter) => {
        const callback = (modelId: string, toAdmin: boolean, readByUserName: string) => {
            emitter({ modelId, toAdmin, readByUserName });
        };

        hub && hub.connection.on('OnMessageClear', callback);

        // Return an unsubscribe method
        return () => {
            hub && hub.connection.off('OnMessageClear', callback);
        };
    });

    while (true) {
        const { modelId, toAdmin, readByUserName } = yield take(channel);
        const state: ChatState.State = yield select((s: ApplicationState) => s.chat);
        if (state.threads) {
            const thread = state.threads.find((t) => t.driverId === modelId || t.merchantId === modelId);
            if (thread) {
                const now = new Date();
                const nowStr = now.toISOString();
                for (const m of thread.messages) {
                    if (m.toAdmin === toAdmin && !m.dateRead) {
                        m.dateRead = nowStr;
                        m.readByUserName = readByUserName ?? '';
                    }
                }

                thread.unread = state.merchant
                    ? thread.messages.filter((m) => !m.toAdmin && !m.dateRead).length
                    : thread.messages.filter((m) => m.toAdmin && !m.dateRead).length;

                let clearReadCount = false;
                if ((state.merchant && !toAdmin) || (!state.merchant && toAdmin)) {
                    clearReadCount = true;
                }

                const newUnreadCount: Map<string, number> = clearReadCount ? new Map() : state.unreadCount;
                yield put<ChatState.UpdateStateAction>({
                    type: ChatState.UpdateStateActionType,
                    payload: {
                        threads: state.threads,
                        unreadCount: newUnreadCount,
                    },
                });

                yield updateAlertState();
            }
        }
    }
}

function* initChatState(act: ServerConnectedAction) {
    if (act.payload.features.merchantOrder) {
        yield put<ChatState.UpdateStateAction>({
            type: ChatState.UpdateStateActionType,
            payload: {
                merchant: true,
            },
        });

        yield put<ChatState.FetchAllMessagesAction>({
            type: ChatState.FetchAllMessagesActionType,
        });
    } else {
        yield fetchRecipients();
    }
}

export default [
    // initialization, setup listener for new messages
    takeEvery(ServerState.ServerConnectedActionType, listenNewMessages),
    takeEvery(ServerState.ServerConnectedActionType, listenOnMessageClear),
    // initialization, load recent messages
    takeEvery(ServerState.ServerConnectedActionType, initChatState),

    takeEvery(ServerState.ClearSidePanelActionType, onClearSidePanel),

    takeEvery(DriverState.DriverUpdateActionType, updateRecipients),
    takeEvery(ChatState.SendMessageActionType, sendMessage),
    takeEvery(ChatState.ReceiveRecipientsActionType, onRecipientsReady),
    takeEvery(ChatState.FetchAllMessagesActionType, fecthMessages),
    takeEvery(ChatState.MarkMessageAsReadActionType, onMarkMessageAsReadAction),
];
