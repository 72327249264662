import moment from 'moment';

export function formatDuration(from: string | Date | null, to: string | Date | null): string | undefined {
    if (!from || !to) {
        return undefined;
    }

    // set up
    const start = moment(from); // some random moment in time (in ms)
    const end = moment(to); // some random moment after start (in ms)
    const diff = end.diff(start);

    // execution
    const str = moment.utc(diff).format('HH:mm:ss');
    return str;
}
