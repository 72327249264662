import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { BusinessInformation } from './BusinessInformation';
import { CustomerInfo } from './CustomerInfo';
import { Driver } from './Driver';
import { Merchant } from './Merchant';
import { MerchantOrganization } from './MerchantOrganization';
import { NamedOption } from './NamedOption';
import { ServiceZone } from './ServiceZone';
import { TransportHub } from './TransportHub';
import { ChatMessage } from './ChatMessage';
import { OrderTask } from './OrderTask';
import { WorkLog } from './WorkLog';
import { HoldOrderRequest } from './HoldOrderRequest';
import { Order } from './Order';
import { MerchantOrderHistoryItem } from './MerchantOrderHistoryItem';
import { DriverOrderTaskHistoryItem } from './DriverOrderTaskHistoryItem';
import { OrderStatus } from './OrderStatus';
import { UserModel } from './UserModel';
import { ReportInfo } from './ReportInfo';
import { ReportContent } from './ReportContent';
import { ReportExportContent } from './ReportExportContent';
import { UndeliverableCode } from './UndeliverableCode';
import { ManifestImportModel } from './ManifestImportModel';
import { MapAssignmentFilter } from './MapAssignmentFilter';
import { DriverAssignResult } from './DriverAssignResult';
import { ManifestImportInfo } from './ManifestImportInfo';
import { OrderRef } from './OrderRef';
import { OrderTaskRef } from './OrderTaskRef';
import { UserRole } from './UserRole';
import { GpsTrackerDevice } from './GpsTrackerDevice';
import { ReportInputControlModel } from './ReportInputControlModel';
import { ReportExportType } from './ReportExportType';
import { PunchInData } from './PunchInData';
import { DriverWorkLogHistoryItem } from './DriverWorkLogHistoryItem';
import { OrderDeliveryTimeChangePayload } from './OrderDeliveryTimeChangePayload';
import { log } from 'utils';
import { StoredFileModel } from './StoredFileModel';
import { AccountAddress } from './AccountAddress';
import { SmsInbox } from './SmsInbox';
import { TaskGroup } from 'components/Dispatching/TaskGroup';

class AdminHub {
    public constructor(public connection: HubConnection) {}

    public setTaskGroupAddress(
        taskGroup: TaskGroup,
        address: AccountAddress,
        setPickupAddress: boolean,
        isFix: boolean,
    ) {
        return this.connection.invoke<string>('setTaskGroupAddress', taskGroup, address, setPickupAddress, isFix);
    }

    public setTaskAddress(task: OrderTask, address: AccountAddress, setPickupAddress: boolean, isFix: boolean) {
        return this.connection.invoke<string>('setTaskAddress', task, address, setPickupAddress, isFix);
    }

    public createWayPoint(orderTask: OrderTask) {
        return this.connection.invoke<OrderTask>('CreateWayPoint', orderTask);
    }

    public processWorkLogStats(workLogId: string) {
        log('adminHub: processWorkLogStats', workLogId);

        return this.connection.invoke<string>('ProcessWorkLogStats', workLogId);
    }

    public getDrivers() {
        return this.connection.invoke<Driver[]>('GetDrivers');
    }

    public findDriverById(id: string) {
        return this.connection.invoke<Driver>('FindDriverById', id);
    }

    public saveDriver(driver: Driver) {
        return this.connection.invoke<{
            data: Driver;
            error: string | null;
        }>('SaveDriver', driver);
    }

    public getMerchants() {
        return this.connection.invoke<Merchant[]>('GetMerchants');
    }

    public getMerchantsOrganizations() {
        return this.connection.invoke<MerchantOrganization[]>('GetMerchantsOrganizations');
    }

    public getMerchantOrganization(id: string) {
        return this.connection.invoke<MerchantOrganization>('GetMerchantOrganization', id);
    }

    public saveMerchantOrganization(merchantOrganization: MerchantOrganization) {
        return this.connection.invoke<MerchantOrganization>('SaveMerchantOrganization', merchantOrganization);
    }

    public getSmsInboxes() {
        return this.connection.invoke<SmsInbox[]>('GetSmsInboxes');
    }

    public deleteSmsInbox(inbox: string) {
        return this.connection.invoke<boolean>('deleteSmsInbox', inbox);
    }

    public getSmsInbox(id: string) {
        return this.connection.invoke<SmsInbox>('GetSmsInbox', id);
    }

    public saveSmsInbox(smsInbox: SmsInbox) {
        return this.connection.invoke<SmsInbox>('SaveSmsInbox', smsInbox);
    }

    public getMerchant(merchantId: string) {
        return this.connection.invoke<Merchant>('GetMerchant', merchantId);
    }

    public getMerchantTimeZoneOffset(merchantId: string) {
        return this.connection.invoke<number>('GetMerchantTimeZoneOffset', merchantId);
    }

    public findMerchantById(id: string) {
        return this.connection.invoke<Merchant>('FindMerchantById', id);
    }

    public saveMerchant(merchant: Merchant) {
        return this.connection.invoke<{
            data: Merchant;
            error: string | null;
        }>('SaveMerchant', merchant);
    }

    public saveCustomer(customer: CustomerInfo) {
        return this.connection.invoke<CustomerInfo>('SaveCustomer', customer);
    }

    public findCustomers(query: string) {
        return this.connection.invoke<CustomerInfo[]>('FindCustomers', query);
    }

    public getTimeZones() {
        return this.connection.invoke<NamedOption[]>('GetTimeZones');
    }

    public getSettings() {
        return this.connection.invoke<BusinessInformation>('GetSettings');
    }

    public saveSettings(settings: BusinessInformation) {
        return this.connection.invoke('SaveSettings', settings);
    }

    public getServiceZones() {
        return this.connection.invoke<ServiceZone[]>('GetServiceZones');
    }

    public saveServiceZone(zone: ServiceZone) {
        return this.connection.invoke<ServiceZone>('SaveServiceZone', zone);
    }

    public deleteServiceZone(id: string) {
        return this.connection.invoke<boolean>('DeleteServiceZone', id);
    }

    public getTransportHubs() {
        return this.connection.invoke<TransportHub[]>('GetTransportHubs');
    }

    public saveTransportHub(hub: TransportHub) {
        return this.connection.invoke<TransportHub>('SaveTransportHub', hub);
    }

    public getUndeliverableCodes() {
        return this.connection.invoke<UndeliverableCode[]>('GetUndeliverableCodes');
    }

    public deleteUndeliverableCode(codeId: string) {
        return this.connection.invoke<boolean>('deleteUndeliverableCode', codeId);
    }

    public saveUndeliverableCode(code: UndeliverableCode) {
        return this.connection.invoke<UndeliverableCode>('SaveUndeliverableCode', code);
    }

    public getGpsTrackerDevices() {
        return this.connection.invoke<GpsTrackerDevice[]>('GetGpsTrackerDevices');
    }

    public saveGpsTrackerDevice(device: GpsTrackerDevice) {
        return this.connection.invoke<GpsTrackerDevice>('SaveGpsTrackerDevice', device);
    }

    public deleteGpsTrackerDevices(id: string) {
        return this.connection.invoke<never>('DeleteGpsTrackerDevices', id);
    }

    public getChatMessages() {
        return this.connection.invoke<ChatMessage[]>('GetChatMessages');
    }

    public sendChatMessage(message: Partial<ChatMessage>) {
        return this.connection.invoke<never>('sendChatMessage', message);
    }

    public getActiveTasks(serviceCities: string[]) {
        return this.connection.invoke<OrderTask[]>('GetActiveTasks', serviceCities || '').catch((err) => {
            console.error(err);
        });
    }

    public punchOutDriver(driverId: string) {
        return this.connection.invoke<never>('PunchOutDriver', driverId);
    }

    public forcePunchInDriver(driverId: string) {
        return this.connection.invoke<never>('ForcePunchInDriver', driverId);
    }

    public acceptPunchIn(driverId: string, punchInData: PunchInData) {
        return this.connection.invoke<never>('AcceptPunchIn', driverId, punchInData);
    }

    public rejectPunchIn(driverId: string, punchInData: PunchInData) {
        return this.connection.invoke<never>('RejectPunchIn', driverId, punchInData);
    }

    public getWorkLogs(driverId: string) {
        return this.connection.invoke<WorkLog[]>('GetWorkLogs', driverId);
    }

    public disableDriver(driverId: string) {
        return this.connection.invoke<boolean>('DisableDriver', driverId);
    }

    public restoreDriver(driverId: string) {
        return this.connection.invoke<boolean>('RestoreDriver', driverId);
    }

    public getDeletedDrivers() {
        return this.connection.invoke<Driver[]>('GetDeletedDrivers');
    }

    public holdOrder(request: HoldOrderRequest) {
        return this.connection.invoke<boolean>('HoldOrder', request).catch((err) => {
            console.error(err);
        });
    }

    public unholdOrder(orderId: string, removeDriver: boolean) {
        return this.connection.invoke<boolean>('UnholdOrder', orderId, removeDriver).catch((err) => {
            console.error(err);
        });
    }

    public getOrder(orderId: string) {
        return this.connection.invoke<Order>('GetOrder', orderId).catch((err) => {
            console.error(err);
        });
    }

    public getMerchantOrders(merchantId: string, fromId?: string) {
        return this.connection.invoke<MerchantOrderHistoryItem[]>('GetMerchantOrders', merchantId, fromId || '');
    }

    public getDriverOrderTasks(driverId: string, fromTick?: string) {
        return this.connection.invoke<DriverOrderTaskHistoryItem[]>('GetDriverOrderTasks', driverId, fromTick || '');
    }

    public getDriverWorkLogs(driverId: string, fromTick?: string) {
        return this.connection.invoke<DriverWorkLogHistoryItem[]>('GetDriverWorkLogs', driverId, fromTick || '');
    }

    public taskDiscardMileage(orderTaskId: string, discard: boolean) {
        return this.connection.invoke<boolean>('TaskDiscardMileage', orderTaskId, discard);
    }

    public getWorkLog(workLogId: string) {
        return this.connection.invoke<WorkLog>('getWorkLog', workLogId);
    }

    public changeOrderStatus(orderId: string, newStatus: OrderStatus) {
        return this.connection.invoke<boolean>('ChangeOrderStatus', orderId, newStatus);
    }

    public getUserInfo() {
        return this.connection.invoke<UserModel>('GetUserInfo');
    }

    public saveDispatcherUser(model: UserModel) {
        return this.connection.invoke<{
            data: UserModel;
            error: string | null;
        }>('SaveDispatcherUser', model);
    }

    public getDispatcherUsers() {
        return this.connection.invoke<UserModel[]>('GetDispatcherUsers');
    }

    public getUserGroups(userId: string) {
        return this.connection.invoke<UserRole[]>('GetUserGroups', userId);
    }

    public getReports() {
        return this.connection.invoke<ReportInfo[]>('GetReports');
    }

    public getInputControls(reportUri: string) {
        return this.connection.invoke<ReportInputControlModel[]>('GetInputControls', reportUri);
    }

    public executeReport(reportUri: string, page: number, parameters: {}) {
        return this.connection.invoke<ReportContent>('ExecuteReport', reportUri, page, parameters);
    }

    public exportReport(reportUri: string, parameters: {}, type: ReportExportType) {
        return this.connection.invoke<ReportExportContent>('ExportReport', reportUri, parameters, type);
    }

    public markTaskVisited(taskId: string) {
        return this.connection.invoke<never>('MarkTaskVisited', taskId);
    }

    public cancelTask(taskId: string) {
        return this.connection.invoke<never>('CancelTask', taskId);
    }

    public getHereAppInfo() {
        return this.connection.invoke<boolean>('GetHereAppInfo');
    }

    public updateTasksPriority(tasks: Partial<OrderTaskRef>[]) {
        return this.connection.invoke<string>('UpdateTasksPriority', tasks).catch((err) => {
            console.error(err);
        });
    }

    public markMessageAsRead(driverId: string) {
        return this.connection.invoke<Order[]>('MarkMessageAsRead', driverId);
    }

    public markMerchantMessageAsRead(merchantId: string) {
        return this.connection.invoke<Order[]>('MarkMerchantMessageAsRead', merchantId);
    }

    public importManifest(manifestImport: ManifestImportModel) {
        return this.connection.invoke('ImportManifest', manifestImport);
    }

    public submitImport(manifestImport: ManifestImportModel) {
        return this.connection.invoke('SubmitImport', manifestImport);
    }

    public completeTasks(taskIds: string[]) {
        return this.connection.invoke<string[]>('completeTasks', taskIds);
    }

    public unassignDriver(taskIds: string[]) {
        return this.connection.invoke<never>('UnassignDriver', taskIds);
    }

    public searchTasksForAssignemnt(serviceCities: string[], filterModel: MapAssignmentFilter) {
        return this.connection.invoke<OrderTask[]>('SearchTasksForAssignemnt', serviceCities || '', filterModel);
    }

    public assignTasksToDriver(orderIds: string[], taskIds: string[], driverId: string) {
        return this.connection.invoke<never>('AssignTasksToDriver', orderIds, taskIds, driverId);
    }

    public transferTasksToDriver(driverIdFrom: string, driverIdTo: string) {
        return this.connection.invoke<DriverAssignResult>('TransferTasksToDriver', driverIdFrom, driverIdTo);
    }

    public getManifestImports() {
        return this.connection.invoke<ManifestImportInfo[]>('GetManifestImports');
    }

    public getOrders(orderIds: string[]) {
        orderIds = orderIds.filter((i) => i != null);
        return this.connection.invoke<OrderRef[]>('GetOrders', orderIds).catch((err) => {
            console.error(err);
        });
    }

    public searchOrdersByFilter(keywords: string, filterMode: string, date: string, dateMax: string) {
        return this.connection.invoke<OrderRef[]>(
            'SearchOrdersByFilter',
            keywords || '',
            filterMode || '',
            date || '',
            dateMax || '',
        );
    }

    public getTasksByOrders(orderIds: string[]) {
        orderIds = orderIds.filter((i) => i != null);
        return this.connection.invoke<OrderTaskRef[]>('GetTasksByOrders', orderIds).catch((err) => {
            console.error(err);
        });
    }

    public getTasksByIds(orderTaskIds: string[]) {
        orderTaskIds = orderTaskIds.filter((i) => i != null);
        return this.connection.invoke<OrderTaskRef[]>('GetTasksByIds', orderTaskIds).catch((err) => {
            console.error(err);
        });
    }

    public disableMerchant(id: string, disable: boolean) {
        return this.connection.invoke<never>('DisableMerchant', id, disable).catch((err) => {
            console.error(err);
        });
    }

    public refreshETAs() {
        return this.connection.invoke<never>('RefreshEtas').catch((err) => {
            console.error(err);
        });
    }

    public resetBinNumbersDrivers() {
        return this.connection.invoke<never>('ResetBinNumbersDrivers');
    }

    public refreshEtaForDriver(id: string) {
        return this.connection.invoke<never>('RefreshEtaForDriver', id);
    }

    public setDesiredTime(
        orders: OrderDeliveryTimeChangePayload[],
        notifyClient: boolean,
        reset5MinAlert: boolean,
        reset1HourAlert: boolean,
    ) {
        return this.connection.invoke<never>('SetDesiredTime', orders, notifyClient, reset5MinAlert, reset1HourAlert);
    }

    //#region BULK_ACTIONS

    public bulkAppendNote(orderIds: string[], notes: string) {
        return this.connection.invoke<string[]>('BulkAppendNote', orderIds, notes);
    }

    public bulkSetFlag(orderIds: string[], flag: string, flagValue: boolean) {
        return this.connection.invoke<string[]>('BulkSetFlag', orderIds, flag, flagValue);
    }

    public sendChatMessages(messages: Partial<ChatMessage>[]) {
        return this.connection.invoke<never>('SendChatMessages', messages);
    }

    //#endregion BULK_ACTIONS

    public tagOrders(orderIds: string[], tag: string) {
        return this.connection.invoke<never>('TagOrders', orderIds, tag);
    }

    public getTaskPhoto(taskId: string) {
        return this.connection.invoke<StoredFileModel[]>('GetTaskPhoto', taskId);
    }

    public getOrderPhoto(orderId: string) {
        return this.connection.invoke<StoredFileModel[]>('GetOrderPhoto', orderId);
    }

    public transferTasks(taskIds: string[], sourceDriverId: string, targetDriverId: string, addr: AccountAddress) {
        return this.connection.invoke<never>('TransferTasks', taskIds, sourceDriverId, targetDriverId, addr);
    }

    public checkImport(merchantId: string) {
        return this.connection.invoke<{
            manifestsOnFtp: string[] | null;
            partialCompletedManifests: ManifestImportInfo[];
        }>('CheckImport', merchantId);
    }

    public importManifestFromFtp(manifestImport: ManifestImportModel) {
        return this.connection.invoke('ImportManifestFromFtp', manifestImport);
    }
}

export { AdminHub };
