import { Driver } from 'models/Driver';
import { Order } from 'models/Order';
import { OrderCategory } from 'models/OrderCategory';
import { OrderRefItem } from 'models/OrderRefItem';
import { ServiceZone } from 'models/ServiceZone';
import { UndeliverableCode } from 'models/UndeliverableCode';
import moment from 'moment';

export interface State {
    filterByKeywords: string;
    filterByDate: string;
    filterByDateMax: string;
    filterMode: string;
    filterOrders: OrderRefItem[];

    undeliverableCodes: UndeliverableCode[] | null;

    orderRefs: OrderRefItem[]; // all order for last (3) days
    orderDict: { [id: string]: OrderRefItem }; // lookup version of orderRefs

    currentCategory: OrderCategory;
    currentZone: ServiceZone | null;
    currentDriver: Driver | null;
    currentOrderRefs: OrderRefItem[];

    allTags: string[];

    activeOrder: Order | null; // current order displayed in details
    showFullOrder: boolean;
    filters: boolean[];
    toggleTableView: boolean;
    isLoading: boolean;
    selectAllOrders: boolean;
    selectedOrders: string[];
    togglePrint: boolean;
    togglePrintOrderSection: boolean;

    direction: boolean;
    columnNameToSort: string;
}

export const defaultState: State = {
    filterByKeywords: '',
    filterByDate: '',
    filterByDateMax: '',
    filterMode: '',
    filterOrders: [],

    undeliverableCodes: null,
    orderRefs: [],
    orderDict: {},
    currentCategory: 'Active',
    currentZone: null,
    currentDriver: null,
    currentOrderRefs: [],

    allTags: [],

    activeOrder: null,
    showFullOrder: false,
    filters: [false, false, false, false, false, false, false, false],
    toggleTableView: true,
    isLoading: true,
    selectedOrders: [],
    selectAllOrders: false,
    togglePrint: false,
    togglePrintOrderSection: false,

    direction: true,
    columnNameToSort: '',
};
