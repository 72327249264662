import { Reducer } from 'redux';

export interface State {
    serviceCitiesFilter: string[];
    dispatchDateFilter: string;
    showLocalTime: boolean;
}

export const ChangeServiceCityActionType = 'NAVI/CHANGE_SERVICE_CITY';
export const ChangeDispatchDateActionType = 'NAVI/CHANGE_SERVICE_CITY';
export const ToggleTimeDisplayActionType = 'NAVI/TOGGLE_TIME_DISPLAY';

export interface ChangeServiceCityAction {
    type: typeof ChangeServiceCityActionType;
    payload: {
        serviceCitiesFilter: string[];
    };
}

export type KnownAction = ChangeServiceCityAction | { type: typeof ToggleTimeDisplayActionType };

export const actionCreators = {
    changeServiceCities: (serviceCitiesFilter: string[]) => ({
        type: ChangeServiceCityActionType,
        payload: { serviceCitiesFilter },
    }),
    changeDispatchDate: (dispatchDateFilter: string) => ({
        type: ChangeDispatchDateActionType,
        payload: { dispatchDateFilter },
    }),
    toggleTimeDisplay: () => ({
        type: ToggleTimeDisplayActionType,
    }),
};

export const reducer: Reducer<State, KnownAction> = (
    state = {
        serviceCitiesFilter: [],
        dispatchDateFilter: '',
        showLocalTime: false,
    },
    action,
): State => {
    switch (action.type) {
        case ChangeServiceCityActionType:
            return { ...state, ...action.payload };
        case ToggleTimeDisplayActionType:
            return { ...state, showLocalTime: !state.showLocalTime };
        default:
            return state;
    }
};
