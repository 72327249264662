import { State, defaultState } from './State';

import { KnownAction } from './Actions/KnownAction';
import { Reducer } from 'redux';
import { UpdateFilterActionType } from './Actions/UpdateFilterAction';
import { UpdateStateActionType } from './Actions/UpdateStateAction';

export const reducer: Reducer<State, KnownAction> = (state: State | undefined = defaultState, action: KnownAction) => {
    switch (action.type) {
        case UpdateStateActionType: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
