/* eslint-disable @typescript-eslint/no-unused-vars */
import { Without } from './helpers';

export interface GeoLocation {
    latitude: number;
    longitude: number;
}

export type GeoLocationValues = Without<
    GeoLocation,
    keyof {
        latitude: {};
        longitude: {};
    }
> & {
    latitude: string;
    longitude: string;
};

export function getDefault(): GeoLocation {
    return {
        latitude: 0,
        longitude: 0,
    };
}

export function toValues(model: Partial<GeoLocation>): GeoLocationValues {
    model = model || {};
    return {
        latitude: `${model.latitude || 0}`,
        longitude: `${model.longitude || 0}`,
    };
}

export function toModel(values: GeoLocationValues): GeoLocation {
    return {
        ...values,
        latitude: Number.parseFloat(values.latitude),
        longitude: Number.parseFloat(values.longitude),
    };
}
