import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { IHubs } from '../../models';
import { LinkContainer } from 'react-router-bootstrap';
import React from 'react';
import { SplashScreen } from '../SplashScreen';
import { menus } from '../../messages';

const ZoneManagement = React.lazy(() => import('./ZoneManagement'));
const BusinessInformationManagement = React.lazy(() => import('./BusinessInformationManagement'));
const TransportHubManagement = React.lazy(() => import('./TransportHub/TransportHubManagement'));
const UndeliverableCodesManagement = React.lazy(() => import('./UndeliverableCodes/UndeliverableCodesManagement'));
const GpsTrackerDevicesManagement = React.lazy(() => import('./GpsTrackerDevices/GpsTrackerDevicesManagement'));
const MerchantOrganizationManagement = React.lazy(() => import('./MerchantOrgs/MerchantOrgsManagement'));
const SmsInboxManagement = React.lazy(() => import('./ChatwootInboxes/SmsInboxManagement'));

const NaviItem: React.SFC<
    {
        labelId: string;
        to: string;
    } & { intl: IntlShape }
> = (props) => (
    <LinkContainer to={props.to} activeClassName="bg-success">
        <li className="list-group-item">
            <NavLink
                to={props.to}
                className="d-block"
                activeClassName="active"
                title={props.intl.formatMessage({ id: props.labelId })}
            >
                {props.children}
            </NavLink>
        </li>
    </LinkContainer>
);

class SystemSettings extends React.Component<
    {
        hubs: IHubs;
    } & RouteComponentProps<{}> & { intl: IntlShape },
    {}
> {
    public render() {
        const { match } = this.props;
        return (
            <div className="d-flex h-100">
                <div className="bg-dark h-100 border-left border-secondary w-side-list">
                    <ul className="list-group m-2 list-dark">
                        <NaviItem
                            to={`${match.path}/business`}
                            labelId={menus.settings.business.id}
                            intl={this.props.intl}
                        >
                            <FormattedMessage {...menus.settings.business} />
                        </NaviItem>
                        <NaviItem to={`${match.path}/zones`} labelId={menus.settings.zones.id} intl={this.props.intl}>
                            <FormattedMessage {...menus.settings.zones} />
                        </NaviItem>
                        <NaviItem to={`${match.path}/hubs`} labelId={menus.settings.hubs.id} intl={this.props.intl}>
                            <FormattedMessage {...menus.settings.hubs} />
                        </NaviItem>
                        <NaviItem
                            to={`${match.path}/undeliverablescodes`}
                            labelId={menus.settings.undeliverables.id}
                            intl={this.props.intl}
                        >
                            <FormattedMessage {...menus.settings.undeliverables} />
                        </NaviItem>
                        <NaviItem
                            to={`${match.path}/gpstrackerdevices`}
                            labelId={menus.settings.gpstrackerdevices.id}
                            intl={this.props.intl}
                        >
                            <FormattedMessage {...menus.settings.gpstrackerdevices} />
                        </NaviItem>
                        <NaviItem
                            to={`${match.path}/merchantorgs`}
                            labelId={menus.settings.merchantOrgs.id}
                            intl={this.props.intl}
                        >
                            <FormattedMessage {...menus.settings.merchantOrgs} />
                        </NaviItem>
                        <NaviItem
                            to={`${match.path}/chatwootinboxes`}
                            labelId={menus.settings.chatwootInboxes.id}
                            intl={this.props.intl}
                        >
                            <FormattedMessage {...menus.settings.chatwootInboxes} />
                        </NaviItem>
                    </ul>
                </div>
                <Switch>
                    <Route path={`${match.path}/business`} render={this.renderBusiness} />
                    <Route path={`${match.path}/zones`} render={this.renderZones} />
                    <Route path={`${match.path}/hubs`} render={this.renderHubs} />
                    <Route path={`${match.path}/undeliverablescodes`} render={this.renderUndeliverableCodes} />
                    <Route path={`${match.path}/gpstrackerdevices`} render={this.renderGpsTrackerDevices} />
                    <Route path={`${match.path}/merchantorgs`} render={this.renderMerchantOrgs} />
                    <Route path={`${match.path}/chatwootinboxes`} render={this.renderChatwootInboxes} />
                    <Redirect to={`${match.path}/business`} />
                </Switch>
            </div>
        );
    }

    private renderHubs = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <TransportHubManagement {...this.props} />
            </React.Suspense>
        );
    };

    private renderZones = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <ZoneManagement {...this.props} />
            </React.Suspense>
        );
    };

    private renderBusiness = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <BusinessInformationManagement {...this.props} />
            </React.Suspense>
        );
    };

    private renderUndeliverableCodes = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <UndeliverableCodesManagement {...this.props} />
            </React.Suspense>
        );
    };

    private renderGpsTrackerDevices = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <GpsTrackerDevicesManagement {...this.props} />
            </React.Suspense>
        );
    };

    private renderMerchantOrgs = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <MerchantOrganizationManagement {...this.props} />
            </React.Suspense>
        );
    };
    private renderChatwootInboxes = (props: RouteComponentProps<{}>) => {
        return (
            <React.Suspense fallback={<SplashScreen />}>
                <SmsInboxManagement {...this.props} />
            </React.Suspense>
        );
    };
}

export default injectIntl(SystemSettings);
