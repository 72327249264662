import * as DriverState from 'store/DriverState';
import * as NaviState from 'store/NaviState';
import * as TaskMgntState from 'store/TaskMgntState';

import { UnassignDriverAction, UnassignDriverActionType } from './Actions/UnassignDriverAction';
import { AssignDriverAction, AssignDriverActionType } from './Actions/AssignDriverAction';
import { RefreshTaskAction, RefreshTaskActionType } from './Actions/RefreshTaskAction';
import { UpdateFilterAction, UpdateFilterActionType } from './Actions/UpdateFilterAction';
import { UpdatePolygonAction, UpdatePolygonActionType } from './Actions/UpdatePolygonAction';
import { UpdateStateAction, UpdateStateActionType } from './Actions/UpdateStateAction';
import { call, debounce, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { AdminHub } from 'models/AdminHub';
import { ApplicationState } from '../ApplicationState';
import { MapAssignmentData } from 'models/MapAssignmentData';
import { OrderTaskRef } from 'models/OrderTaskRef';
import { RefreshEtasActionType } from './Actions/RefreshEtasAction';
import { State } from './State';
import { Toast } from 'reactstrap';
import _ from 'lodash';
import { log } from 'utils';
import moment from 'moment';
import { selectAdminHub } from '../selectAdminHub';
import { toast } from 'react-toastify';
import { RefreshDriverETAAction, RefreshDriverETAActionType } from './Actions/RefreshDriverETAAction';
import { confirm } from 'react-confirm-box';

function* filterTasksByPolygon() {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            selectedTasks: [],
        },
    });

    const state: State = yield select((s: ApplicationState) => s.mapAssignment);

    if (!state.polygon) {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                isLoading: false,
            },
        });

        return;
    }

    const poly = state.polygon;
    const containedTasks = state.tasks.filter((t) => {
        const pos = new google.maps.LatLng(t.address.location.latitude, t.address.location.longitude);

        if (google.maps.geometry.poly.containsLocation(pos, poly)) {
            return true;
        }

        return false;
    });

    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            selectedTasks: containedTasks,
            isLoading: false,
        },
    });
}

function* onRefreshTasks() {
    const serviceCitiesFilter: string[] = yield select((s: ApplicationState) => s.navi.serviceCitiesFilter);
    const state: State = yield select((s: ApplicationState) => s.mapAssignment);
    const adminHub: AdminHub = yield selectAdminHub();

    const filter = state.filter;
    if (filter) {
        log('[PREF]', 'searchTasksForAssignemnt');

        const mapAssignmentData: MapAssignmentData = yield call(() =>
            adminHub.searchTasksForAssignemnt(serviceCitiesFilter, filter),
        );
        log('[PREF]', 'searchTasksForAssignemnt END', mapAssignmentData.orderTaskRefs.length);

        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                tasks: mapAssignmentData.orderTaskRefs,
                postals: mapAssignmentData.mapAssignmentPostals,
                domainServiceLevels: mapAssignmentData.mapAssignmentServiceLevels,
            },
        });

        yield filterTasksByPolygon();
    }
}

function* onUpdateFilter(action: UpdateFilterAction) {
    yield put<UpdateStateAction>({
        type: UpdateStateActionType,
        payload: {
            filter: action.payload.filter,
            tasks: [],
            isLoading: true,
        },
    });

    yield put<RefreshTaskAction>({ type: RefreshTaskActionType });
}

function* onUpdatePolygon(action: UpdatePolygonAction) {
    if (action.payload.points.length < 3) {
        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                polygon: null,
            },
        });
    } else {
        const polygon = new google.maps.Polygon({
            paths: action.payload.points.map((p) => ({
                lat: p.latitude,
                lng: p.longitude,
            })),
        });

        yield put<UpdateStateAction>({
            type: UpdateStateActionType,
            payload: {
                polygon: polygon,
            },
        });
    }

    yield filterTasksByPolygon();
}

function confirmDialog(message: string, options: any) {
    return new Promise((resolve) => {
        confirm(message, options).then((result) => resolve(result));
    });
}

function* onAssignDriver(action: AssignDriverAction) {
    const state: State = yield select((s: ApplicationState) => s.mapAssignment);
    const naviState: NaviState.State = yield select((s: ApplicationState) => s.navi);
    const driverState: DriverState.State = yield select((s: ApplicationState) => s.driver);
    const adminHub: AdminHub = yield selectAdminHub();

    if (state.filter) {
        const driverId = action.payload.driverId;
        const taskIds = state.selectedTasks.map((t) => t.id);
        const orderIds = state.selectedTasks.map((t) => t.order.id);

        const dispatchDateFilter = naviState.dispatchDateFilter;
        const currentDriver = driverState.activeDrivers && driverState.activeDrivers.find((d) => d.id == driverId);

        let i;
        let differentDates = 0;
        let errorDetails = '';
        for (i = 0; i < state.selectedTasks.length; i++) {
            if (
                moment(state.selectedTasks[i].desiredTime).format('YYYY-MM-DD') !=
                moment(currentDriver!.nextShiftStartTime!).format('YYYY-MM-DD')
            ) {
                log(
                    '[ERR]',
                    'onAssignDriver',
                    moment(state.selectedTasks[i].desiredTime).format('YYYY-MM-DD') +
                        '->' +
                        moment(currentDriver!.nextShiftStartTime!).format('YYYY-MM-DD'),
                );
                differentDates++;
                errorDetails += ` ${state.selectedTasks[i].order.seqId}`;
            }
        }

        var abortAssignment = false;
        if (differentDates > 0) {
            const confirmResult: boolean = yield call(
                confirmDialog,
                `${differentDates} tasks have a different delivery date than this driver start date:\n\n ${errorDetails}`,
                {
                    labels: {
                        confirmable: "Continue Assignment. I know what I'm doing.",
                        cancellable: 'Cancel Assignment',
                    },
                },
            );

            abortAssignment = !confirmResult;
        }

        if (!abortAssignment) {
            yield put<UpdateStateAction>({
                type: UpdateStateActionType,
                payload: {
                    tasks: [],
                    isLoading: true,
                },
            });

            try {
                log('[PREF]', 'assignTasksToDriver');
                yield call(() => adminHub.assignTasksToDriver(orderIds, taskIds, driverId));
                log('[PREF]', 'assignTasksToDriver END');

                yield put<UpdateFilterAction>({
                    type: UpdateFilterActionType,
                    payload: {
                        filter: state.filter,
                    },
                });
            } catch (err) {
                console.error('assignTasksToDriver failed', err);
            }

            yield delay(3000);

            // auto-route after assignment
            if (action.payload.autoRoute) {
                log('[PREF]', 'autoroute start');
                let autoRouteTries = 7;
                while (autoRouteTries-- > 0) {
                    log('[PREF]', 'autoroute ' + autoRouteTries);
                    //log('Auto-routing try ' + autoRouteTries);
                    yield delay(2000);

                    const taskMgnt: TaskMgntState.State = yield select((s: ApplicationState) => s.taskMgnt);
                    const driverWithTasks = taskMgnt.drivers.find((d) => d.id === driverId);

                    if (driverWithTasks) {
                        const lookup = _.keyBy(driverWithTasks.tasks, (t) => t.id);
                        let missingTask = false;
                        for (const assignedTaskId of taskIds) {
                            if (!lookup[assignedTaskId]) {
                                console.log('missing tasks');
                                missingTask = true;
                                break;
                            }
                        }

                        // driver task list is updated to contain all the assigment
                        if (!missingTask) {
                            const taskGroups = _.union(driverWithTasks.tasks.map((t) => t.groupOrder));
                            log('Auto-routing after assigment', driverId, taskGroups);
                            yield put<TaskMgntState.BeginAutoRouteAction>({
                                type: TaskMgntState.BeginAutoRouteActionType,
                                payload: { driverId, taskGroups },
                            });

                            if (autoRouteTries != 6) {
                                toast.success(`Routing ${driverWithTasks.name} retry succeeded`);
                            } else {
                                toast.success(`Routing ${driverWithTasks.name} succeeded`);
                            }
                            break;
                        } else {
                            toast.error(`Auto-routing ${driverWithTasks.name} failed`);
                        }
                    }
                    // break;
                }
            }
        } //END: Confirm assigned
        else {
            toast.info('Assignment cancelled');
        }
    }
}

function* onUnassignDriver() {
    const state: State = yield select((s: ApplicationState) => s.mapAssignment);
    const adminHub: AdminHub = yield selectAdminHub();

    if (state.filter) {
        const taskIds = state.selectedTasks.map((t) => t.id);

        try {
            log('[PREF]', 'unassignDriver', taskIds);
            yield call(() => adminHub.unassignDriver(taskIds));
            log('[PREF]', 'unassignDriver end');
        } catch (err) {
            yield call(() => toast.error('Failed to unassign task!'));
            log('faild to unassign task', err);
        }
    }
}

function* onRefreshEtas() {
    const adminHub: AdminHub = yield selectAdminHub();

    log('[PREF]', 'reFreshETAsFromMapFilter');
    const tasks: OrderTaskRef[] = yield call(() => adminHub.refreshETAs());
    log('[PREF]', 'reFreshETAsFromMapFilter END');
}

function* onRefreshEtaForDriver(action: RefreshDriverETAAction) {
    const adminHub: AdminHub = yield selectAdminHub();

    log('[PREF]', 'reFreshETAsFromMapFilter');
    const tasks: OrderTaskRef[] = yield call(() => adminHub.refreshEtaForDriver(action.payload.driverId));
    log('[PREF]', 'reFreshETAsFromMapFilter END');
}

export const sagas = [
    takeEvery(UpdateFilterActionType, onUpdateFilter),
    takeEvery(UpdatePolygonActionType, onUpdatePolygon),
    takeEvery(AssignDriverActionType, onAssignDriver),
    takeEvery(UnassignDriverActionType, onUnassignDriver),
    debounce(100, RefreshTaskActionType, onRefreshTasks),
    takeEvery(RefreshEtasActionType, onRefreshEtas),
    takeEvery(RefreshDriverETAActionType, onRefreshEtaForDriver),
];
