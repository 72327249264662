/* eslint-disable @typescript-eslint/no-unused-vars */
import { Without } from './helpers';

export interface MerchantOrganization {
    description: string;
    id: string;
    name: string;
}

export type MerchantOrganizationValues = Without<MerchantOrganization, keyof {}> & {};

export function getDefault(): MerchantOrganization {
    return {
        description: '',
        id: '',
        name: '',
    };
}

export function toValues(model: Partial<MerchantOrganization>): MerchantOrganizationValues {
    model = model || {};
    return {
        description: model.description || '',
        id: model.id || '',
        name: model.name || '',
    };
}

export function toModel(values: MerchantOrganizationValues): MerchantOrganization {
    return {
        ...values,
    };
}
