import { Driver } from 'models/Driver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { faCar, faBiking } from '@fortawesome/pro-light-svg-icons';
import { faMotorcycle } from '@fortawesome/pro-regular-svg-icons';

export interface DriverStatusIconProps {
    driver: Pick<Driver, 'phoneStatus' | 'isOnline' | 'vehicleType' | 'payPerPackage'>;
    className?: string;
    size?: SizeProp;
}

class DriverStatusIcon extends React.Component<DriverStatusIconProps> {
    public render() {
        const { driver, className, size } = this.props;
        return (
            <FontAwesomeIcon
                fixedWidth={true}
                icon={
                    (driver.vehicleType == 'car'
                        ? faCar
                        : driver.vehicleType == 'bike'
                        ? faBiking
                        : driver.vehicleType == 'ebike'
                        ? faMotorcycle
                        : faCar) as IconProp
                }
                title={driver.payPerPackage === true ? 'Per package (contract)' : ''}
                className={classNames('driver-status-icon', className, {
                    online: driver.phoneStatus === 'Active',
                    active: driver.isOnline,
                    'contract-driver': driver.payPerPackage === true,
                })}
                size={size}
            />
        );
    }
}

export default DriverStatusIcon;
