import { GeoLocation } from 'models/GeoLocation';
import { MerchantZone } from 'models/MerchantZone';

export interface State {
    zoneToCopy: GeoLocation[] | null;
    serviceZoneToCopy: MerchantZone | null;
}

export const defaultState: State = {
    zoneToCopy: null,
    serviceZoneToCopy: null,
};
