import { CloseWorkLogAction, CloseWorkLogActionType } from './Actions/CloseWorkLogAction';
import { DiscardMileageAction, DiscardMileageActionType } from './Actions/DiscardMileageAction';
import { FetchHistoryAction, FetchHistoryActionType } from './Actions/FetchHistoryAction';
import { FetchMoreHistoryAction, FetchMoreHistoryActionType } from './Actions/FetchMoreHistoryAction';
import { FetchWorkLogAction, FetchWorkLogActionType } from './Actions/FetchWorkLogAction';
import { ProcessWorkLogStatsAction, ProcessWorkLogStatsActionType } from './Actions/ProcessWorkLogStatsAction';

export const actionCreators = {
    fetchHistory: (driverId: string) => {
        const act: FetchHistoryAction = { type: FetchHistoryActionType, payload: { driverId } };
        return act;
    },
    fetchMoreWorkLogs: () => {
        const act: FetchMoreHistoryAction = { type: FetchMoreHistoryActionType };
        return act;
    },
    fetchWorkLog: (workLogId: string) => {
        const act: FetchWorkLogAction = {
            type: FetchWorkLogActionType,
            payload: { workLogId },
        };
        return act;
    },
    processWorkLogStats: (workLogId: string) => {
        const act: ProcessWorkLogStatsAction = {
            type: ProcessWorkLogStatsActionType,
            payload: { workLogId },
        };
        return act;
    },
    closeWorkLog: () => {
        const act: CloseWorkLogAction = {
            type: CloseWorkLogActionType,
        };
        return act;
    },
    discardMileage: (orderTaskId: string, discard: boolean) => {
        const act: DiscardMileageAction = {
            type: DiscardMileageActionType,
            payload: {
                orderTaskId,
                discard,
            },
        };
        return act;
    },
};
