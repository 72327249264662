import * as utils from './utils';

import { AuthError } from 'msal/lib-commonjs/error/AuthError';
import { AuthResponse } from 'msal/lib-commonjs/AuthResponse';
import { Configuration } from 'msal/lib-commonjs/Configuration';
import { UserAgentApplication } from 'msal';
import { appConfig } from './appConfig';
//import { setTimeout } from 'timers';

interface AuthOptions {
    clientId: string;
    tenant: string;
    signInPolicy: string;
    signInPolicyPortal: string;
}

class Auth {
    private tokenUsage = 0;
    private accessToken: string | null = null;
    private accessTokenExpiresOn: Date | null = null;
    private userAgentApplication: UserAgentApplication;
    private b2cAuthority: string;

    public constructor(private readonly options: AuthOptions) {
        this.b2cAuthority = `https://${this.options.tenant}.b2clogin.com/tfp/${this.options.tenant}.onmicrosoft.com/${this.options.signInPolicyPortal}`;
        const msalConfg: Configuration = {
            auth: {
                clientId: this.options.clientId,
                authority: this.b2cAuthority,
                redirectUri: window.location.origin,
                validateAuthority: false,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
        };

        this.userAgentApplication = new UserAgentApplication(msalConfg);
        this.userAgentApplication.handleRedirectCallback(this.handleRedirectCallback);
    }

    public inProgress() {
        return this.userAgentApplication.isCallback(window.location.hash);
    }

    public aquireToken(): Promise<string> {
        if (this.tokenUsage > 10) {
            this.tokenUsage = 0;
            this.accessToken = null;
        }

        if (this.inProgress()) {
            return Promise.reject();
        }

        // token expires in 15s
        if (this.accessTokenExpiresOn && this.accessTokenExpiresOn.getTime() + 15 * 1000 < new Date().getTime()) {
            utils.log('Token expired on ', this.accessTokenExpiresOn);
            this.accessTokenExpiresOn = null;
            this.accessToken = null;
            this.tokenUsage = 0;
        }

        this.tokenUsage = this.tokenUsage + 1;
        if (this.accessToken) {
            return Promise.resolve(this.accessToken);
        } else {
            return new Promise<string>((resolve, reject) => {
                // defer so authCallback get a chance to execute
                setTimeout(() => {
                    if (this.accessToken) {
                        utils.log('Resolved token');
                        resolve(this.accessToken);
                    } else {
                        const user = this.userAgentApplication.getAccount();
                        const scope = `https://${this.options.tenant}.onmicrosoft.com/cann-deliv-dispatch/user_impersonation`;

                        if (user) {
                            utils.log('user authenticated, aquiring token');
                            this.userAgentApplication
                                .acquireTokenSilent({
                                    scopes: [scope],
                                    authority: this.b2cAuthority,
                                })
                                .then((resp) => {
                                    utils.log('receive new token', resp);
                                    this.accessTokenExpiresOn = resp.expiresOn;
                                    resolve((this.accessToken = resp.accessToken));
                                })
                                .catch((err) => {
                                    utils.log('acquireTokenSilent error', err);
                                    this.userAgentApplication.logout();
                                    const redirectUri = this.userAgentApplication.getRedirectUri();
                                    this.userAgentApplication.loginRedirect({
                                        scopes: [scope],
                                        authority: this.b2cAuthority,
                                        redirectUri: redirectUri,
                                    });
                                });
                        } else {
                            utils.log('user NOT authenticated, loginRedirect');
                            const redirectUri = this.userAgentApplication.getRedirectUri();
                            this.userAgentApplication.loginRedirect({
                                scopes: [scope],
                                authority: this.b2cAuthority,
                                redirectUri: redirectUri,
                            });
                        }
                    }
                }, 0);
            });
        }
    }

    public getDisplayName() {
        const user = this.userAgentApplication.getAccount();
        return user ? user.name : null;
    }

    public getUserId() {
        const user = this.userAgentApplication.getAccount();
        return user ? user.accountIdentifier : null;
    }

    public logout() {
        this.userAgentApplication.logout();
    }

    private handleRedirectCallback = (authErr: AuthError, response?: AuthResponse) => {
        utils.log('handleRedirectCallback', authErr, response);
        if (response && response.accessToken) {
            this.accessToken = response.accessToken;
        }
    };
}

const auth = new Auth({
    clientId: appConfig.config.aad.clientId,
    signInPolicy: appConfig.config.aad.signInPolicy,
    signInPolicyPortal: appConfig.config.aad.signInPolicyPortal,
    tenant: appConfig.config.aad.tenant,
});

export { auth };
