import { AdminHub, IHubs, OrderHub, UserInfo, DriverStatusHub, AdminOrderHub } from 'models';
import { connectHub, log } from 'utils';

import { AppThunkAction } from './AppThunkAction';
import { BusinessInformation } from 'models/BusinessInformation';
import { FeatureAccess } from 'models/FeatureAccess';
import { Reducer } from 'redux';
import { appConfig } from 'appConfig';
import { auth } from '../Auth';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

export const ServerConnectedActionType = 'APP/SERVER_CONNECTED';

const ConnectActionType = 'APP/CONNECT';
export const ReconnectedActionType = 'APP/RECONNECTED';
const LogoutActionType = 'APP/LOGOUT';
const ReconnectingActionType = 'APP/RECONNECTING';
const DisconnectedActionType = 'APP/DISCONNECTED';
export const ReloadSettingActionType = 'APP/RELOADSETTING';
export const ClearSidePanelActionType = 'APP/CLEAR_SIDE_PANEL';

export interface ServerConnectedAction {
    type: typeof ServerConnectedActionType;
    payload: {
        hubs: IHubs;
        user: UserInfo;
        serviceCity: string;
        sysSettings: BusinessInformation;
        features: FeatureAccess;
    };
}

export interface State {
    connectingTimestamp: moment.Moment | null;
    isDisconnected: boolean;
    hubs: IHubs | null;
    user: UserInfo | null;
    serviceCity: string;
    sysSettings: BusinessInformation | null;
    features: FeatureAccess;
}

export type KnownAction =
    | { type: typeof ReloadSettingActionType; sysSettings: BusinessInformation }
    | ServerConnectedAction
    | {
          type: typeof ConnectActionType;
      }
    | {
          type: typeof ReconnectedActionType;
      }
    | {
          type: typeof LogoutActionType;
      }
    | {
          type: typeof ReconnectingActionType;
      }
    | {
          type: typeof DisconnectedActionType;
      };

const defaultState: State = {
    connectingTimestamp: null,
    isDisconnected: false,
    hubs: null,
    user: null,
    serviceCity: '',
    sysSettings: null,
    features: {
        merchant: false,
        merchantWrite: false,
        dispatch: false,
        driver: false,
        admin: false,
        report: false,
        merchantOrder: false,
        sysSettings: false,
        superAdmin: false,
    },
};

export const actionCreators = {
    reloadAfterSystemSettingUpdate: (sysSettings: BusinessInformation) => (dispatch: Dispatch<KnownAction>) => {
        const action: KnownAction = {
            type: ReloadSettingActionType,
            sysSettings,
        };
        dispatch(action);
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        auth.logout();
        dispatch({ type: LogoutActionType });
    },
    connect: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: ConnectActionType });

        await auth.aquireToken(); // this is need to trigger login
        const name = auth.getDisplayName();
        const userId = auth.getUserId();

        if (name && userId) {
            const apiConfig = appConfig.config.api;
            const adminHubConn = await connectHub(`${apiConfig.admin}/hubs/admin`);
            const orderHub = new OrderHub(await connectHub(`${apiConfig.orders}/hubs/orders`));
            const adminHub = new AdminHub(adminHubConn);
            const driverStatusHub = new DriverStatusHub(await connectHub(`${apiConfig.orders}/hubs/driver-status`));
            const adminOrderHubConn = await connectHub(`${apiConfig.orders}/hubs/admin-order`);

            adminHubConn.onreconnecting((err) => {
                console.log('adminHubConn.onreconnecting: err: ' + err);
                dispatch({ type: ReconnectingActionType });
                toast.warn('Connection issue detected..');
            });

            adminHubConn.onreconnected((connId) => {
                console.log('adminHubConn.onreconnected: connId: ' + connId);
                dispatch({ type: ReconnectedActionType });
            });

            adminHubConn.onclose((err) => {
                console.log('adminHubConn.onclose: err: ' + err);
                dispatch({ type: DisconnectedActionType });
            });

            adminOrderHubConn.onreconnecting((err) => {
                console.log('adminOrderHubConn.onreconnecting: err: ' + err);
                toast.info('Order Hub Reconnecting..');
            });

            const userInfo = await adminHub.getUserInfo();
            const sysSettings = await adminHub.getSettings();

            const isSU = userInfo.roles.includes('SuperAdmin');
            const isAdmin = userInfo.roles.includes('Admin');
            const isMgr = userInfo.roles.includes('Manager');
            const isDispatcher = userInfo.roles.includes('Dispatcher');
            const isFinance = userInfo.roles.includes('Finance');
            const isCustomerCare = userInfo.roles.includes('CustomerCare');
            const isMerchant = userInfo.roles.includes('Merchant');

            const accessDispatch = isSU || isAdmin || isMgr || isDispatcher;
            const features: FeatureAccess = {
                merchantWrite: isSU || isAdmin || isMgr,
                merchant: isSU || isAdmin || isMgr || isDispatcher,
                dispatch: accessDispatch,
                driver: isSU || isAdmin || isMgr || isDispatcher,
                admin: isSU || isAdmin,
                report: isSU || isAdmin || isFinance || isCustomerCare || isMerchant,
                merchantOrder: isMerchant,
                sysSettings: isSU,
                superAdmin: isSU,
            };

            log('user feature', features);
            dispatch({
                type: ServerConnectedActionType,
                payload: {
                    hubs: {
                        admin: adminHub,
                        orders: orderHub,
                        driverStatus: driverStatusHub,
                        adminOrderHub: new AdminOrderHub(adminOrderHubConn),
                    },
                    user: {
                        userId: userId,
                        displayName: name,
                        roles: userInfo.roles,
                        merchantId: userInfo.merchantId,
                    },
                    serviceCity: userInfo.serviceCity,
                    sysSettings,
                    features,
                },
            });
        }
    },
};

export const reducer: Reducer<State, KnownAction> = (
    state: State | undefined = defaultState,
    action: KnownAction,
): State => {
    switch (action.type) {
        case ReloadSettingActionType:
            return { ...state, sysSettings: action.sysSettings };
        case ConnectActionType:
            return { ...state, connectingTimestamp: moment() };
        case ServerConnectedActionType:
            return { ...state, ...action.payload, connectingTimestamp: null };
        case ReconnectedActionType:
            return { ...state, connectingTimestamp: null, isDisconnected: false };
        case ReconnectingActionType:
            return { ...state, connectingTimestamp: moment(), isDisconnected: false };
        case DisconnectedActionType:
            return { ...state, connectingTimestamp: null, isDisconnected: true };
        case LogoutActionType:
            return { ...state, hubs: null, user: null };
        default:
            return state;
    }
};
