/* eslint-disable @typescript-eslint/no-unused-vars */
import { Without } from './helpers';

import {
    GeoLocation,
    GeoLocationValues,
    getDefault as getGeoLocationDefault,
    toModel as toGeoLocationModel,
    toValues as toGeoLocationValues,
} from './GeoLocation';

import { GeoStopType } from './GeoStopType';

export interface GeoAddress {
    address: string;
    address2: string;
    city: string;
    country: string;
    location: GeoLocation;
    postal: string;
    state: string;
    stopType: GeoStopType;
}

export type GeoAddressValues = Without<
    GeoAddress,
    keyof {
        location: {};
    }
> & {
    location: GeoLocationValues;
};

export function getDefault(): GeoAddress {
    return {
        address: '',
        address2: '',
        city: '',
        country: '',
        location: getGeoLocationDefault(),
        postal: '',
        state: '',
        stopType: 'Delivery',
    };
}

export function toValues(model: Partial<GeoAddress>): GeoAddressValues {
    model = model || {};
    return {
        address: model.address || '',
        address2: model.address2 || '',
        city: model.city || '',
        country: model.country || '',
        location: toGeoLocationValues(model.location || {}),
        postal: model.postal || '',
        state: model.state || '',
        stopType: model.stopType || 'Delivery',
    };
}

export function toModel(values: GeoAddressValues): GeoAddress {
    return {
        ...values,
        location: toGeoLocationModel(values.location),
        stopType: values.stopType || 'Delivery',
    };
}
